import { API, TECHLEND_BE_URL } from '@/constants.api';
import { TOKEN } from '@/constants';
import { _post } from '@/services/crudService';

import { useCookies } from 'vue3-cookies';
const { cookies } = useCookies();

export default {
  namespaced: true,

  state: {
    id: '',
  },
  getters: {},
  mutations: {
    async setLogin(state, { token }) {
      /* state.id = id; */
      state.isLoggedIn = token ? true : false;
      state.token = token ? token : '';
      localStorage.setItem(TOKEN, state.token);
      cookies.set(TOKEN, state.token);
      if (!token) {
        state.id = '';
        await localStorage.removeItem('vuex');
        await cookies.remove(TOKEN);
        window.localStorage.clear();
        location.reload();
      }
    },
    async setLogout(state, { token }) {
      if (!token) {
        state.id = '';
        await localStorage.removeItem('vuex');
        await cookies.remove(TOKEN);
        window.localStorage.clear();
      }
    },
  },
  actions: {
    setLogin({ commit }, value) {
      commit('setLogin', value);
    },
    setLogout({ commit }, value) {
      commit('setLogout', value);
    },
    async fetchOtp(state, payload) {
      await _post(TECHLEND_BE_URL + API.AUTHENTICATE.REQUEST_OTP, payload);
    },
    async verifyOtp({ commit }, payload) {
      const response = await _post(TECHLEND_BE_URL + API.AUTHENTICATE.VERIFY_OTP, payload);
      const { data } = response;
      commit('setLogin', { token: data.token });
    },
  },
};
