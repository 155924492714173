<template>
  <div>
    <Spinner v-if="isLoading" class="flex" />
    <div v-else class="application-container box-shadow">
      <h3 class="title pm-txt-color">Applications</h3>
      <div class="loan-details p-d-flex p-flex-column p-flex-xl-row">
        <!-- <loan-details-card
          aLink="loan/001/application"
          buttonText="View Application"
        /> -->
        <loan-details-card
          v-for="(item, i) in loans"
          :key="i"
          :openBasic="openBasic"
          buttonText="View Application"
          :item="item"
        />
        <AddNewLoan v-if="showNew" />
      </div>

      <loan-detail-modal :closeBasic="closeBasic" :displayBasic="displayBasic" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import AddNewLoan from './AddNewLoan.vue';
import LoanDetailModal from '../LoanDetailsModal.vue';
import LoanDetailsCard from './LoanDetailsCard.vue';

export default {
  components: { LoanDetailsCard, LoanDetailModal, AddNewLoan },
  data() {
    return {
      displayBasic: false,
      isLoading: true,
      showNew: false,
    };
  },
  computed: {
    ...mapGetters('loans', ['loans']),
    ...mapGetters({
      dashboardLoan: 'loans/dashboardLoan',
    }),
  },
  methods: {
    ...mapActions({
      getLoansById: 'loans/getLoansById',
      setLoanId: 'loans/setLoanId',
      fetchLoanSummary: 'loans/fetchLoanSummary',
      setLoanStatus: 'loans/setLoanStatus',
      setNewLoanId: 'loans/setNewLoanId',
      getDashboardLoan: 'loans/getDashboardLoan',
    }),
    async openBasic(item) {
      await this.setLoanId(item.id);
      await this.setNewLoanId(item.loanReferenceNumber);
      await this.setLoanStatus(item.applicationStatus);
      if (
        item.applicationStatus === 'cancelled' ||
        item.loanStatus === 'dropped_off' ||
        item.loanStatus === 'withdrawn' ||
        item.loanStatus === 'rejected' ||
        item.loanStatus === 'on_hold'
      ) {
        this.$router.push(`/loan/${item.loanReferenceNumber}/summary`);
      } else {
        this.$router.push(`/loan/${item.loanReferenceNumber}/application`);
        // await this.fetchLoanSummary();
      }
      this.displayBasic = true;
    },
    closeBasic() {
      this.displayBasic = false;
    },
  },
  async created() {
    this.isLoading = true;
    await this.getLoansById();
    await this.getDashboardLoan();
    if (
      this.dashboardLoan.applicationStatus === 'cancelled' ||
      this.dashboardLoan.loanStatus === 'dropped_off' ||
      this.dashboardLoan.loanStatus === 'withdrawn' ||
      this.dashboardLoan.loanStatus === 'rejected' ||
      this.dashboardLoan.loanStatus === 'on_hold'
    ) {
      this.showNew = true;
    }
    this.isLoading = false;
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';

.title {
  font-size: 1.25rem;
}

.application-container {
  background-color: $white-color;
  margin: 2rem 0;
  padding: 1.875rem;
  // display: inline-block;

  .loan-details {
    gap: 2rem;
    justify-content: flex-start;
    flex-wrap: wrap;
    // justify-content: center;
  }
}
</style>
