<template>
  <Avatar :label="label" class="p-mr-2" size="large" v-bind:style="{ backgroundColor: color }" />
</template>

<script>
import Avatar from 'primevue/avatar';
export default {
  name: 'AvatarLabel',
  props: ['label'],
  data: () => ({
    color: 'orange',
  }),
  components: {
    Avatar,
  },
  created() {
    var letters = 'BCDEF'.split('');
    var color = '#';
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * letters.length)];
    }
    this.color = color;
  },
};
</script>

<style></style>
