<template>
  <div class="calender-wrapper">
    <Calendar
      placeholder="DD/MM/YYYY"
      class="calender-field"
      :style="{ width: width }"
      v-model="dateInput"
      @date-select="onChange"
      dateFormat="dd/mm/yy"
      :minDate="minDate"
      :maxDate="maxDate"
      showIcon
      :manualInput="false"
      :monthNavigator="true"
      :yearNavigator="true"
      :yearRange="yearRange ? yearRange : '1937:2022'"
      :selectOtherMonths="true"
    />
  </div>
</template>

<script>
import Calendar from 'primevue/calendar';

export default {
  components: { Calendar },
  props: ['placeholder', 'width', 'value', 'minDate', 'maxDate', 'yearRange'],
  data() {
    return {
      dateInput: undefined,
    };
  },
  methods: {
    onChange(value) {
      if (value) {
        this.$emit('change', new Date(value).toISOString());
      }
    },
  },
  watch: {
    value: {
      handler(dateStr) {
        const newDate = new Date(dateStr);
        this.dateInput = isNaN(newDate) ? undefined : newDate;
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss">
@import '@/styles/global.scss';

.calender-wrapper {
  display: flex;
  align-items: center;
  position: relative;
  .calender-field {
    input {
      order: 2;
    }
  }
  .p-calendar-w-btn .p-datepicker-trigger {
    border: 1px solid #ced4da;
    background-color: white;
    border-radius: 4px;
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    border-right: none;
  }
  .p-button {
    color: black !important;
  }
}
.p-inputtext {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}
.p-inputtext:hover {
  border: 1px solid #ced4da !important;
}
.p-inputtext:enabled {
  border: 1px solid #ced4da !important;
}
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
  border: unset !important;
  background: none;
}
.p-datepicker .p-datepicker-header .p-datepicker-title select {
  border: unset !important;
  background: none;
}
.p-datepicker .p-datepicker-header .p-datepicker-title select:focus {
  box-shadow: unset !important;
}
.p-datepicker-title {
  display: flex;
  flex-direction: row-reverse;
}

.p-datepicker-title .p-datepicker-year {
  margin-right: 5px;
}
</style>
