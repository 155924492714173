import { API, TECHLEND_CALCS_URL } from '@/constants.api';
import { _post } from '@/services/crudService';

export default {
  namespaced: true,

  state: {
    addditionalFundsData: {
      addditional_funds: {
        hasAdditionalSavings: null,
        hasGiftedFunds: null,
        additionalSavingAmount: 0,
        savingsAmount: 0,
        giftedFundsAmount: 0,
        giftedFundsPercentage: 0,
      },
      loan_date: null,
      loanData: {},
      loanFormData: {},
      loan_amount: 0,
      loan_term: 0,
      brokerFee: 0,
      brokerFeeAmount: 0,
      brokerNumber: '',
      brokerFirstName: '',
      brokerLastName: '',
      brokerFeeFromApplication: 0,
      loanNote: '',
      loanCalcFields: {
        op_score_value: '',
        ip_score_value: '',
        ip_score_zone_value: '',
        ip_score_category_value: '',
        op_score_zone_value: '',
        op_score_category_value: '',
      },
    },
  },
  getters: {
    addditionalFundsData: ({ addditionalFundsData }) => {
      return addditionalFundsData.addditional_funds;
    },
    loanDate: ({ addditionalFundsData }) => {
      return addditionalFundsData.loan_date;
    },
    loanData: ({ loanData }) => loanData,
    loanAmount: ({ addditionalFundsData }) => addditionalFundsData.loan_amount,
    loanFormData: ({ addditionalFundsData }) => addditionalFundsData.loanFormData,
    brokerNumber: ({ addditionalFundsData }) => addditionalFundsData.brokerNumber,
    loanNote: ({ loanNote }) => loanNote,
    brokerFirstName: ({ addditionalFundsData }) => addditionalFundsData.brokerFirstName,
    brokerLastName: ({ addditionalFundsData }) => addditionalFundsData.brokerLastName,
    brokerFee: ({ addditionalFundsData }) => addditionalFundsData.brokerFee,
    brokerFeeAmount: ({ addditionalFundsData }) => addditionalFundsData.brokerFeeAmount,
  },
  mutations: {
    setAdditionalFundsState(storeState, { fieldName, value }) {
      storeState.addditionalFundsData.addditional_funds[fieldName] = value;
    },
    setLoanDate(storeState, { fieldName, value }) {
      storeState.addditionalFundsData[fieldName] = value;
    },
    setLoanData(storeState, { fieldName, value }) {
      storeState.addditionalFundsData.loanFormData[fieldName] = value;
    },
    setFinalData(storeState) {
      const finalObj = {
        additional_funds: storeState.addditionalFundsData.addditional_funds,
        loan_date: storeState.addditionalFundsData.loan_date,
        eligible_loan_amount: storeState.addditionalFundsData.loan_amount
          ? Number(storeState.addditionalFundsData.loan_amount)
          : 0,
        loan_term: storeState.addditionalFundsData.loan_term ? storeState.addditionalFundsData.loan_term : '0',
        broker_fee: storeState.addditionalFundsData.brokerFee ? Number(storeState.addditionalFundsData.brokerFee) : 0,
        broker_fee_amount: storeState.addditionalFundsData.brokerFeeAmount
          ? Number(storeState.addditionalFundsData.brokerFeeAmount)
          : 0,
        borrow_money: storeState.addditionalFundsData.loanFormData.borrow_money
          ? Number(storeState.addditionalFundsData.loanFormData.borrow_money)
          : 0,
        require_add_fund: storeState.addditionalFundsData.loanFormData.require_add_fund
          ? storeState.addditionalFundsData.loanFormData.require_add_fund
          : false,
        reasonForFunds: storeState.addditionalFundsData.loanFormData.reasonForFunds
          ? storeState.addditionalFundsData.loanFormData.reasonForFunds
          : '',
        additional_fund: storeState.addditionalFundsData.loanFormData.additional_fund
          ? Number(storeState.addditionalFundsData.loanFormData.additional_fund)
          : 0,
        reason_describe: storeState.addditionalFundsData.loanFormData.reason_describe
          ? storeState.addditionalFundsData.loanFormData.reason_describe
          : '',
      };
      storeState.loanData = finalObj;
    },
    setLoanAmount(storeState, payload) {
      storeState.addditionalFundsData.loan_amount = payload.loan_amount || 0;
      storeState.addditionalFundsData.loan_term = '0' || payload.loan_term;

      // op_score_category_value: ""
      storeState.addditionalFundsData.loanCalcFields.op_score_value = payload.op_score_value
        ? payload.op_score_value
        : '0';
      storeState.addditionalFundsData.loanCalcFields.ip_score_value = payload.ip_score_value
        ? payload.ip_score_value
        : '0';
      storeState.addditionalFundsData.loanCalcFields.ip_score_zone_value = payload.ip_score_zone_value
        ? payload.ip_score_zone_value
        : '0';
      storeState.addditionalFundsData.loanCalcFields.ip_score_category_value = payload.ip_score_category_value
        ? payload.ip_score_category_value
        : '0';
      storeState.addditionalFundsData.loanCalcFields.op_score_zone_value = payload.op_score_zone_value
        ? payload.op_score_zone_value
        : '0';
      storeState.addditionalFundsData.loanCalcFields.op_score_category_value = payload.op_score_category_value
        ? payload.op_score_category_value
        : '0';
      storeState.addditionalFundsData.loanCalcFields.loan_duration = payload.loan_duration
        ? payload.loan_duration
        : '0';
    },
    setBorrowLoanAmount(storeState, payload) {
      storeState.addditionalFundsData.loan_amount = payload.loan_amount ? payload.loan_amount : 0;
      // storeState.addditionalFundsData.addditional_funds.additionalSavingAmount = payload.loan_amount ? payload.loan_amount : 0
    },
    setBrokerFee(storeState, payload) {
      storeState.addditionalFundsData.brokerFee = payload.percent ? payload.percent / 100 : 0;
      storeState.addditionalFundsData.brokerFeeAmount = payload.amount ? payload.amount : 0;
    },
    setBrokerNumber(storeState, payload) {
      storeState.addditionalFundsData.brokerNumber = payload;
    },
    setBrokerFirstName(storeState, payload) {
      storeState.addditionalFundsData.brokerFirstName = payload;
    },
    setBrokerLastName(storeState, payload) {
      storeState.addditionalFundsData.brokerLastName = payload;
    },
    setBrokerFeeFromApplication(storeState, payload) {
      storeState.addditionalFundsData.brokerFeeFromApplication = payload.broker_fee_inc_gst
        ? payload.broker_fee_inc_gst * 100
        : 0;
    },
    setLoanNote(storeState, payload) {
      storeState.loanNote = payload;
    },
    setResetState(storeState) {
      storeState.addditionalFundsData = {
        addditional_funds: {
          hasAdditionalSavings: null,
          hasGiftedFunds: null,
          additionalSavingAmount: 0,
          savingsAmount: 0,
          giftedFundsAmount: 0,
          giftedFundsPercentage: 0,
        },
        loan_date: null,
        loanData: {},
        loanFormData: {},
        loan_amount: 0,
        loan_term: 0,
        brokerFee: 0,
        brokerNumber: '',
        brokerFirstName: '',
        brokerLastName: '',
        brokerFeeFromApplication: 0,
        loanNote: '',
      };
    },
  },
  actions: {
    setAdditionalFundsState({ commit }, payload) {
      commit('setAdditionalFundsState', payload);
      commit('setFinalData');
    },
    setLoanDate({ commit }, payload) {
      commit('setLoanDate', payload);
      commit('setFinalData');
    },
    async fetchAdditionalLoanAmount({ state, rootState, commit }) {
      const payload = {
        savingsToContributeToPurchase: state.addditionalFundsData.addditional_funds.additionalSavingAmount,
        giftedFundsAmount: state.addditionalFundsData.addditional_funds.hasGiftedFunds,
        /* gifted_funds_amount:
          state.addditionalFundsData.addditional_funds.giftedFundsAmount,
        gifted_funds_percentage:
          state.addditionalFundsData.addditional_funds.giftedFundsPercentage, */
        /* you_have_add_funds:
          state.addditionalFundsData.addditional_funds.hasAdditionalSavings, */
        additionalFundsRequested: state.addditionalFundsData.addditional_funds.savingsAmount,
        incomingProperties: rootState.applicationform.buying.buyingFormData.buying_property_details.map((ip) => ({
          ...ip,
          state: ip?.state?.toLowerCase() || 'vic',
          postCode: `${ip?.postCode}`,
        })),
        outgoingProperties: rootState.applicationform.selling.sellingFormData.selling_property_details.map((op) => ({
          ...op,
          estimatedValuation: op.estimatedPropertyPrice,
          currentMortgage: op.outstandingMortgageAmount,
          state: op.state.toLowerCase(),
          postCode: `${op.postCode}`,
        })),
        country: 'au',
        // TODO: this step should be
        // setting this data bcoz we collect applicant info post this step
        interestFreeTerm: 3,
        jointApplication: false,
        savingsToContributeToEndDebt: 0,
        brokerFeeIncludingGST: 0.25,
        applicants: [{ age: 35 }],
      };
      const { data } = await _post(TECHLEND_CALCS_URL + API.CALCS.LOAN_AMOUNT, payload);

      commit('setLoanAmount', data.result);
    },
    setBrokerFee({ commit }, payload) {
      commit('setBrokerFee', payload);
    },
    setLoanData({ commit }, payload) {
      commit('setLoanData', payload);
    },
    setLoanNote({ commit }, payload) {
      commit('setLoanNote', payload);
    },
    setBorrowLoanAmount({ commit }, payload) {
      commit('setBorrowLoanAmount', payload);
    },
  },
};
