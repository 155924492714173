export default {
  namespaced: true,

  state: {
    expenseFormData: {
      applicant_2_dependents: null,
      expenses: [],
      decrease_month: 0,
      increase_month: 0,
      changes_in_living_expenses: '',
      totalExpenses: 0,
    },
    expenseData: {},
  },
  getters: {
    expenseFormData: ({ expenseFormData }) => expenseFormData,
    expenseData: ({ expenseData }) => expenseData,
  },
  mutations: {
    setExpenseState(storeState, { fieldName, value }) {
      storeState.expenseFormData[fieldName] = value;
    },
    setFinalData(storeState) {
      const finalObj = {
        applicant_2_dependents: storeState.expenseFormData.applicant_2_dependents,
        applicant_2_decrease_month: storeState.expenseFormData.decrease_month,
        applicant_2_increase_month: storeState.expenseFormData.increase_month,
        applicant_2_changes_in_living_expenses: storeState.expenseFormData.changes_in_living_expenses,
        expenses: storeState.expenseFormData.expenses,
        applicant_2_total_expenses: storeState.expenseFormData.totalExpenses,
      };
      storeState.expenseData = finalObj;
    },
    setFinalDataPrimary(storeState) {
      const finalObj = {
        applicant_1_dependents: storeState.expenseFormData.applicant_2_dependents,
        applicant_1_decrease_month: storeState.expenseFormData.decrease_month,
        applicant_1_increase_month: storeState.expenseFormData.increase_month,
        applicant_1_changes_in_living_expenses: storeState.expenseFormData.changes_in_living_expenses,
        expenses: storeState.expenseFormData.expenses,
        applicant_1_total_expenses: storeState.expenseFormData.totalExpenses,
      };
      storeState.expenseData = finalObj;
    },
    setFinalDataEmpty(storeState) {
      storeState.expenseFormData = {
        applicant_2_dependents: '',
        expenses: [],
        decrease_month: 0,
        increase_month: 0,
        changes_in_living_expenses: '',
      };
      storeState.expenseData = {};
      let obj = {
        applicant_2_dependents: '',
        decrease_month: 0,
        increase_month: 0,
        changes_in_living_expenses: '',
        expenses: [],
      };
      storeState.expenseData = obj;
    },
  },
  actions: {
    setExpenseState({ commit, rootState }, payload) {
      commit('setExpenseState', payload);
      if (rootState.secondapplicationform.applicantType == 'primary') {
        commit('setFinalDataPrimary');
      } else {
        commit('setFinalData');
      }
    },
    setFinalDataEmpty({ commit }, payload) {
      commit('setFinalDataEmpty', payload);
    },
  },
};
