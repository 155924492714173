export const applicationProgress = [
  { id: 1, title: 'Buying', isActive: true, path: '/application/buying/loan-purpose' },
  { id: 2, title: 'Selling', isActive: false, path: '/application/selling/property' },
  { id: 3, title: 'Loan details', isActive: false, path: '/application/loan-details/additional-funds' },
  { id: 4, title: 'Applicant Details', isActive: false, path: '/application/applicant/details' },
  { id: 5, title: 'Income', isActive: false, path: '/application/income/source' },
  { id: 6, title: 'Expenses', isActive: false, path: '/application/expenses/dependent' },
  { id: 7, title: 'Assets and liabilities', isActive: false, path: '/application/assets-liability/assets' },
  { id: 8, title: 'Verify Identity', isActive: false, path: '/application/verify/mobile-number' },
  { id: 9, title: 'Submit Application', isActive: false, path: '/application/declaration/declaration-form' },
];

export const secondApplicationProgress = [
  { id: 1, title: 'Summary Review', isActive: true, path: '/second-applicant/applicant-details#1' },
  { id: 2, title: 'Income', isActive: false, path: '/second-applicant/income/source' },
  { id: 3, title: 'Expenses', isActive: false, path: '/second-applicant/expenses/dependent' },
  { id: 4, title: 'Assets and liabilities', isActive: false, path: '/second-applicant/assets-liability/assets' },
  { id: 5, title: 'Verify Identity', isActive: false, path: '/second-applicant/verify/mobile-number' },
  { id: 6, title: 'Submit Application', isActive: false, path: '/second-applicant/declaration/declaration-form' },
];

export const utmTags = {
  first_click: {
    utm_source: '',
    utm_medium: '',
    utm_campaign: '',
    utm_content: '',
    utm_term: '',
  },
  last_click: {
    utm_source: '',
    utm_medium: '',
    utm_campaign: '',
    utm_content: '',
    utm_term: '',
  },
  int_source: '',
};

export const secondApplicationSubProgress = [
  [
    {
      id: 1,
      title: 'Buying',
      isActive: false,
      value: [
        {
          name: 'Buying',
          hash: '#1',
        },
        {
          name: 'Selling',
          hash: '#2',
        },
        {
          name: 'Loan Details',
          hash: '#3',
        },
        {
          name: 'Application Details',
          hash: '#4',
        },
        {
          name: 'Income',
          hash: '#5',
        },
        {
          name: 'Expenses',
          hash: '#6',
        },
        {
          name: 'Assets and liabilities',
          hash: '#7',
        },
        {
          name: 'Finalise Review',
          hash: '#8',
        },
      ],
    },
  ],
  [
    { id: 1, title: 'Source of income', isActive: false, isCompleted: false },
    { id: 2, title: 'Income details', isActive: false, isCompleted: false },
    { id: 3, title: 'Employment details', isActive: false, isCompleted: false },
    { id: 4, title: 'Additional income', isActive: false, isCompleted: false },
    { id: 5, title: 'Income expectations', isActive: false, isCompleted: false },
  ],
  [
    { id: 1, title: 'Number of dependents', isActive: false, isCompleted: false },
    { id: 2, title: 'Monthly expenses', isActive: false, isCompleted: false },
    { id: 3, title: 'Changes in expenses', isActive: false, isCompleted: false },
  ],
  [
    { id: 1, title: 'Assets', isActive: false, isCompleted: false, value1: [] },
    { id: 2, title: 'Liabilities', isActive: false, isCompleted: false, value1: [] },
  ],
  [
    { id: 1, title: 'Mobile number', isActive: false, isCompleted: false },
    // { id: 2, title: 'How did you hear about us?', isActive: false, isCompleted: false },
  ],
  [{ id: 1, title: 'Confirmation', isActive: false, isCompleted: false }],
];

export const applicationSubProgress = [
  [
    { id: 1, title: 'Loan purpose', isActive: true, isCompleted: false },
    // { id: 2, title: 'Number of properties', isActive: false, isCompleted: false, isHide: true },
    { id: 2, title: 'Property info', isActive: false, isCompleted: false, value: [] },
  ],
  [
    { id: 1, title: 'Number of properties', isActive: false, isCompleted: false },
    { id: 2, title: 'Property info', isActive: false, isCompleted: false, value: [] },
  ],
  [
    { id: 1, title: 'Additional funds', isActive: false, isCompleted: false },
    { id: 2, title: 'Loan amount', isActive: false, isCompleted: false },
    { id: 3, title: 'Loan date', isActive: false, isCompleted: false },
  ],
  [
    { id: 1, title: 'Number of applicants', isActive: false, isCompleted: false },
    { id: 2, title: 'Applicant details', isActive: false, isCompleted: false, value: [] },
    { id: 3, title: 'Property ownership', isActive: false, isCompleted: false },
  ],
  [
    { id: 1, title: 'Source of income', isActive: false, isCompleted: false },
    { id: 2, title: 'Income details', isActive: false, isCompleted: false },
    { id: 3, title: 'Employment details', isActive: false, isCompleted: false },
    { id: 4, title: 'Additional income', isActive: false, isCompleted: false },
    { id: 5, title: 'Income expectations', isActive: false, isCompleted: false },
  ],
  [
    { id: 1, title: 'Number of dependents', isActive: false, isCompleted: false },
    { id: 2, title: 'Monthly expenses', isActive: false, isCompleted: false },
    { id: 3, title: 'Changes in expenses', isActive: false, isCompleted: false },
  ],
  [
    { id: 1, title: 'Assets', isActive: false, isCompleted: false, value1: [] },
    { id: 2, title: 'Liabilities', isActive: false, isCompleted: false, value1: [] },
  ],
  [{ id: 1, title: 'Mobile number', isActive: false, isCompleted: false }],
  [{ id: 1, title: 'Confirmation', isActive: false, isCompleted: false }],
];
