// import Vue from 'vue';
import { createApp } from 'vue';
import { version } from '../package.json';
import App from './App.vue';
import LogRocket from 'logrocket';
import router from './router';
import store from './store';

import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';
import 'primevue/resources/primevue.min.css';
import 'primevue/resources/themes/saga-blue/theme.css';
import PrimeVue from 'primevue/config';
// eslint-disable-next-line
import '@stripe/stripe-js';
import Vue3Transitions from 'vue3-transitions';
import VueCookies from 'vue3-cookies';

// Common componets
import ApplicationToolbar from '@/components/common/ApplicationToolbar.vue';
import AvatarLabel from '@/components/common/AvatarLabel.vue';
import Buttons from '@/components/common/Button.vue';
import Checkbox from '@/components/common/Checkbox.vue';
import DatePicker from '@/components/common/DatePIcker.vue';
import Dropdown from '@/components/common/Dropdown.vue';
import InputNumber from '@/components/common/InputNumber.vue';
import InputWithIcon from '@/components/common/InputFieldWithIcon.vue';
import InputWithIcon2 from '@/components/common/InputFieldWithIconPercent.vue';
import RadioButton from '@/components/common/RadioButton.vue';
import Sidebar from '@/components/common/Sidebar.vue';
import Slider from '@/components/common/Slider.vue';
import Spinner from '@/components/common/Spinner.vue';
import TextArea from '@/components/common/TextArea.vue';
import TextField from '@/components/common/TextField.vue';
import ToastService from 'primevue/toastservice';
import Toolbar from '@/components/common/Toolbar.vue';
import Tooltip from 'primevue/tooltip';
import VueSignaturePad from 'vue-signature-pad';
import VueSmoothScroll from 'v-smooth-scroll';
import vClickOutside from 'click-outside-vue3';

const domainUrl = process.env.VUE_APP_CUSTOM_THEME_DOMAIN_URL ?? 'app.bridgit.com.au';
if (window.location.host === domainUrl) {
  LogRocket.init('vuowhw/customer-portal-v2', {
    release: version,
  });
}

const app = createApp(App);

// In vue 3 production tip is false in default
// Vue.config.productionTip = false;

app.use(PrimeVue);
app.use(VueSignaturePad);
app.use(store);
app.use(VueCookies);
app.use(Vue3Transitions);
app.use(VueSmoothScroll);
app.use(ToastService);
app.use(vClickOutside);

// Common components
app.component('TextField', TextField);
app.component('AvatarLabel', AvatarLabel);
app.component('TextArea', TextArea);
app.component('RadioButton', RadioButton);
app.component('Button', Buttons);
app.component('Dropdown', Dropdown);
app.component('Toolbar', Toolbar);
app.component('ApplicationToolbar', ApplicationToolbar);
app.component('Checkbox', Checkbox);
app.component('DatePicker', DatePicker);
app.component('Sidebar', Sidebar);
app.component('Spinner', Spinner);
app.component('InputNumberField', InputNumber);
app.component('InputWithIcon', InputWithIcon);
app.component('InputWithIcon2', InputWithIcon2);
app.component('Slider', Slider);
app.directive('tooltip', Tooltip);

app.use(router).mount('#app');
