export function generateSecondaryApplicantPayload(formData) {
  const { income, expense, assetsLiability, declaration, applicantType, primaryApplicant } = formData;

  //find applicantId currently we don't need to send it BE but sending it anyway to leverage in future
  const { id: applicantId } = primaryApplicant.applicants.find((applicant) => applicant.applicantType == applicantType);

  const payload = {
    fundsSalaried: handelFundsSalaried(income.incomeData.salaried, applicantId) || [],
    fundsSelfEmployed:
      handleFundsSelfEmployed(fixStateAndCounty(addApplicantId(income.incomeData.self_employed, applicantId))) || [],
    fundsAdditionalIncome: handleFundsAdditionalIncome(income.incomeData.additional_income, applicantId) || [],
    fundsExpenses: handleExpenses(addApplicantId(expense.expenseData.expenses)) || [],
    fundsAssets: handleAssets(assetsLiability.assetLiabilityData.assets) || [],
    incomeExpectations: addApplicantId(income.incomeData.income_expectations) || [],
    employmentDetails: addApplicantId(income.incomeData.employment_details) || [],
    assetsProperties: fixStateAndCounty(assetsLiability.property) || [],
    liabilityCards: addApplicantId(assetsLiability.cards) || [],
    liabilityVehicles: addApplicantId(assetsLiability.vehicles) || [],
    liabilityOthers: addApplicantId(assetsLiability.assetLiabilityData.liability_others) || [],
    userDeclarations: generateDeclarations(primaryApplicant.applicants) || declaration.declarationFormData1,
  };
  return payload;
}

const handleAssets = (assets) =>
  assets.map((asset) => ({
    ...asset,
    applicantIndex: asset.applicantType === 'primary' ? 0 : 1,
  }));

// Fix state and country
const fixStateAndCounty = (arr, applicantId) => {
  return arr.map((i) => ({
    ...i,
    state: i.state.toLowerCase(),
    country: 'au',
    postCode: i.postCode.toString(),
    applicantId,
  }));
};

const handleFundsSelfEmployed = (funds) =>
  funds.map((fund) => ({
    ...fund,
    applicantIndex: fund.applicantType === 'primary' ? 0 : 1,
    state: fund.state?.toLowerCase(),
    country: 'au',
    postCode: fund.postCode?.toString(),
    salaryFrequency: fund.selfEmployedFrequency.toLowerCase(),
    australianBusinessNumber: fund?.aunBusinessNumber,
    salaryAmount: fund?.salary || fund?.salaryAmount,
  }));

// to remove undefined values in expenses
const handleExpenses = (expenses) => expenses.filter((expense) => expense.expenseType != 'undefined');

const generateDeclarations = (applicants) => {
  const types = [
    'bankrupt',
    'shareholder',
    'court',
    'obligations',
    'information',
    'personal',
    'identity',
    'disclosure',
  ];
  const result = [];
  if (applicants.length > 1) {
    result.push(
      ...types.map((type) => ({
        declarationType: 'agreement',
        declarationDescription: type,
        accepted: true,
        applicantType: 'secondary',
        applicantIndex: 1,
      })),
    );
  }
  return result;
};

// add applicationId to objects
const addApplicantId = (arr, applicantId) => {
  return (
    [...arr]?.map((i) => {
      let finalObj = {
        ...i,
        applicantId,
      };
      if (i.state) {
        finalObj.state = i.state.toLowerCase();
      }
      return finalObj;
    }) || []
  );
};

// add structure to funds additional income
const handleFundsAdditionalIncome = (addFunds, applicantId) =>
  addFunds.map((fund) => ({
    applicantId,
    centerlinkPayments: fund.centerlinkPayments,
    centerlinkPaymentsFrequency: fund.centerlinkPaymentsFrequency.toLowerCase(),
    childSupportMaintenance: fund.childSupportMaintenance,
    childSupportMaintenanceFrequency: fund.childSupportMaintenanceFrequency.toLowerCase(),
    familyAllowance: fund.familyAllowance,
    familyAllowanceFrequency: fund.familyAllowanceFrequency.toLowerCase(),
    familyTaxBenefits: fund.familyTaxBenefits,
    familyTaxBenefitsFrequency: fund.familyTaxBenefitsFrequency.toLowerCase(),
    governmentPension: fund.governmentPension,
    governmentPensionFrequency: fund.governmentPensionFrequency.toLowerCase(),
    dividends: fund.dividends,
    super: fund.super,
    superFrequency: fund.superFrequency.toLowerCase(),
    other: fund.other,
    otherFrequency: fund.otherFrequency.toLowerCase(),
    dividendsFrequency: fund.dividendsFrequency.toLowerCase(),
    applicantType: fund.applicantType,
  }));

//handle funds salaried
const handelFundsSalaried = (funds, applicantId) =>
  funds.map((fund) => ({
    applicantId,
    jobType: fund.jobType,
    salaryAmount: fund.salaryAmount,
    salaryPackaged: fund.salaryPackaged,
    salaryFrequency: fund.salaryFrequency.toLowerCase() == 'yearly' ? 'annually' : fund.salaryFrequency.toLowerCase(),
    commissionsAmount: fund.commissionsAmount,
    commissionsFrequency:
      fund.commissionsFrequency.toLowerCase() == 'yearly' ? 'annually' : fund.commissionsFrequency.toLowerCase(),
    bonusesAmount: fund.bonusesAmount,
    bonusesFrequency:
      fund.bonusesFrequency.toLowerCase() == 'yearly' ? 'annually' : fund.bonusesFrequency.toLowerCase(),
    overtimeAmount: fund.overtimePayAmount,
    overtimeFrequency:
      fund.overtimePayFrequency.toLowerCase() == 'yearly' ? 'annually' : fund.overtimePayFrequency.toLowerCase(),
    nameOfEmployer: fund.nameOfEmployer,
    employerAddress1: fund.employerAddress1,
    employerAddress2: fund.employerAddress2,
    employerCity: fund.employerCity,
    employerState: fund.employerState.toLowerCase(),
    employerCountry: 'au',
    postCode: fund.postCode,
    applicantType: fund.applicantType,
    isSalarySacrifice: fund.isSalarySacrifice,
    isTaxable: fund.isTaxable,
  }));
