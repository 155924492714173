<template>
  <div style="position: relative">
    <Toolbar
      :toggleSideNavClicked="toggleSideNavClicked"
      @change="toggleSideNav($event)"
      :toggleOptionClose="toggleOptionClose"
      :key="toolBarKey"
    />
    <div class="flex">
      <div class="sidebar-component">
        <Sidebar
          :menuItems="menuItems"
          :key="sideBarKey"
          @close="closeSidebar"
          :toggleSideNavClicked="toggleSideNavClicked"
          title="hello"
          @change="toggleSideNavClose($event)"
        />
      </div>
      <div class="router-component std-spacing">
        <router-view></router-view>
      </div>
    </div>
    <!-- <div class="fab">
      <img src="@/assets/icons/chat.svg" />
    </div> -->
  </div>
</template>

<script>
export default {
  name: 'Index',
  components: ['Sidebar'],
  data() {
    return {
      sideBarKey: 0,
      toggleSideNavClicked: false,
      toggleOptionClose: false,
      toolBarKey: 10,
      menuItems: [
        {
          id: 1,
          text: 'Dashboard',
          icon: 'home_icon',
          link: '/dashboard',
        },
        { id: 2, text: 'My Loans', icon: 'myloan_icon', link: '/loan' },
        {
          id: 3,
          text: 'My statements',
          icon: 'statement_icon',
          link: '/statements',
        },
        {
          id: 4,
          text: 'Contact us',
          icon: 'contact_icon',
          link: '/contact',
        },
        {
          id: 5,
          text: 'Settings',
          icon: 'settings_icon',
          link: '/settings',
        },
      ],
    };
  },
  methods: {
    closeSidebar() {
      this.toggleSideNavClicked = false;
      this.sideBarKey += 1;
    },
    toggleSideNav(event) {
      this.sideBarKey += 1;
      this.toggleSideNavClicked = event;
    },
    toggleSideNavClose(event) {
      this.sideBarKey += 1;
      this.toggleSideNavClicked = event;
      this.toggleOptionClose = false;
      this.toolBarKey += 1;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';

.sidebar-component {
  background-color: $secondary-color;
  height: calc(100vh - 80px);
  position: relative;
}

.std-spacing {
  padding: 0 45px;
  @media (max-width: 992px) {
    padding: 0 20px;
  }
}

.fab {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 40px;
  right: 40px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  z-index: 99;
  background-color: $primary-color;
  box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}
.router-component {
  background: #f1f6fb;
  // min-height: 100vh;
  height: calc(100vh - 80px);
  overflow: auto;
  width: 100%;
}
</style>
