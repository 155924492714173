export default {
  namespaced: true,
  state: {
    employmentFormData: {
      number_of_full_time: 0,
      number_of_casual: 0,
      number_of_part_time: 0,
      number_of_self: 0,
      number_of_unemployed: 0,
      number_of_retired: 0,
      fullTime_1: [],
      casual_1: [],
      parttime_1: [],
      selfemployed_1: [],
      additional_1: [],
      empdetails_1: [],
      incomeexpectation_1: [],
      applicant_1_isUnemployed: false,
      applicant_1_isRetired: false,
      applicant_2_isUnemployed: false,
      applicant_2_isRetired: false,
    },
    employmentFormData2: {
      number_of_full_time: 0,
      number_of_casual: 0,
      number_of_part_time: 0,
      number_of_self: 0,
      number_of_unemployed: 0,
      number_of_retired: 0,
      fullTime_2: [],
      casual_2: [],
      parttime_2: [],
      selfemployed_2: [],
      additional_2: [],
      empdetails_2: [],
      incomeexpectation_2: [],
    },
    incomeData: {},
  },
  getters: {
    employmentFormData: ({ employmentFormData }) => employmentFormData,
    employmentFormData2: ({ employmentFormData2 }) => employmentFormData2,
    incomeData: ({ incomeData }) => incomeData,
  },
  mutations: {
    setIncomeData(storeState, { fieldName, index, value, type }) {
      let obj = {};
      switch (type) {
        case 'fulltime1':
          obj[fieldName] = value;
          obj['applicantType'] = 'primary';
          obj['jobType'] = 'full_time';
          storeState.employmentFormData['fullTime_1'][index] = {
            ...storeState.employmentFormData['fullTime_1'][index],
            ...obj,
          };
          break;
        case 'casual1':
          obj[fieldName] = value;
          obj['applicantType'] = 'primary';
          obj['jobType'] = 'casual';
          storeState.employmentFormData['casual_1'][index] = {
            ...storeState.employmentFormData['casual_1'][index],
            ...obj,
          };
          break;
        case 'parttime1':
          obj[fieldName] = value;
          obj['applicantType'] = 'primary';
          obj['jobType'] = 'part_time';
          storeState.employmentFormData['parttime_1'][index] = {
            ...storeState.employmentFormData['parttime_1'][index],
            ...obj,
          };
          break;
        case 'selfemployed1':
          obj[fieldName] = value;
          obj['applicantType'] = 'primary';
          obj['jobType'] = 'self_employed';
          storeState.employmentFormData['selfemployed_1'][index] = {
            ...storeState.employmentFormData['selfemployed_1'][index],
            ...obj,
          };
          break;
        case 'additional1':
          obj[fieldName] = value;
          obj['applicantType'] = 'primary';
          storeState.employmentFormData['additional_1'][index] = {
            ...storeState.employmentFormData['additional_1'][index],
            ...obj,
          };
          break;
        case 'empdetails1':
          obj[fieldName] = value;
          obj['applicantType'] = 'primary';
          storeState.employmentFormData['empdetails_1'][index] = {
            ...storeState.employmentFormData['empdetails_1'][index],
            ...obj,
          };
          break;
        case 'incomeexpectation1':
          obj[fieldName] = value;
          obj['applicantType'] = 'primary';
          storeState.employmentFormData['incomeexpectation_1'][index] = {
            ...storeState.employmentFormData['incomeexpectation_1'][index],
            ...obj,
          };
          break;
        case 'fulltime2':
          obj[fieldName] = value;
          obj['applicantType'] = 'secondary';
          obj['jobType'] = 'full_time';
          storeState.employmentFormData2['fullTime_2'][index] = {
            ...storeState.employmentFormData2['fullTime_2'][index],
            ...obj,
          };
          break;
        case 'casual2':
          obj[fieldName] = value;
          obj['applicantType'] = 'secondary';
          obj['jobType'] = 'casual';
          storeState.employmentFormData2['casual_2'][index] = {
            ...storeState.employmentFormData2['casual_2'][index],
            ...obj,
          };
          break;
        case 'parttime2':
          obj[fieldName] = value;
          obj['applicantType'] = 'secondary';
          obj['jobType'] = 'part_time';
          storeState.employmentFormData2['parttime_2'][index] = {
            ...storeState.employmentFormData2['parttime_2'][index],
            ...obj,
          };
          break;
        case 'selfemployed2':
          obj[fieldName] = value;
          obj['applicantType'] = 'secondary';
          obj['jobType'] = 'self_employed';
          storeState.employmentFormData2['selfemployed_2'][index] = {
            ...storeState.employmentFormData2['selfemployed_2'][index],
            ...obj,
          };
          break;
        case 'additional2':
          obj[fieldName] = value;
          obj['applicantType'] = 'secondary';
          storeState.employmentFormData2['additional_2'][index] = {
            ...storeState.employmentFormData2['additional_2'][index],
            ...obj,
          };
          break;
        case 'empdetails2':
          obj[fieldName] = value;
          obj['applicantType'] = 'secondary';
          storeState.employmentFormData2['empdetails_2'][index] = {
            ...storeState.employmentFormData2['empdetails_2'][index],
            ...obj,
          };
          break;
        case 'incomeexpectation2':
          obj[fieldName] = value;
          obj['applicantType'] = 'secondary';
          storeState.employmentFormData2['incomeexpectation_2'][index] = {
            ...storeState.employmentFormData2['incomeexpectation_2'][index],
            ...obj,
          };
          break;
      }
    },
    setIncomeDataEmpty(storeState, { type }) {
      switch (type) {
        case 'fulltime1':
          storeState.employmentFormData['fullTime_1'] = [];
          break;
        case 'casual1':
          storeState.employmentFormData['casual_1'] = [];
          break;
        case 'parttime1':
          storeState.employmentFormData['parttime_1'] = [];
          break;
        case 'selfemployed1':
          storeState.employmentFormData['selfemployed_1'] = [];
          break;
        case 'all1':
          storeState.employmentFormData['fullTime_1'] = [];
          storeState.employmentFormData['casual_1'] = [];
          storeState.employmentFormData['parttime_1'] = [];
          storeState.employmentFormData['selfemployed_1'] = [];
          break;
        case 'fulltime2':
          storeState.employmentFormData2['fullTime_2'] = [];
          break;
        case 'casual2':
          storeState.employmentFormData2['casual_2'] = [];
          break;
        case 'parttime2':
          storeState.employmentFormData2['parttime_2'] = [];
          break;
        case 'selfemployed2':
          storeState.employmentFormData2['selfemployed_2'] = [];
          break;
        case 'all2':
          storeState.employmentFormData2['fullTime_2'] = [];
          storeState.employmentFormData2['casual_2'] = [];
          storeState.employmentFormData2['parttime_2'] = [];
          storeState.employmentFormData2['selfemployed_2'] = [];
          storeState.employmentFormData2['additional_2'] = [];
          storeState.employmentFormData2['empdetails_2'] = [];
          storeState.employmentFormData2['incomeexpectation_2'] = [];
          break;
      }
    },
    setEmploymentFormData(storeState, { fieldName, value }) {
      storeState.employmentFormData[fieldName] = value;
    },
    setEmploymentFormData2(storeState, { fieldName, value }) {
      storeState.employmentFormData2[fieldName] = value;
    },
    setFinalData(storeState) {
      let obj = {
        salaried: [
          ...storeState.employmentFormData.fullTime_1,
          ...storeState.employmentFormData.casual_1,
          ...storeState.employmentFormData.parttime_1,
          ...storeState.employmentFormData2.fullTime_2,
          ...storeState.employmentFormData2.casual_2,
          ...storeState.employmentFormData2.parttime_2,
        ],
        self_employed: [
          ...storeState.employmentFormData.selfemployed_1,
          ...storeState.employmentFormData2.selfemployed_2,
        ],
        additional_income: [
          ...storeState.employmentFormData.additional_1,
          ...storeState.employmentFormData2.additional_2,
        ],
        income_expectations: [
          ...storeState.employmentFormData.incomeexpectation_1,
          ...storeState.employmentFormData2.incomeexpectation_2,
        ],
        employment_details: [
          ...storeState.employmentFormData.empdetails_1,
          ...storeState.employmentFormData2.empdetails_2,
        ],
        applicant_1_isUnemployed: storeState.employmentFormData.applicant_1_isUnemployed
          ? storeState.employmentFormData.applicant_1_isUnemployed
          : false,
        applicant_1_isRetired: storeState.employmentFormData.applicant_1_isRetired
          ? storeState.employmentFormData.applicant_1_isRetired
          : false,
        applicant_2_isUnemployed: storeState.employmentFormData2.applicant_2_isUnemployed
          ? storeState.employmentFormData2.applicant_2_isUnemployed
          : false,
        applicant_2_isRetired: storeState.employmentFormData2.applicant_2_isRetired
          ? storeState.employmentFormData2.applicant_2_isRetired
          : false,
      };
      storeState.incomeData = obj;
    },
    setFinalDataEmpty(storeState) {
      storeState.employmentFormData = {
        number_of_full_time: 0,
        number_of_casual: 0,
        number_of_part_time: 0,
        number_of_self: 0,
        number_of_unemployed: 0,
        number_of_retired: 0,
        fullTime_1: [],
        casual_1: [],
        parttime_1: [],
        selfemployed_1: [],
        additional_1: [],
        empdetails_1: [],
        incomeexpectation_1: [],
        applicant_1_isUnemployed: false,
        applicant_1_isRetired: false,
      };
      storeState.employmentFormData2 = {
        number_of_full_time: 0,
        number_of_casual: 0,
        number_of_part_time: 0,
        number_of_self: 0,
        number_of_unemployed: 0,
        number_of_retired: 0,
        fullTime_2: [],
        casual_2: [],
        parttime_2: [],
        selfemployed_2: [],
        additional_2: [],
        empdetails_2: [],
        incomeexpectation_2: [],
        applicant_2_isUnemployed: false,
        applicant_2_isRetired: false,
      };
      storeState.incomeData = {};
      let obj = {
        salaried: [],
        self_employed: [],
        additional_income: [],
        income_expectations: [],
        employment_details: [],
        applicant_1_isUnemployed: false,
        applicant_1_isRetired: false,
        applicant_2_isUnemployed: false,
        applicant_2_isRetired: false,
      };
      storeState.incomeData = obj;
    },
    setAdditionalIncomeDataEmpty(storeState, { title, count }) {
      switch (title) {
        case 'full_time':
          storeState.employmentFormData.fullTime_1.splice(count);
          return;
        case 'part_time':
          storeState.employmentFormData.parttime_1.splice(count);
          return;
        case 'casual':
          storeState.employmentFormData.casual_1.splice(count);
          return;
        case 'self_employed':
          storeState.employmentFormData.selfemployed_1.splice(count);
          return;
      }
    },
    setAdditionalIncomeData2Empty(storeState, { title, count }) {
      switch (title) {
        case 'full_time':
          storeState.employmentFormData2.fullTime_2.splice(count);
          return;
        case 'part_time':
          storeState.employmentFormData2.parttime_2.splice(count);
          return;
        case 'casual':
          storeState.employmentFormData2.casual_2.splice(count);
          return;
        case 'self_employed':
          storeState.employmentFormData2.selfemployed_2.splice(count);
          return;
      }
    },
    setResetState(storeState) {
      storeState.employmentFormData = {
        number_of_full_time: 0,
        number_of_casual: 0,
        number_of_part_time: 0,
        number_of_self: 0,
        number_of_unemployed: 0,
        number_of_retired: 0,
        fullTime_1: [],
        casual_1: [],
        parttime_1: [],
        selfemployed_1: [],
        additional_1: [],
        empdetails_1: [],
        incomeexpectation_1: [],
        applicant_1_isUnemployed: false,
        applicant_1_isRetired: false,
        applicant_2_isUnemployed: false,
        applicant_2_isRetired: false,
      };
      storeState.employmentFormData2 = {
        number_of_full_time: 0,
        number_of_casual: 0,
        number_of_part_time: 0,
        number_of_self: 0,
        number_of_unemployed: 0,
        number_of_retired: 0,
        fullTime_2: [],
        casual_2: [],
        parttime_2: [],
        selfemployed_2: [],
        additional_2: [],
        empdetails_2: [],
        incomeexpectation_2: [],
      };
      storeState.incomeData = {};
    },
  },
  actions: {
    setEmploymentFormData({ commit }, payload) {
      commit('setEmploymentFormData', payload);
    },
    setEmploymentFormData2({ commit }, payload) {
      commit('setEmploymentFormData2', payload);
    },
    setIncomeData({ commit }, payload) {
      commit('setIncomeData', payload);
    },
    setFinalData({ commit }) {
      commit('setFinalData');
    },
    setIncomeDataEmpty({ commit }, payload) {
      commit('setIncomeDataEmpty', payload);
    },
    setFinalDataEmpty({ commit }, payload) {
      commit('setFinalDataEmpty', payload);
    },
    setAdditionalIncomeDataEmpty({ commit }, payload) {
      commit('setAdditionalIncomeDataEmpty', payload);
    },
    setAdditionalIncomeData2Empty({ commit }, payload) {
      commit('setAdditionalIncomeData2Empty', payload);
    },
  },
};
