<template>
  <div>
    <label class="p-float-label">{{ label }}</label>
    <div class="input-wrapper">
      <RadioButton name="category" :disabled="disable" @change="EmitToParent" :value="value" />
    </div>
  </div>
</template>

<script>
import RadioButton from 'primevue/radiobutton';
export default {
  name: 'RadioButtons',
  props: ['radioButtonData', 'disable', 'label', 'value'],
  methods: {
    EmitToParent() {
      // console.log(this.selectedCategory.name);
    },
  },
  components: {
    RadioButton,
  },
};
</script>

<style lang="scss">
.p-float-label {
  margin-bottom: 1rem;
}

.input-wrapper {
  display: flex;
  align-items: center;
}
.input-field {
  border-color: unset;
  outline: 0 none;
  outline-offset: 0;
  box-shadow: unset !important;
  border-radius: unset !important;
}

.p-inputtext {
  border: 1px solid rgba(2, 0, 56, 0.15);
  border-radius: 4px !important;
}
.p-inputtext:enabled:focus {
  border-color: rgba(2, 0, 56, 0.15);
  border: 2px solid rgba(100, 97, 170, 0.3);
}
.p-inputtext:enabled:hover {
  border-color: rgba(2, 0, 56, 0.15);
  border: 2px solid rgba(100, 97, 170, 0.3);
}
</style>
