export const convertFiletoBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result.replace('data:', '').replace(/^.+,/, ''));
    reader.onerror = (error) => reject(error);
  });
};

export const base64toFile = (base64FileUrl, fileName = 'file-name') => {
  return fetch(base64FileUrl)
    .then((res) => res.blob())
    .then((blob) => {
      const file = new File([blob], fileName, { type: 'image/png' });
      return file;
    });
};
