import { API, TECHLEND_BE_URL } from '@/constants.api';
import { _get } from '@/services/crudService';
import { secondApplicationProgress, secondApplicationSubProgress } from '../constant';
import router from '../../router';

import assetsLiability from './assetsLiability';
import declaration from './declaration';
import expense from './expense';
import income from './income';
import verify from './verify';
// import { useCookies } from "vue3-cookies";

export default {
  namespaced: true,

  state: {
    applicationProgress: secondApplicationProgress,
    applicationSubProgress: secondApplicationSubProgress,
    selectedProgressIndex: 0,
    selectedSubProgressIndex: 0,
    applicationProgressPercentage: 20,
    primaryApplicant: null,
    applicationId: '',
    applicantType: 'secondary',
    isFromCRM: false,
    isPreApprovalValue: true,
    isFromBroker: false,
    app_id: '',
  },
  getters: {
    applicationProgress: ({ applicationProgress }) => applicationProgress,
    applicationSubProgress: ({ applicationSubProgress }) => applicationSubProgress,
    selectedProgressIndex: ({ selectedProgressIndex }) => selectedProgressIndex,
    selectedSubProgressIndex: ({ selectedSubProgressIndex }) => selectedSubProgressIndex,
    applicationProgressPercentage: ({ applicationProgressPercentage }) => applicationProgressPercentage,
    primaryApplicant: ({ primaryApplicant }) => primaryApplicant,
    applicationId: ({ applicationId }) => applicationId,
    applicantType: ({ applicantType }) => applicantType,
    isFromCRM: ({ isFromCRM }) => isFromCRM,
    isPreApproval: ({ isPreApprovalValue }) => isPreApprovalValue,
    isFromBroker: ({ isFromBroker }) => isFromBroker,
  },
  mutations: {
    setProgressIndex(storeState, payload) {
      storeState.selectedProgressIndex = payload;
    },
    setSubProgressIndex(storeState, payload) {
      storeState.selectedSubProgressIndex = payload;
    },
    setApplicationPercentage(storeState, payload) {
      storeState.applicationProgressPercentage = payload;
    },
    setPrimaryApplicantData(storeState, payload) {
      let otherDetails = {};
      storeState.applicationId = payload && payload?.id ? payload.id : '';
      if (payload && payload.id) {
        otherDetails = payload;
      }
      storeState.primaryApplicant = payload && payload?.id ? { ...payload, ...otherDetails } : {};
      storeState.isPreApprovalValue = payload && payload?.isPreApproval;
    },
    setApplicantType(storeState, payload) {
      storeState.applicantType = payload;
    },
    setIsFromCRM(storeState, payload) {
      storeState.isFromCRM = payload;
    },
    setIsFromBroker(storeState, payload) {
      storeState.isFromBroker = payload;
    },
    setAppId(storeState, payload) {
      storeState.app_id = payload;
    },
  },
  actions: {
    setProgressIndex({ commit }, payload) {
      commit('setProgressIndex', payload);
    },
    setAppId({ commit }, payload) {
      commit('setAppId', payload);
    },
    setSubProgressIndex({ commit }, payload) {
      commit('setSubProgressIndex', payload);
    },
    setApplicationPercentage({ commit }, payload) {
      commit('setApplicationPercentage', payload);
    },
    async getApplicationData({ commit, state }, id) {
      try {
        const { data } = await _get(
          TECHLEND_BE_URL + API.APPLICATION.RETRIEVE_APPLICATION + `?id=${id || state.app_id}`,
        );
        commit('setPrimaryApplicantData', data);
      } catch (err) {
        commit('setPrimaryApplicantData', null);
        return err;
      }
    },
    // get application details for secondary applicant token which was found in the url from the mail sent from sendgrid
    async getApplicationDataWithScondaryApplicantToken({ commit }) {
      try {
        const { data } = await _get(TECHLEND_BE_URL + API.APPLICATION.RETRIEVE_APPLICATION);
        commit('setPrimaryApplicantData', data.application);
        return data?.application || true;
      } catch (error) {
        console.log(error);
        commit('setPrimaryApplicantData', null);
        return true;
      }
    },
    activeCheck({ state, commit }, payload) {
      if (payload === 100) {
        commit('setApplicationPercentage', 100);
        return;
      }
      let percentage = (payload + 1) * 20;
      commit('setApplicationPercentage', percentage);
      if (!state.applicationProgress[payload].isActive) {
        let activeIndex = 0;
        state.applicationProgress.map((item, index) => {
          if (item.isActive && index < payload) {
            activeIndex = index;
          }
        });
        router.push(state.applicationProgress[activeIndex].path);
      }
    },
    setApplicantType({ commit }, payload) {
      commit('setApplicantType', payload);
    },
    setIsFromCRM({ commit }, payload) {
      commit('setIsFromCRM', payload);
    },
    setIsFromBroker({ commit }, payload) {
      commit('setIsFromBroker', payload);
    },
  },
  modules: {
    income,
    expense,
    assetsLiability,
    verify,
    declaration,
  },
};
