export default {
  namespaced: true,
  state: {
    liabilityFormData: {
      number_of_credit_card: 0,
      number_of_vehicle: 0,
      number_of_personal_loan: 0,
    },
    assetFormData: {
      number_of_property: 0,
      number_of_savings: 0,
      number_of_shares: 0,
      number_of_superannuation: 0,
    },
    property: [],
    cards: [],
    vehicles: [],
    loans: [],
    assets: [],
    savings: [],
    shares: [],
    superannuations: [],
    assetLiabilityData: {},
  },
  getters: {
    liabilityFormData: ({ liabilityFormData }) => liabilityFormData,
    assetFormData: ({ assetFormData }) => assetFormData,
    propertyData: ({ property }) => property,
    cardData: ({ cards }) => cards,
    vehicleData: ({ vehicles }) => vehicles,
    loanData: ({ loans }) => loans,
    savingsData: ({ savings }) => savings,
    sharesData: ({ shares }) => shares,
    annuationData: ({ superannuations }) => superannuations,
    assetLiabilityData: ({ assetLiabilityData }) => assetLiabilityData,
  },
  mutations: {
    setLiabilityFormData(storeState, { fieldName, value }) {
      storeState.liabilityFormData[fieldName] = value;
    },
    setPropertyData(storeState, { fieldName, index, value, rootState }) {
      const { secondapplicationform } = rootState;
      let propertyObj = {};
      propertyObj['applicantType'] = secondapplicationform.applicantType == 'primary' ? 'primary' : 'secondary';
      propertyObj[fieldName] = value;
      storeState.property[index] = {
        ...storeState.property[index],
        ...propertyObj,
      };
    },
    setCreditCardData(storeState, { fieldName, index, value, rootState }) {
      const { secondapplicationform } = rootState;
      let propertyObj = {};
      propertyObj['applicantType'] = secondapplicationform.applicantType == 'primary' ? 'primary' : 'secondary';
      propertyObj[fieldName] = value;
      storeState.cards[index] = { ...storeState.cards[index], ...propertyObj };
    },
    setVehicleData(storeState, { fieldName, index, value, rootState }) {
      const { secondapplicationform } = rootState;
      let propertyObj = {};
      propertyObj['applicantType'] = secondapplicationform.applicantType == 'primary' ? 'primary' : 'secondary';
      propertyObj[fieldName] = value;
      storeState.vehicles[index] = {
        ...storeState.vehicles[index],
        ...propertyObj,
      };
    },
    setLoanData(storeState, { fieldName, index, value, rootState }) {
      const { secondapplicationform } = rootState;
      let propertyObj = {};
      propertyObj['applicantType'] = secondapplicationform.applicantType == 'primary' ? 'primary' : 'secondary';
      propertyObj[fieldName] = value;
      storeState.loans[index] = { ...storeState.loans[index], ...propertyObj };
    },
    setAssetFormData(storeState, { fieldName, value }) {
      storeState.assetFormData[fieldName] = value;
    },
    setSavingsData(storeState, payload) {
      storeState.savings = payload;
      storeState.assets = [...storeState.savings, ...storeState.assets];
    },
    setSharesData(storeState, payload) {
      storeState.shares = payload;
      storeState.assets = [...storeState.shares, ...storeState.assets];
    },
    setAnnuationData(storeState, payload) {
      storeState.superannuations = payload;
      storeState.assets = [...storeState.shares, ...storeState.assets];
    },
    setFinalData(storeState) {
      const finalObj = {
        assets: [...storeState.savings, ...storeState.shares, ...storeState.superannuations],
        assets_properties: [...storeState.property],
        liability_cards: [...storeState.cards],
        liability_vechicles: [...storeState.vehicles],
        liability_others: [...storeState.loans],
      };
      storeState.assetLiabilityData = finalObj;
    },
    setFinalDataEmpty(storeState) {
      storeState.liabilityFormData = {
        number_of_credit_card: 0,
        number_of_vehicle: 0,
        number_of_personal_loan: 0,
      };
      storeState.assetFormData = {
        number_of_property: 0,
        number_of_savings: 0,
        number_of_shares: 0,
        number_of_superannuation: 0,
      };
      storeState.property = [];
      storeState.cards = [];
      storeState.vehicles = [];
      storeState.loans = [];
      storeState.assets = [];
      storeState.savings = [];
      storeState.shares = [];
      storeState.superannuations = [];
      storeState.assetLiabilityData = {};
      let obj = {
        assets: [],
        assets_properties: [],
        liability_cards: [],
        liability_vechicles: [],
        liability_others: [],
      };
      storeState.assetLiabilityData = obj;
    },
    setLiabilityDataEmpty(storeState, { type }) {
      switch (type) {
        case 'cards':
          storeState.cards = [];
          break;
        case 'vehicles':
          storeState.vehicles = [];
          break;
        case 'others':
          storeState.loans = [];
          break;
        case 'all':
          storeState.cards = [];
          storeState.vehicles = [];
          storeState.loans = [];
          break;
      }
    },
    setAssetDataEmpty(storeState, { type }) {
      switch (type) {
        case 'property':
          storeState.property = [];
          break;
        case 'savings':
          storeState.savings = [];
          break;
        case 'shares':
          storeState.shares = [];
          break;
        case 'superannuation':
          storeState.superannuations = [];
          break;
        case 'all':
          storeState.property = [];
          storeState.savings = [];
          storeState.shares = [];
          storeState.superannuations = [];
          break;
      }
    },
    setAdditionalLiabilityDataEmpty(storeState, { title, count }) {
      switch (title) {
        case 'credit_card':
          storeState.cards.splice(count);
          break;
        case 'motor_vehicle':
          storeState.vehicles.splice(count);
          break;
        case 'personal_loan':
          storeState.loans.splice(count);
          break;
      }
    },
    setAdditionalAssetDataEmpty(storeState, { title, count }) {
      switch (title) {
        case 'property':
          storeState.property.splice(count);
          break;
        case 'savings':
          storeState.savings.splice(count);
          break;
        case 'shares':
          storeState.shares.splice(count);
          break;
        case 'superannuation':
          storeState.superannuations.splice(count);
          break;
      }
    },
  },
  actions: {
    setLiabilityFormData({ commit }, payload) {
      commit('setLiabilityFormData', payload);
      commit('setFinalData');
    },
    setAssetFormData({ commit }, payload) {
      commit('setAssetFormData', payload);
      commit('setFinalData');
    },
    //Liability
    setCreditCardData({ commit, rootState }, payload) {
      payload.rootState = rootState;
      commit('setCreditCardData', payload);
      commit('setFinalData');
    },
    setVehicleData({ commit, rootState }, payload) {
      payload.rootState = rootState;
      commit('setVehicleData', payload);
      commit('setFinalData');
    },
    setLoanData({ commit, rootState }, payload) {
      payload.rootState = rootState;
      commit('setLoanData', payload);
      commit('setFinalData');
    },
    //Assets
    setPropertyData({ commit, rootState }, payload) {
      payload.rootState = rootState;
      commit('setPropertyData', payload);
      commit('setFinalData');
    },
    setSavingsData({ commit }, payload) {
      commit('setSavingsData', payload);
      commit('setFinalData');
    },
    setSharesData({ commit }, payload) {
      commit('setSharesData', payload);
      commit('setFinalData');
    },
    setAnnuationData({ commit }, payload) {
      commit('setAnnuationData', payload);
      commit('setFinalData');
    },
    setLiabilityDataEmpty({ commit }, payload) {
      commit('setLiabilityDataEmpty', payload);
    },
    setAssetDataEmpty({ commit }, payload) {
      commit('setAssetDataEmpty', payload);
    },
    setFinalDataEmpty({ commit }, payload) {
      commit('setFinalDataEmpty', payload);
    },
    setAdditionalLiabilityDataEmpty({ commit }, payload) {
      commit('setAdditionalLiabilityDataEmpty', payload);
    },
    setAdditionalAssetDataEmpty({ commit }, payload) {
      commit('setAdditionalAssetDataEmpty', payload);
    },
  },
};
