export function camelToUnderscore(key) {
  if (!key) return undefined;
  var result = key.replace(/([A-Z])/g, ' $1');
  return result.split(' ').join('_').toLowerCase();
}

export function currentDate() {
  const date = new Date();

  // set time to midnight.
  date.setHours(0, 0, 0, 0);
  return date;
}
