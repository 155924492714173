import { authGuard } from '@/services/Authentication';
import ApplicationComponent from '@/views/customer/Loan/Applications/index.vue';
import ConditionalOfferPayment from '@/views/customer/Loan/Progress-Summary/Progress/ConditionalOfferPayment.vue';
import ContactComponent from '@/views/customer/Contact/index.vue';
import CusotmerComponent from '@/views/customer';
import DashboardComponent from '@/views/customer/Dashboard/index.vue';
import LoanComponent from '@/views/customer/Loan/index.vue';
import LoanDetails from '@/views/customer/Loan/LoanDetail';
import ProgressAndSummary from '@/views/customer/Loan/Progress-Summary/index.vue';
import SettingsComponent from '@/views/customer/Settings/index.vue';
import StatementsComponent from '@/views/customer/Statements/index.vue';

export const CustomerRoutes = [
  {
    path: '/',
    component: CusotmerComponent,
    beforeEnter: authGuard,
    children: [
      {
        path: 'loan',
        component: LoanComponent,
        children: [
          {
            path: '',
            component: ApplicationComponent,
            name: 'Loan Application',
          },
          {
            path: ':id/application',
            component: ProgressAndSummary,
            name: 'Progress-Summary',
          },
          {
            path: ':id/summary',
            component: LoanDetails,
            name: 'Loan Applicatsion',
          },
          {
            path: 'settlement',
          },
        ],
      },
      {
        // TODO: it's not clear when the user would hit this page
        // but it co-opts the accept conditional offer payment form
        // to add new billing information as a standalone page, this
        // should be split off into its own page
        path: '/payment',
        component: ConditionalOfferPayment,
        name: 'PaymentMethod',
      },
      {
        path: '/dashboard',
        component: DashboardComponent,
        name: 'Dashboard',
      },
      {
        path: '/statements',
        component: StatementsComponent,
        name: 'Statements',
      },
      {
        path: '/contact',
        component: ContactComponent,
        name: 'Contact',
      },
      {
        path: '/settings',
        component: SettingsComponent,
        name: 'Settings',
      },
    ],
  },
];

export default CustomerRoutes;
