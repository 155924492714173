<template>
  <div>
    <label :class="labelClass" class="p-float-label hk-grotest-medium"
      >{{ label }}<span v-if="required" class="required"></span
    ></label>
    <div class="input-wrapper">
      <span v-if="icon" class="icon-container">
        <i :class="icon" class="icons field" />
      </span>
      <InputText
        v-model="editValue"
        class="input-field md:col-6"
        @keydown="keyDown"
        :placeholder="placeholder"
        :type="type"
        :disabled="disabled"
        :pattern="pattern"
        :max="max"
        :min="min"
        :maxLength="maxLength"
        :style="{
          height: `${height}`,
          width: `${width}`,
          border: `1px solid ${color} !important`,
          color: `${color}`,
        }"
        @input="onInput"
      />
    </div>
    <label v-if="errorMessages" class="error-text required bold-400">{{ errorMessages }}</label>
  </div>
</template>

<script>
import InputText from 'primevue/inputtext';
export default {
  name: 'TextField',
  components: { InputText },
  props: [
    'border',
    'color',
    'disabled',
    'errorMessages',
    'height',
    'icon',
    'label',
    'labelClass',
    'max',
    'maxLength',
    'min',
    'pattern',
    'placeholder',
    'required',
    'type',
    'value',
    'width',
  ],
  emits: ['onInput', 'change'],
  data() {
    return {
      editValue: '',
    };
  },
  methods: {
    onInput({ target }) {
      this.$emit('onInput', target.value);
    },
    keyDown(value) {
      if (this.type == 'number') {
        let invalidChars = ['-', '+', 'e', '.'];
        if (invalidChars.includes(value.key)) {
          value.preventDefault();
        }
      }
    },
  },
  watch: {
    editValue() {
      this.$emit('change', this.editValue);
    },
  },
  mounted() {
    this.editValue = this.value;
  },
};
</script>

<style lang="scss" scoped>
.input-wrapper {
  display: flex;
  align-items: center;
}
.input-field {
  border-color: unset;
  outline: 0 none;
  outline-offset: 0;
  box-shadow: unset !important;
  border-radius: unset !important;
}

.icons {
  font-weight: bold !important;
  height: 100%;
  font-size: 1.2rem;
  padding: 10px;
  min-width: 40px;
  // border-right: 1px solid #ced4da;
  border: 1px solid #ced4da;
  margin-top: 1rem;
  background: #fff;
}

.p-inputtext {
  border: 1px solid rgba(2, 0, 56, 0.15);
  border-radius: 4px !important;
}
.p-inputtext:enabled:focus {
  border-color: rgba(2, 0, 56, 0.15);
  border: 1px solid rgba(100, 97, 170, 0.3);
  box-shadow: unset;
}
.p-inputtext:enabled:hover {
  border-color: rgba(2, 0, 56, 0.15);
  border: 1px solid rgba(100, 97, 170, 0.3);
  box-shadow: unset;
}
.p-inputtext:enabled:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.1rem rgba(2, 0, 56, 0.3) !important;
  border-color: rgba(2, 0, 56, 0.15);
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.required {
  color: red;
}
.error-text {
  font-size: 14px;
}
</style>
