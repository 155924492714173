<template>
  <div>
    <Spinner v-if="isLoading" class="flex" />
    <div v-else class="progress-container">
      <Timeline
        v-if="!loanSettlement"
        :progressStatus="progress"
        @getProgress="getProgress"
        :status="status"
        :key="timelineKey"
      />
      <LoanSettlementProgress v-if="loanSettlement" />
      <div style="gap: 1rem" :class="isMR ? 'sec p-d-flex-wrap' : 'sec p-d-flex'">
        <TasksTodoList
          :progressStatus="progress"
          @openDialog="openBasic"
          @openUnconditional="openUnconditionalDialog"
          @openCondtional="openConditionalDialog"
          @openBasic="openLoanModal"
          @loanAmountSettled="loanAmountSettled"
          @openDischargeRequest="openDischargeRequest"
          @getProgress="getProgress"
          :completed="isTodoCompleted"
          @id="getId"
          :key="toDoKey"
          @propId="getPropId"
          @refresh="refresh"
          @setSelectedTask="setSelectedTask"
          @documentId="(e) => (documentId = e)"
          @taskId="(e) => (taskId = e)"
        />
        <TasksCompletedList
          @amend="amendFiles"
          :key="completedKey"
          :loanStatus="loanStatus"
          :completed="taskCompleted"
          :progressStatus="progress"
        />
      </div>
      <FileUpload
        :display="displayFileuploadDialog"
        :openDialog="openBasic"
        :closeDialog="closeBasic"
        :status="selectedTask ? selectedTask.status : ''"
        :taskTitle="selectedTask ? selectedTask.taskTitle : ''"
        :crmTaskTitle="selectedTask && selectedTask.crmTask ? selectedTask.crmTask.title : ''"
        :externalDescription="selectedTask ? selectedTask.externalDescription : ''"
        :documents="selectedTask ? selectedTask.documents : []"
        :taskId="selectedTask.id"
        :key="uploadKey"
        @completed="closeFileupload"
        @skipped="taskSkipped"
      />
      <UnconditionalOfferDialog
        :display="displayUnConditionalDialog"
        :openDialog="openUnconditionalDialog"
        :closeDialog="closeUnconditionalDialog"
        @completed="unconditionalOfferCompleted"
        :selectedTaskId="selectedTaskId"
        :key="unconditionalOfferKey"
      />
      <ConditionalOfferDialog
        :display="displayConditionalDialog"
        :openDialog="openConditionalDialog"
        :closeDialog="closeConditionalDialog"
        @closeCondition="closeCondition"
        :key="conditionalOfferKey"
      />
      <LoanDetailsModal
        :displayBasic="displayLoanDetailsModal"
        :closeBasic="closeLoanModal"
        :propId="propId"
        @completed="completeProperty"
        :key="loanKey"
        :documentId="documentId"
      />
      <DischargeRequest
        :display="displayDischargeReq"
        :closeDialog="closeDischargeReq"
        @change="taskCompleted"
        :key="dischargeKey"
        @completed="completedDischarge"
        :documentId="documentId"
        :taskId="taskId"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ConditionalOfferDialog from './ConditionalOfferDialog.vue';
import DischargeRequest from './LoanSettlement/DischargeRequest.vue';
import FileUpload from './FileUpload.vue';
import LoanDetailsModal from '../../LoanDetailsModal.vue';
import LoanSettlementProgress from './LoanSettlement/index.vue';
import Screensize from '@/mixins/screensize.mixin';
import TasksCompletedList from './TasksCompletedList.vue';
import TasksTodoList from './TasksTodoList.vue';
import Timeline from './Timeline.vue';
import UnconditionalOfferDialog from './UnconditionalOfferDialog.vue';

export default {
  mixins: [Screensize],
  components: {
    Timeline,
    TasksTodoList,
    TasksCompletedList,
    FileUpload,
    UnconditionalOfferDialog,
    ConditionalOfferDialog,
    LoanDetailsModal,
    LoanSettlementProgress,
    DischargeRequest,
  },
  computed: {
    ...mapGetters({
      selectedLoanId: 'loans/selectedLoanId',
      selectedTaskId: 'loans/selectedTaskId',
      selectedLoanProgress: 'loans/selectedLoanProgress',
      selectedLoanDetails: 'loans/selectedLoanDetails',
    }),
  },
  data() {
    return {
      task: {},
      selectedTask: {
        taskTitle: '',
        externalDescription: '',
        supportingDocument: {
          documentURLs: [],
        },
      },
      taskId: '',
      conditionalOfferKey: 100,
      displayFileuploadDialog: false,
      displayConditionalDialog: false,
      displayUnConditionalDialog: false,
      displayLoanDetailsModal: false,
      displayDischargeReq: false,
      isTodoCompleted: false,
      taskCompleted: true,
      fileUploadList: [],
      progress: '',
      loanSettlement: false,
      status: '',
      timelineKey: 0,
      uploadKey: 10,
      toDoKey: 30,
      loanStatus: '',
      completedKey: 20,
      unconditionalOfferKey: 40,
      dischargeKey: 50,
      isLoading: true,
      propId: '',
      loanKey: 60,
      documentId: '',
      isMR: false,
    };
  },
  methods: {
    ...mapActions({
      fetchLoanProgress: 'loans/fetchLoanProgress',
      fetchLoanSummary: 'loans/fetchLoanSummary',
      setLoanStatus: 'loans/setLoanStatus',
    }),
    async refresh() {
      // this.toDoKey += 1;
      // this.completedKey += 1;
      await this.fetchLoanProgress().then(() => {
        const status = this.selectedLoanProgress?.loanStatus;
        // this.setLoanStatus(status);
        this.loanStatus = status;
        this.completedKey += 1;
        this.timelineKey += 1;
        this.toDoKey += 1;
      });
    },
    setSelectedTask(selectedTask) {
      this.selectedTask = selectedTask || {};
    },
    async completeProperty() {
      this.displayLoanDetailsModal = false;
      // this.toDoKey += 1;
      // this.completedKey += 1;
      await this.fetchLoanProgress().then(() => {
        const status = this.selectedLoanProgress?.loanStatus;
        // this.setLoanStatus(status);
        this.loanStatus = status;
        this.completedKey += 1;
        this.timelineKey += 1;
        this.toDoKey += 1;
      });
    },
    openBasic(selectedTask) {
      this.uploadKey += 1;
      this.displayFileuploadDialog = true;
      this.selectedTask = selectedTask;
    },
    closeBasic() {
      this.displayFileuploadDialog = false;
    },
    skipFileUpload() {
      this.displayFileuploadDialog = false;
    },
    async closeFileupload() {
      this.displayFileuploadDialog = false;
      // await this.fetchLoan();
      // this.toDoKey += 1;
      // this.completedKey += 1;
      await this.fetchLoanProgress().then(() => {
        const status = this.selectedLoanProgress?.status;
        // this.setLoanStatus(status);
        this.loanStatus = status;
        this.completedKey += 1;
        this.timelineKey += 1;
        this.toDoKey += 1;
      });
    },
    openConditionalDialog() {
      this.uploadKey += 1;
      this.conditionalOfferKey += 1;
      this.displayConditionalDialog = true;
    },
    closeConditionalDialog() {
      this.displayConditionalDialog = false;
    },
    openUnconditionalDialog() {
      this.displayUnConditionalDialog = true;
    },
    async amendFiles(selectedTask) {
      this.uploadKey += 1;
      this.selectedTask = selectedTask;
      this.displayFileuploadDialog = true;
      // this.toDoKey += 1;
      // this.completedKey += 1;
      // await this.fetchLoanProgress().then(() => {
      /* const status = this.selectedLoanProgress?.status;
				// this.setLoanStatus(status);
				this.loanStatus = status; */
      // this.completedKey += 1;
      // this.timelineKey += 1;
      // this.toDoKey += 1;
      // });
    },
    async unconditionalOfferCompleted() {
      this.displayUnConditionalDialog = false;
      await this.fetchLoanProgress().then(() => {
        //const status = this.selectedLoanProgress?.status;
        // this.setLoanStatus(status);
        //this.loanStatus = status;
        this.completedKey += 1;
        this.timelineKey += 1;
        this.toDoKey += 1;
      });
    },
    closeUnconditionalDialog() {
      this.progress = 'settlement';
      this.displayUnConditionalDialog = false;
    },
    openLoanModal() {
      this.loanKey += 1;
      this.displayLoanDetailsModal = true;
    },
    closeLoanModal() {
      this.displayLoanDetailsModal = false;
    },
    openDischargeReq() {
      this.dischargeKey += 1;
      this.displayDischargeReq = true;
    },
    async completedDischarge() {
      this.displayDischargeReq = false;
      // this.toDoKey += 1;
      // this.completedKey += 1;
      await this.fetchLoanProgress().then(() => {
        const status = this.selectedLoanProgress?.status;
        // this.setLoanStatus(status);
        this.loanStatus = status;
        this.completedKey += 1;
        this.timelineKey += 1;
        this.toDoKey += 1;
      });
    },
    closeDischargeReq() {
      // this.taskCompleted = false;
      // this.isTodoCompleted = true;
      this.displayDischargeReq = false;
    },
    getProgress(props) {
      this.$emit('setCurrentProgress', props);
      return (this.progress = props);
    },
    loanAmountSettled() {
      // this.progress = "loanFunded";
      // return (this.loanSettlement = true);
    },
    async closeCondition() {
      // this.closeConditionalDialog();
      this.$toast.add({
        severity: 'success',
        summary: 'Success',
        detail: 'Payment Completed Successfully',
        life: 3000,
      });
      // await _put(
      //   TECHLEND_BE_URL + "update_application_task_status?id=" + this.selectedTaskId,
      //   {
      //     application_id: this.selectedLoanId,
      //     status: "review",
      //   }
      // );
      this.displayConditionalDialog = false;
      this.isLoading = true;
      this.setLoanStatus('supporting_documents');
      await setTimeout(async () => {
        await this.fetchLoanProgress().then(async () => {
          const status = this.selectedLoanProgress?.status;
          this.loanStatus = status;
          this.completedKey += 1;
          await this.fetchLoanProgress().then(async () => {
            this.timelineKey += 1;
            this.toDoKey += 1;
            this.completedKey += 1;
          });
        });
        this.isLoading = false;
        this.completedKey += 1;
        this.timelineKey += 1;
        this.toDoKey += 1;
      }, 5000);
    },
    async getId(id) {
      try {
        this.selectedTaskId = id;
        // if (this.loanStatus === "loan_settlement") {
        //   await _put(
        //     TECHLEND_BE_URL + "update_application_task_status?id=" + this.selectedTaskId,
        //     {
        //       application_id: this.selectedLoanId,
        //       status: "review",
        //     }
        //   );
        //   this.toDoKey += 1;
        //   this.completedKey += 1;
        //   return;
        // }
        // if (this.loanStatus === "loan_settlement") {
        //   return this.openDischargeReq();
        // }
        this.uploadKey += 1;
        this.unconditionalOfferKey += 1;
      } catch (err) {
        if (err.response && err.response.data && err.response.data.message) {
          await this.$toast.add({
            severity: 'error',
            summary: 'Error',
            detail: err.response.data.message,
            life: 3000,
          });
        } else {
          await this.$toast.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Something went wrong',
            life: 3000,
          });
        }
      }
    },
    getPropId(id) {
      this.propId = id;
    },
    async fetchLoan() {
      await this.fetchLoanSummary();
      const status = this.selectedLoanDetails?.applicationStatus;
      await this.setLoanStatus(status);
      this.loanStatus = status;
      this.completedKey += 1;
      switch (status) {
        case 'created':
          this.progress = 'application';
          break;
        case 'conditional_offer':
          this.progress = 'application';
          break;
        case 'supporting_document':
          this.progress = 'conditional';
          break;
        case 'supporting_documents':
          this.progress = 'supporting';
          break;
        case 'unconditional_offer':
          this.progress = 'unconditional';
          break;
        case 'loan_settlement':
          this.progress = 'settlement';
          break;
        case 'loan_funded':
          this.progress = 'loanFunded';
          this.loanSettlement = true;
          break;
        case 'loan_repaid':
          this.progress = 'loanFunded';
          this.loanSettlement = true;
          break;
      }
      this.timelineKey += 1;
    },
  },
  async created() {
    this.isLoading = true;
    await this.fetchLoan();
    console.log(this.selectedLoanDetails);
    this.isLoading = false;
  },
};
</script>

<style lang="scss" scoped></style>
