<template>
  <div>
    <div class="mt-4" v-if="isDashboard">
      <Spinner v-if="isLoading" class="flex" />
      <div v-else :class="isMR ? '' : 'p-grid'">
        <div :class="isMR ? '' : 'col-9'">
          <LoanStatus />
          <SpecialConditions v-if="isStatusFunded" />
          <Task v-if="!isStatusFunded" />
          <Contact :class="isMR ? 'mt-3' : 'mt-4'" />
        </div>
        <div :class="isMR ? 'mt-3' : 'col-3'">
          <Blog />
          <Refer @refer="goToClient" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Blog from './Blog.vue';
import Contact from './Contact.vue';
import LoanStatus from './LoanStatus.vue';
import Screensize from '@/mixins/screensize.mixin';
import SpecialConditions from './SpecialConditions.vue';
import Task from './ToDoTask.vue';

export default {
  name: 'index',
  mixins: [Screensize],
  components: {
    LoanStatus,
    Blog,
    Task,
    Contact,
    SpecialConditions,
  },
  data() {
    return {
      isDashboard: true,
      isLoading: true,
    };
  },
  methods: {
    ...mapActions({
      getDashboardLoan: 'loans/getDashboardLoan',
      getLoanStatus: 'loans/getLoanStatus',
      fetchLoanSummary: 'loans/fetchLoanSummary',
    }),
    goToClient() {
      this.isDashboard = false;
    },
  },
  async created() {
    this.isLoading = true;
    await this.getDashboardLoan();
    await this.fetchLoanSummary();
    if (this.$route.query && this.$route.query.type) {
      this.isDashboard = this.$route.query.type === 'broker';
    }
    this.isLoading = false;
  },
  computed: {
    ...mapGetters({
      isDashboardPage: 'loans/isDashboardPage',
      dashboardLoan: 'loans/dashboardLoan',
    }),
    isStatusFunded() {
      return ['loan_funded', 'loan_repaid'].includes(this.dashboardLoan?.applicationStatus);
    },
  },
  watch: {
    isDashboardPage() {
      this.isDashboard = true;
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/styles/global.scss';

.title {
  font-family: Gordita-bold;
  // color: $secondary-color;
}
</style>
