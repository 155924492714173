export const changeStatusName = (status) => {
  const statusObj = {
    created: 'Created',
    conditional_offer: 'Conditional offer',
    unconditional_offer: 'Unconditional offer',
    supporting_documents: 'Supporting document',
    supporting_document: 'Supporting document',
    loan_settlement: 'Loan settlement',
    loan_funded: 'Loan funded',
    cancelled: 'Cancelled',
    loan_repaid: 'Loan repaid',
  };
  return statusObj[status] ? statusObj[status] : status;
};
