import { API, TECHLEND_BE_URL } from '@/constants.api';
import { _patch, _post } from '@/services/crudService';
import { generateSecondaryApplicantPayload } from '@/services/generateSecondaryApplicantPayload';

export default {
  namespaced: true,

  state: {
    declarationFormData: [],
    agreementFormData: [],
    discussionFormData: {
      need_discussion: null,
      discussion_msg: '',
    },
    declarationData: {},
    existing_app_confirmation: false,
    incomeTaxCalcResult: {},
  },
  getters: {
    declarationFormData: ({ declarationFormData }) => declarationFormData,
    agreementFormData: ({ agreementFormData }) => agreementFormData,
    discussionFormData: ({ discussionFormData }) => discussionFormData,
    declarationData: ({ declarationData }) => declarationData,
  },
  mutations: {
    setDiscussionFormData(storeState, { fieldName, value }) {
      storeState.discussionFormData[fieldName] = value;
    },
    setDeclarationFormData(storeState, payload) {
      storeState.declarationFormData = payload;
    },
    setAgreementFormData(storeState, payload) {
      storeState.agreementFormData = payload;
    },
    setFinalData(storeState) {
      const finalObj = {
        user_declaration: [...storeState.declarationFormData, ...storeState.agreementFormData],
        need_discusssion: storeState.discussionFormData.need_discussion,
        discussion_msg: storeState.discussionFormData.discussion_msg,
      };
      storeState.declarationData = finalObj;
    },
    setExistingAppConfirmation(storeState, payload) {
      storeState.existing_app_confirmation = payload;
    },
    setIncomeTax(storeState, payload) {
      storeState.incomeTaxCalcResult = payload;
    },
  },
  actions: {
    setDeclarationFormData({ commit }, payload) {
      commit('setDeclarationFormData', payload);
      commit('setFinalData');
    },
    setAgreementFormData({ commit }, payload) {
      commit('setAgreementFormData', payload);
      commit('setFinalData');
    },
    setDiscussionFormData({ commit }, payload) {
      commit('setDiscussionFormData', payload);
      commit('setFinalData');
    },
    setExistingAppConfirmation({ commit }, payload) {
      commit('setExistingAppConfirmation', payload);
    },
    async runDecisionSeeker(storeState, payload) {
      try {
        const res = await _post(TECHLEND_BE_URL + `run-decision-seeker-engine?application_id=${payload}`);
        const { data } = res;
        return data?.decision_seeker_result;
      } catch (err) {
        return 'fail';
      }
    },
    async submitApplication({ rootState }) {
      const { secondapplicationform } = rootState;
      let isFromCRM = secondapplicationform.isFromCRM;
      /* let reqObj = {
				...secondapplicationform.income.incomeData,
				...secondapplicationform.expense.expenseData,
				...secondapplicationform.assetsLiability.assetLiabilityData,
				...secondapplicationform.verify.verifyData,
				...secondapplicationform.declaration.declarationData,
				created_user_type: "customer",
				applicant_2_sign:
					secondapplicationform.applicantType !== "primary"
						? payload.signature
						: "",
				applicant_1_sign:
					secondapplicationform.applicantType === "primary"
						? payload.signature
						: "",
				isRequest: isFromCRM ? true : false,
			}; */

      // generating payload for patch request
      let applicationPayload = generateSecondaryApplicantPayload(secondapplicationform);
      if (isFromCRM) applicationPayload = { ...applicationPayload, isFromCRM: true };

      if (secondapplicationform.applicantType == 'primary') {
        return await _post(
          TECHLEND_BE_URL + `primary-applicant-submit?application_id=${secondapplicationform.applicationId}`,
          applicationPayload,
        );
      } else {
        return await _patch(
          TECHLEND_BE_URL + `${API.AUTH.APPLICATION_SUBMIT}/${secondapplicationform.applicationId}`,
          applicationPayload,
        );
      }
    },
  },
};
