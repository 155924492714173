import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';

//Authentication
import auth from './auth';
//Settings
import settings from './settings';
// Loan
import loans from './loan';

//Application Form
import applicationform from './applicationform';
//Second Application Form
import secondapplicationform from './secondapplicationform';

export default createStore({
  strict: false,
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    applicationform,
    secondapplicationform,
    auth,
    settings,
    loans,
  },
  plugins: [
    createPersistedState({
      storage: window.localStorage,
      getState: (key, storage) => {
        const value = storage.getItem(key);
        try {
          const data = typeof value === 'string' ? JSON.parse(value) : typeof value === 'object' ? value : undefined;

          // handle change to application steps
          const subProgress = data?.applicationform?.applicationSubProgress;
          if (subProgress && subProgress[7].length > 1) {
            subProgress[7] = [
              {
                id: 1,
                title: 'Mobile number',
                isActive: subProgress[7].some((x) => x.isActive),
                isCompleted: false,
              },
            ];
          }
          if (subProgress && subProgress[8].length > 1) {
            subProgress[8] = [
              {
                id: 1,
                title: 'Confirmation',
                isActive: subProgress[8].some((x) => x.isActive),
                isCompleted: false,
              },
            ];
          }
          const secondSubProgress = data?.secondapplicationform?.applicationSubProgress;
          if (secondSubProgress && secondSubProgress[5].length > 1) {
            secondSubProgress[5] = [
              {
                id: 1,
                title: 'Confirmation',
                isActive: secondSubProgress[5].some((x) => x.isActive),
                isCompleted: false,
              },
            ];
          }

          return data;
        } catch (err) {
          return undefined;
        }
      },
    }),
  ],
});
