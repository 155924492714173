// import Vue from "vue";
import { API, TECHLEND_BE_URL } from '@/constants.api';
import { TOKEN } from '@/constants';
import { useCookies } from 'vue3-cookies';
import axios from 'axios';
const { cookies } = useCookies();

/**
 * Axios basic configuration
 * Some general configuration can be added like timeout, headers, params etc. More details can be found on https://github.com/axios/axios
 * */
const config = {
  baseURL: `${TECHLEND_BE_URL}`,
};
/**
 * Creating the instance of Axios
 * It is because, in large scale application we may need to consume APIs from more than single server,
 * So, may need to create multiple http client with different config
 * Only this client will be used rather than axios in the application
 **/
const httpClient = axios.create(config);

/**
 * Auth interceptors
 * @description Configuration related to AUTH token can be done in interceptors.
 * Currenlty it is just doing nothing but idea to to show the capability of axios and its interceptors
 * In future, interceptors can be created into separate files and consumed into multiple http clients
 * @param {*} config
 */
const authInterceptor = (config) => {
  /** add auth token */
  const token = cookies.get(TOKEN);
  config['headers'] = {
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + token,
  };
  return config;
};

const loggerInterceptor = (config) => {
  /** Add logging here */
  return config;
};

/** Adding the request interceptors */
httpClient.interceptors.request.use(authInterceptor);
httpClient.interceptors.request.use(loggerInterceptor);

/** Adding the response interceptors */
httpClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    /** Do something with response error */
    if (error.config && error.config.url != API.AUTHENTICATE.VERIFY_OTP && error.response.status === 401) {
      // store.dispatch('logout');
      // window.location.reload();
    }
    throw error;
  },
);

export { httpClient };
