<template>
  <div class="discharge-authority-container">
    <Jotform src="https://form.jotform.com/242540901624854" :initial-height="1100" />
  </div>
</template>

<script>
import Jotform from '@/components/common/JotForm.vue';

export default {
  components: {
    Jotform,
  },
};
</script>

<style lang="scss" scoped>
.discharge-authority-container {
  width: 100%;
}
</style>
