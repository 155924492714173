<template>
  <div class="modal-container">
    <Dialog :visible="display" :modal="true" class="unconditional-modal">
      <template #header>
        <h1 class="title pm-txt-color">Accept Unconditional Offer</h1>
      </template>
      <div class="modal-content">
        <p class="desc lt-txt-color">
          All we need now is to confirm your settlement date and the details of your solicitor/conveyancer.<br />
          Your solicitor/conveyancer will act as a witness for any electronic signing required and will assist you
          through loan settlement.
        </p>

        <div class="icon-wrapper p-d-flex p-ai-center">
          <img :src="require('@/assets/icons/loan_summ_icon.svg')" alt="" />
          <p class="heading pm-txt-color">Loan Summary</p>
        </div>
        <div class="section-1">
          <div class="grid-items">
            <div class="wrap">
              <p class="heading lt-txt-color">Loan</p>
              <p class="text pm-txt-color" v-if="selectedLoanDetails">
                {{ formatPurpose(selectedLoanDetails.loanReferenceNumber) }}
              </p>
            </div>
            <div v-if="selectedLoanDetails.calculatedValue?.interestFreeTermMonths > 0" class="wrap">
              <p class="heading lt-txt-color">Interest free period</p>
              <p class="text pm-txt-color" v-if="selectedLoanDetails">
                {{ selectedLoanDetails.calculatedValue?.interestFreeTermMonths }}
                months
              </p>
            </div>
            <div v-else class="wrap"></div>
            <div class="wrap">
              <p class="heading lt-txt-color">Term</p>
              <p class="text pm-txt-color" v-if="selectedLoanDetails?.calculatedValue?.loanTermMonths">
                {{ selectedLoanDetails?.calculatedValue?.loanTermMonths }} months
              </p>
            </div>
            <div v-if="selectedLoanDetails.calculatedValue?.interestFreeTermMonths > 0" class="wrap">
              <p class="heading lt-txt-color">Interest rate</p>
              <p class="text pm-txt-color" v-if="selectedLoanDetails.calculatedValue">
                {{ (interestRate * 100).toFixed(2) }}% after interest free period
              </p>
            </div>
            <div v-else class="wrap">
              <p class="heading lt-txt-color">Interest rate</p>
              <p class="text pm-txt-color" v-if="selectedLoanDetails.calculatedValue">
                {{ (interestRate * 100).toFixed(2) }}%
              </p>
            </div>
            <div class="wrap">
              <p class="heading lt-txt-color">Valuation Fee</p>
              <p class="text pm-txt-color">
                {{ formatCurrency(valuationCost) }}
              </p>
            </div>
            <div class="wrap">
              <p class="heading lt-txt-color">Total set-up fee (%)</p>
              <p class="text pm-txt-color">{{ (totalSetupPercentage * 100).toFixed(2) }}%</p>
            </div>
            <div class="wrap">
              <p class="heading lt-txt-color">Loan Deposit</p>
              <p class="text pm-txt-color">
                {{ formatCurrency(loanDeposit) }}
              </p>
            </div>
            <div class="wrap">
              <p class="heading lt-txt-color">Loan Amount</p>
              <p class="text primary-color">{{ formatCurrency(loanAmount) }}</p>
            </div>
          </div>
        </div>
        <div class="section-2">
          <p class="sec-heading pm-txt-color font-size-1">Solicitor/conveyancer details</p>
          <div class="inputs">
            <TextField
              width="100%"
              placeholder="First name"
              :value="formData.firstName"
              label="First Name"
              :key="inputKey + 8"
              :errorMessages="showErrors('firstName')"
              @onInput="getFormData($event, 'firstName')"
            />
            <TextField
              width="100%"
              placeholder="Last name"
              label="Last Name"
              :value="formData.lastName"
              :key="getKey(7)"
              :errorMessages="showErrors('lastName')"
              @onInput="getFormData($event, 'lastName')"
            />
            <TextField
              width="100%"
              placeholder="Email address"
              label="Email"
              :key="inputKey + 6"
              :value="formData.email"
              :errorMessages="showErrors('email')"
              @onInput="getFormData($event, 'email')"
            />
          </div>

          <div class="mt-3 inputs">
            <TextField
              width="100%"
              placeholder="Phone number"
              label="Phone"
              type="number"
              :value="formData.mobilePhoneNumber"
              :key="inputKey + 5"
              :errorMessages="showErrors('mobilePhoneNumber')"
              @onInput="getFormData($event, 'mobilePhoneNumber')"
            />
            <TextField
              width="100%"
              placeholder="Company"
              label="Company"
              :key="inputKey + 3"
              :value="formData.company"
              :errorMessages="showErrors('company')"
              @onInput="getFormData($event, 'company')"
            />
            <TextField
              width="100%"
              placeholder="Address"
              label="Address"
              :value="formData.address"
              :key="inputKey + 2"
              :errorMessages="showErrors('address')"
              @onInput="getFormData($event, 'address')"
            />
          </div>
        </div>
        <div class="section-3">
          <label class="hk-grotest-medium mb-1">Settlement Date</label>
          <div class="inputs">
            <DatePicker
              :minDate="currentDate()"
              yearRange="2022:2050"
              :value="formData.settlementDate"
              :key="datePickerKey"
              @change="getFormData($event, 'settlementDate')"
            />
          </div>
        </div>
      </div>
      <template #footer>
        <div class="modal-footer">
          <p class="close" @click="closeDialog()">Back</p>
          <Button :disabled="isFormInvalid || isLoading" label="Submit" type="primary" @click="save()" />
        </div>
        <div v-if="isFormInvalid" class="mr-4 error-text">
          The form has missing or invalid fields<br />Please check before submitting
        </div>
      </template>
    </Dialog>
  </div>
</template>
<script>
import { API, TECHLEND_BE_URL } from '@/constants.api';
import { _patch } from '@/services/crudService';
import { currentDate } from '@/services/util';
import { email, helpers, integer, required } from '@vuelidate/validators';
import { formatCurrency } from '@/services/format';
import { mapActions, mapGetters } from 'vuex';
import { useVuelidate } from '@vuelidate/core';
import Dialog from 'primevue/dialog';
import Screensize from '@/mixins/screensize.mixin';

export default {
  mixins: [Screensize],
  props: ['display', 'openDialog', 'closeDialog'],
  setup() {
    return {
      currentDate,
      formatCurrency,
      v$: useVuelidate(),
    };
  },
  components: { Dialog },
  validations() {
    return {
      formData: {
        firstName: { required: helpers.withMessage('First name is required', required) },
        lastName: { required: helpers.withMessage('Last name is required', required) },
        email: { email, required: helpers.withMessage('Email is required', required) },
        company: { required: helpers.withMessage('Company name is required', required) },
        mobilePhoneNumber: {
          required: helpers.withMessage('Phone number is required', required),
          integer,
        },
        address: { required: helpers.withMessage('Address is required', required) },
        settlementDate: { required: helpers.withMessage('Settlement date is required', required) },
      },
    };
  },
  data() {
    return {
      formData: {
        firstName: '',
        lastName: '',
        email: '',
        mobilePhoneNumber: '',
        address: '',
        applicationId: '',
        settlementDate: currentDate().toISOString(),
        company: '',
      },
      solicitor: '',
      isLoading: false,
      inputKey: 0,
      datePickerKey: 10,
    };
  },
  computed: {
    ...mapGetters({
      selectedLoanDetails: 'loans/selectedLoanDetails',
      selectedLoanId: 'loans/selectedLoanId',
      selectedTaskId: 'loans/selectedTaskId',
    }),
    valuationCost() {
      return this.selectedLoanDetails.calculatedValue?.valuationFee;
    },
    totalSetupPercentage() {
      return (
        (this.selectedLoanDetails.calculatedValue?.setupFeeRate ?? 0) +
        (this.selectedLoanDetails.calculatedValue?.brokerFee ?? 0)
      );
    },
    interestRate() {
      const introRate = this.selectedLoanDetails.assessmentRates.find(
        (rate) => rate.assessmentRateType === 'introductory_variable_interest_rate',
      );
      if (introRate) {
        return introRate?.assessmentRate;
      } else {
        const bridgingRate = this.selectedLoanDetails.assessmentRates.find(
          (rate) => rate.assessmentRateType === 'bridging_variable_interest_rate',
        );
        return bridgingRate?.assessmentRate;
      }
    },
    loanDeposit() {
      return this.selectedLoanDetails.calculatedValue?.depositAmount;
    },
    loanAmount() {
      return this.selectedLoanDetails.calculatedValue?.loanAmount;
    },
    isFormInvalid() {
      return this.v$.$errors.length;
    },
  },
  methods: {
    ...mapActions({ getSolictorDetailsById: 'loans/getSolictorDetailsById' }),
    getKey(num) {
      return this.inputKey + num;
    },
    formatPurpose(purpose) {
      const obj = {
        buy_now: 'Buy now',
        renovate: 'Renovate',
        cash_advance: 'Equity release',
        equity_release: 'Equity release',
      };
      return obj[purpose] ? obj[purpose] : purpose;
    },
    getFormData(value, fieldName) {
      this.formData[fieldName] = value;
      this.formData = { ...this.formData };
    },
    async save() {
      const isFormValid = await this.v$.$validate();
      if (!isFormValid) return;

      this.isLoading = true;
      try {
        await _patch(TECHLEND_BE_URL + API.TASKS.ACCEPT_UNCONDITIONAL_OFFER + `?taskId=${this.selectedTaskId}`, {
          ...this.formData,
          status: 'in_progress',
        });
        this.$emit('completed');
      } catch (err) {
        if (err.response && err.response.data && err.response.data.message) {
          await this.$toast.add({
            severity: 'error',
            summary: 'Error',
            detail: err.response.data.message,
            life: 5000,
          });
        } else {
          await this.$toast.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Something went wrong',
            life: 5000,
          });
        }
      } finally {
        this.isLoading = false;
      }
    },
    getSettlementDate(loan) {
      return loan?.settlementDate ?? loan?.incomingProperties?.[0]?.settlementDate ?? currentDate().toISOString();
    },
    showErrors(fieldName) {
      return this.v$.formData[fieldName]?.$errors.map((e) => e.$message).join('. ');
    },
  },
  async created() {
    const data = await this.getSolictorDetailsById();
    if (data.length) {
      this.solicitor = data[0];
      Object.assign(this.formData, {
        firstName: this.solicitor.firstName || '',
        lastName: this.solicitor.lastName || '',
        email: this.solicitor.email || '',
        mobilePhoneNumber: this.solicitor.mobilePhoneNumber || '',
        company: this.solicitor.company || '',
        address: this.solicitor.address || '',
        settlementDate: this.getSettlementDate(this.selectedLoanDetails),
      });
      this.inputKey = new Date().getTime();
    } else {
      Object.assign(this.formData, {
        firstName: '',
        lastName: '',
        email: '',
        mobilePhoneNumber: '',
        address: '',
        company: '',
        settlementDate: this.getSettlementDate(this.selectedLoanDetails),
      });
    }
    this.datePickerKey += 1;
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';

.title {
  font-weight: bold;
  color: $primary-color;
  font-size: 2.25rem;
  @media (max-width: 992px) {
    font-size: 25px;
  }
}

.desc {
  font-size: 0.875rem;
  margin-bottom: 1.5rem;
  line-height: 2;
}

.text {
  margin-bottom: 0.3rem;
}

.field-not {
  width: 100%;
}

.second-row {
  margin-top: 1rem;
}

.heading,
.text {
  font-size: 0.875rem !important;
}

.inputs {
  gap: 1rem;
  align-items: start;

  :deep(.p-float-label) {
    margin-bottom: 0.25rem;
  }
}

.modal-content {
  border: none;

  .section-1 {
    margin-top: 2rem;
    width: 90%;

    .details-content {
      gap: 1.375rem;
    }

    .grid-items {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 0.5rem;
      @media (max-width: 992px) {
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }

  .section-2 {
    margin-top: 1.875rem;

    .label {
      margin-bottom: 1rem;
    }

    .inputs {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      @media (max-width: 992px) {
        grid-template-columns: repeat(1, 1fr);
      }
    }

    .second-row {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  .section-3 {
    margin-top: 1.875rem;
    width: 100%;

    .mail-total {
      width: 100%;
      margin-top: 1rem;
      gap: 1rem;
    }
  }

  .icon-wrapper {
    gap: 1rem;

    .icon {
      font-size: 2rem;
    }
  }

  .heading {
    margin: 0;
    font-weight: bold;
    font-size: 1.25rem;
  }
}
.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 2rem;

  margin-top: 2rem;
  .close {
    margin: 0;
    cursor: pointer;
  }
}

.error-text {
  color: red;
  font-size: 14px;
}

:global(.unconditional-modal) {
  width: calc(100vw - 1rem);

  @media (min-width: 640px) {
    width: 80vw;
  }
}
</style>
