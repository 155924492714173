<template>
  <div id="sideNavigation" class="sidebar-container">
    <nav id="navigationContent">
      <div class="header">
        <div class="header-top">
          <div class="nav-items">
            <div v-for="item in menuItems" @click="onclickRoute" :key="item.id" class="wrap">
              <router-link :to="item.link" class="a-link">
                <div @click="closeSidebar" class="nav-item p-d-flex">
                  <div class="icon-wrapper p-d-flex p-ai-center pi-jc-center">
                    <SvgIcon class="icon" :name="item.icon" size="1.80rem" />
                    <SvgIcon class="icon-active" :name="item.icon" size="1.80rem" />
                  </div>
                  <p class="nav-text" :class="{ 'nav-text-dashboard': item.text == 'Dashboard' }">
                    {{ item.text }}
                  </p>
                </div>
              </router-link>
            </div>
          </div>
        </div>

        <div class="header-bottom">
          <div class="nav-items">
            <div v-if="showFaq" @click="goToFaq" class="a-link cursor-pointer">
              <div class="nav-item p-d-flex">
                <span class="icon-wrapper p-d-flex p-ai-center pi-jc-center">
                  <img class="icon" :src="require('@/assets/icons/faq_icon.svg')" />
                </span>
                <p class="nav-text">FAQ</p>
              </div>
            </div>
            <div @click="goToBlog" class="a-link cursor-pointer">
              <div class="nav-item p-d-flex">
                <span class="icon-wrapper p-d-flex p-ai-center pi-jc-center">
                  <img class="icon" :src="require(`@/assets/icons/blog_icon.svg`)" />
                </span>
                <p class="nav-text white-space-normal">{{ blogTitle }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="footer">
        <p v-if="originDisclaimer" class="disclaimer-message sc-txt-color">{{ originDisclaimer }}</p>
        <p class="copyright-text sc-txt-color">
          Copyright @ {{ getYear }} Bridgit <br />
          <span>All rights reserved</span>
        </p>
      </div>
    </nav>
  </div>
</template>

<script>
import SvgIcon from './SvgIcon.vue';

export default {
  components: {
    SvgIcon,
  },
  props: ['menuItems', 'title', 'toggleSideNavClicked'],
  data() {
    return {
      width: '',
      getYear: '',
    };
  },
  methods: {
    closeSidebar() {
      this.$emit('close');
    },
    goToBlog() {
      window.open(process.env.VUE_APP_CUSTOM_THEME_BLOG_LINK ?? 'https://www.bridgit.com.au/blog');
      this.$emit('change', false);
    },
    goToFaq() {
      window.open(process.env.VUE_APP_CUSTOM_THEME_FAQ_LINK ?? 'https://bridgit.com.au/faqs/');
      this.$emit('change', false);
    },
    onResize() {
      this.width = window.innerWidth;
    },
    onclickRoute() {
      this.$emit('change', false);
    },
  },
  mounted() {
    if (this.width < 992) {
      if (this.toggleSideNavClicked === true) {
        document.getElementById('sideNavigation').style.width = '270px';
        document.getElementById('navigationContent').style.display = 'flex';
      }
      // else if (this.toggleSideNavClicked === false) {
      // document.getElementById("sideNavigation").style.width = "0px";
      // document.getElementById("navigationContent").style.display = "none";
      // }
    } else {
      document.getElementById('sideNavigation').style.width = '270px';
      document.getElementById('navigationContent').style.display = 'flex';
    }
  },
  created() {
    window.addEventListener('resize', this.onResize);
    this.getYear = new Date().getFullYear();
  },
  destroyed() {
    window.removeEventListener('resize', this.onResize);
  },
  computed: {
    originDisclaimer() {
      if (!process.env.VUE_APP_CUSTOM_THEME_DISCLAIMER) return '';

      const [firstSentence] = process.env.VUE_APP_CUSTOM_THEME_DISCLAIMER.split('.');

      return firstSentence ? `${firstSentence}.` : '';
    },
    blogTitle() {
      return process.env.VUE_APP_CUSTOM_THEME_BLOG_TITLE ?? 'Blog';
    },
    showFaq() {
      return process.env.VUE_APP_CUSTOM_THEME_FAQ_LINK === undefined;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';

.sidebar-container {
  width: 270px;
  background-color: $secondary-color;
  @media (max-width: 992px) {
    width: 0px;
  }

  nav {
    width: 100%;
    padding: 2rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    @media (max-width: 992px) {
      display: none;
    }

    .header {
      width: 100%;
      .a-link {
        text-decoration: none;
      }

      .nav-item {
        gap: 1.2rem;
        padding-top: 0.9rem !important;
        padding-bottom: 0.9rem !important;
        margin-bottom: 0.3rem;
        align-items: flex-start;
      }

      .header-top {
        width: 100%;
        .nav-item {
          // padding: 0 3rem;
          width: 82%;
          margin-left: 3rem;
        }
      }

      .icon-wrapper {
        width: 25px;
        height: 25px;
        .icon {
          font-size: 1.125rem;
          color: $secondary-text-color;
        }

        .icon-active {
          display: none;
        }
      }

      .router-link-active .nav-item {
        border-right: 3.7px solid $sky-blue-color;
        background: linear-gradient(90deg, rgba(84, 183, 249, 0) 29.33%, rgba(84, 183, 249, 0.1) 74.81%);
      }

      .router-link-active .icon-active {
        color: $primary-alternative-color;
        display: block;
      }

      .router-link-active .icon {
        display: none;
      }

      .router-link-active .nav-text {
        color: $white-color !important;
      }

      .nav-text {
        font-size: 1.2rem;
        color: $secondary-text-color;
        margin: 0;
        white-space: nowrap;
      }
      .nav-text-dashboard {
        font-size: 1.2rem;
        color: $secondary-text-color;
        margin: 0;
        white-space: nowrap;
        margin-top: 4px;
      }

      // HEADER BOTTOM
      .header-bottom {
        border-top: 1px solid $secondary-text-color;
        margin: auto 3rem;
        margin-top: 1.3rem;

        .nav-items {
          &:first-child {
            margin-top: 1rem;
          }
        }
      }
    }
    .footer {
      position: absolute;
      bottom: 1rem;
      .disclaimer-message {
        margin-bottom: 0.5rem;
      }
      p {
        color: $secondary-text-color;
        text-align: center;
        line-height: 1.7;
        margin-left: 1rem;
        margin-right: 1rem;
        font-size: 0.7rem;
      }
      @media (max-width: 992px) {
        display: none;
      }
    }
  }
}
</style>
