<template>
  <Card>
    <template #title>
      <div class="title pm-txt-color gr-bold">Tasks</div>
    </template>

    <template #content>
      <div
        v-if="
          tasksList?.length &&
          this.dashboardLoan.applicationStatus !== 'cancelled' &&
          this.dashboardLoan.loanStatus !== 'dropped_off' &&
          this.dashboardLoan.loanStatus !== 'withdrawn' &&
          this.dashboardLoan.loanStatus !== 'rejected' &&
          this.dashboardLoan.loanStatus !== 'on_hold'
        "
      >
        <div v-for="(item, i) in tasksList" :key="i">
          <div class="flex align-items-center justify-content-between task-layout pb-3 pt-3">
            <div class="pm-txt-color gr-medium todo-list col-8">
              {{ fromSnakeCase(item.taskTitle) }}
            </div>
            <div
              :data-test-id="`open-${item.taskTitle.replace(/_/g, '-')}-button`"
              :style="{
                border: `1px solid ${
                  item.status === 'open' || item.status === 'not_yet_started'
                    ? 'var(--pink-color)'
                    : item.status == 'review'
                    ? 'var(--sky-blue-color)'
                    : 'var(--primary-color)'
                } !important`,
                color: `${
                  item.status === 'open'
                    ? 'var(--pink-color)'
                    : item.status == 'review'
                    ? 'var(--sky-blue-color)'
                    : 'var(--primary-color)'
                }`,
                background: '#ffffff',
              }"
              @click="navigateTo"
              class="flex align-items-center justify-content-center bgColor cursor-pointer"
              :class="
                item.status === 'open' || item.status === 'not_yet_started'
                  ? 'bg-open'
                  : item.status == 'review'
                  ? 'bg-review'
                  : 'bg-complete'
              "
            >
              <span v-if="item.status != 'review'" class="capital">{{
                item.status == 'not_yet_started' ? 'open' : item.status
              }}</span>
              <span v-else>Under review</span>
            </div>
            <!-- <Button
              width="150px"
              style="font-size: 14px"
              @click="navigateTo"
              v-if="item.status !== 'Completed'"
              :label="item.status"
              :color="item.status === 'Under review' ? '#54B7F9' : '#2CDD80'"
              :bgcolor="item.status === 'Under review' ? '#ffffff' : '#ffffff'"
            /> -->
            <!-- <div v-if="item.status === 'Completed'" class="completed-status">
              Completed
            </div> -->
          </div>
        </div>
      </div>
      <div v-else class="todo-completed p-d-flex flex justify-content-center align-items-center">
        <div>
          <div class="text-color-primary">
            <SvgIcon name="todo_completed" size="7rem" />
          </div>
          <p class="lt-txt-color font-size-1 mt-5">No tasks found in to do list</p>
        </div>
      </div>
    </template>
  </Card>
</template>

<script>
import { fromSnakeCase } from '@/services/format';
import { mapActions, mapGetters } from 'vuex';
import Card from 'primevue/card';
import SvgIcon from '../../../components/common/SvgIcon.vue';

export default {
  name: 'Task',
  components: {
    Card,
    SvgIcon,
  },
  data() {
    return {};
  },
  methods: {
    ...mapActions({
      setLoanId: 'loans/setLoanId',
      setLoanStatus: 'loans/setLoanStatus',
      setNewLoanId: 'loans/setNewLoanId',
      getTasksByApplicationId: 'loans/getTasksByApplicationId',
    }),
    fromSnakeCase,
    async navigateTo() {
      // if (status === "Action Required") {
      //   this.$router.push({
      //     path: "loan/001/application",
      //     query: { type: "3" },
      //   });
      //   return;
      // }
      await this.setLoanId(this.dashboardLoan.id);
      await this.setLoanStatus(this.dashboardLoan.applicationStatus);
      await this.setNewLoanId(this.dashboardLoan.loanReferenceNumber);
      // this.$router.push({
      //   path: `/loan/${this.dashboardLoan.status.loan_id}/application`,
      // });
      if (
        this.dashboardLoan.applicationStatus === 'cancelled' ||
        this.dashboardLoan.loanStatus === 'dropped_off' ||
        this.dashboardLoan.loanStatus === 'withdrawn' ||
        this.dashboardLoan.loanStatus === 'rejected' ||
        this.dashboardLoan.loanStatus === 'on_hold'
      ) {
        this.$router.push(`/loan/${this.dashboardLoan.loanReferenceNumber}/summary`);
      } else {
        this.$router.push(`/loan/${this.dashboardLoan.loanReferenceNumber}/application`);
        // await this.fetchLoanSummary();
      }
    },
    async getData() {
      const currentApplicationId = this.profileDetails.applicationId;
      await this.getTasksByApplicationId(currentApplicationId);
    },
  },
  computed: {
    ...mapGetters({
      dashboardLoan: 'loans/dashboardLoan',
      tasksList: 'loans/tasksList',
      profileDetails: 'settings/profileDetails',
    }),
  },
  async created() {
    if (
      this.dashboardLoan.applicationStatus !== 'cancelled' ||
      this.dashboardLoan.loanStatus !== 'dropped_off' ||
      this.dashboardLoan.loanStatus !== 'withdrawn' ||
      this.dashboardLoan.loanStatus !== 'rejected' ||
      this.dashboardLoan.loanStatus !== 'on_hold'
    ) {
      await this.getData();
    }
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';

.todo-list {
  font-size: 0.875rem;
}

:deep(.p-card-body) {
  padding: 2rem !important;
}

.p-card {
  box-shadow: unset !important;
  color: $primary-text-color !important;
  font-family: $font-family !important;
  border-top-right-radius: 0px !important;
  border-top-left-radius: 0px !important;
  height: 440px;
  overflow: auto;
  @media (max-width: 992px) {
    margin: 0 10px;
  }
}
:deep(.p-card-title) {
  font-size: 1.25rem !important;
}
:deep(.p-card-content) {
  font-size: 1rem !important;
}
.task-layout {
  border-bottom: 1px solid #02003826;
}
.completed-status {
  color: #51c78e;
}
.bgColor {
  width: 150px;
  height: 40px;
  border-radius: 4px;
}
.bg-green {
  background: #a4bfe0;
}
.bg-green:hover {
  background: #a4bfe0 !important;
  color: black !important;
}
.bg-orange:hover {
  background: #{lighten($pink-color, 10%)} !important;
  color: black !important;
}
.bg-open:hover {
  background: #{lighten($pink-color, 10%)} !important;
  color: black !important;
}
.bg-review:hover {
  background: #a4bfe0 !important;
  color: black !important;
}
.bg-complete:hover {
  background: $primary-color !important;
  color: black !important;
}
</style>
