<template>
  <svg xmlns="http://www.w3.org/2000/svg" class="hidden">
    <symbol id="home_icon" viewBox="0 0 32 25" fill="none" stroke="currentColor">
      <g clip-path="url(#clip0_3667_56309)">
        <path
          d="M25.7835 12.0254V24.2547H6.52234V12.0254"
          stroke="currentColor"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M0.815308 11.5151L15.6943 0.814453L31.1847 11.9227"
          stroke="currentColor"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M18.6497 15.5918H13.3503V24.2542H18.6497V15.5918Z"
          stroke="currentColor"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3667_56309">
          <rect width="32" height="24.9682" fill="white" />
        </clipPath>
      </defs>
    </symbol>
    <symbol id="home_with_window_icon" viewBox="0 0 32 25" fill="none" stroke="currentColor">
      <path
        d="M21.1423 7.63477L26.9923 12.9368"
        stroke="#020038"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M1 13.1221L8.01262 6.35388L9.70098 4.73216L13.6479 1L21.1418 7.63496"
        stroke="#020038"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M24.5037 14.3516V15.018L24.5407 23.9041L14.7068 23.9486L3.52507 24.0004L3.48064 14.4404"
        stroke="#020038"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.3937 13.6383L7.75816 13.6582L7.77857 18.4049L12.4142 18.3849L12.3937 13.6383Z"
        stroke="#54B7F9"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M20.2158 13.6026L15.9134 13.6211L15.9453 21.0262L20.2476 21.0077L20.2158 13.6026Z"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </symbol>
    <symbol id="myloan_icon" viewBox="0 0 25 25" fill="none" stroke="currentColor">
      <path
        d="M16.3 23.3C15.1 23.7 13.8 23.9 12.5 23.9C6.1 23.9 1 18.8 1 12.4C1 6 6.2 1 12.6 1C19 1 24.1 6.1 24.1 12.5C24.1 15.4 23 18 21.3 20"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.8001 15.4996C9.8001 17.1996 11.3001 18.1996 13.0001 18.1996C14.7001 18.1996 15.5001 16.6996 15.5001 15.0996C15.5001 13.4996 13.9001 12.3996 12.6001 12.3996H12.7001C11.0001 12.3996 9.6001 10.8996 9.6001 9.09961C9.6001 7.29961 11.1001 6.59961 12.7001 6.59961C14.3001 6.59961 15.4001 7.69961 15.4001 8.99961"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.7002 6.70039V4.90039"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.7002 19.9992V18.1992"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </symbol>
    <symbol id="statement_icon" viewBox="0 0 22 24" fill="none" stroke="currentColor">
      <path
        d="M3.0957 0.800781H18.2087"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18.4955 22.8008H20.8868V3.38339C20.8868 1.94861 19.739 0.800781 18.3042 0.800781"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M2.23459 7.49643H0.799805V3.28774C0.799805 1.94861 1.94763 0.800781 3.28676 0.800781C4.62589 0.800781 5.77372 1.85296 5.77372 3.28774V22.8008H13.8085"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.5562 8.64453H16.9648"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.5562 14.8613H16.9648"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </symbol>
    <symbol id="contact_icon" viewBox="0 0 23 22" fill="none" stroke="currentColor">
      <path
        d="M16.6812 16.8646H18.607C20.441 16.8646 22 15.3974 22 13.4716V4.39301C22 2.55895 20.5327 1 18.607 1H4.39301C2.55895 1 1 2.46725 1 4.39301V13.4716V20.2576L7.32751 16.8646H11.5459"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </symbol>
    <symbol id="settings_icon" viewBox="0 0 22 24" fill="none" stroke="currentColor">
      <path
        d="M8.06926 4.78355L5.77922 2.99134L2.99134 5.77922L4.78355 8.06926C4.38528 8.76623 4.08658 9.4632 3.88745 10.1602L1 10.5584V14.5411L3.88745 14.9394C4.08658 15.6364 4.38528 16.3333 4.78355 17.0303L2.99134 19.2208L5.77922 22.0087L8.06926 20.2165C8.66667 20.6147 9.36364 20.9134 10.1602 21.1126L10.5584 24H14.5411L14.9394 21.1126C15.6364 20.9134 16.3333 20.6147 17.0303 20.2165L19.3203 22.0087L22.1082 19.2208L20.2165 16.9307C20.6147 16.3333 20.9134 15.6364 21.1126 14.8398L24 14.4416V10.4589L21.1126 10.0606C20.9134 9.36364 20.6147 8.66667 20.2165 7.9697L22.0087 5.67965L19.2208 2.99134L16.9307 4.78355C16.3333 4.38528 15.6364 4.08658 14.8398 3.88745L14.4416 1H10.4589"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.5499 15.7356C14.3096 15.7356 15.7361 14.3091 15.7361 12.5494C15.7361 10.7898 14.3096 9.36328 12.5499 9.36328C10.7903 9.36328 9.36377 10.7898 9.36377 12.5494C9.36377 14.3091 10.7903 15.7356 12.5499 15.7356Z"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </symbol>
    <symbol id="todo_completed" viewBox="0 0 145 116" fill="none" stroke="currentColor">
      <path
        d="M36.1479 24.6948V8.37145C36.1479 5.60702 38.3858 3.36914 41.1503 3.36914H108.418C111.183 3.36914 113.421 5.60702 113.421 8.37145V94.9904C113.421 97.7549 111.183 99.9928 108.418 99.9928H41.1503C38.3858 99.9928 36.1479 97.7549 36.1479 94.9904V76.5609"
        stroke="#020038"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M119.081 18.377H124.083C126.848 18.377 129.086 20.6148 129.086 23.3793V109.998C129.086 112.763 126.848 115.001 124.083 115.001H56.8153C54.0509 115.001 51.813 112.763 51.813 109.998V107.365"
        stroke="#020038"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M36.806 69.1892C47.3479 69.1892 55.8938 60.6433 55.8938 50.1014C55.8938 39.5596 47.3479 31.0137 36.806 31.0137C26.2641 31.0137 17.7183 39.5596 17.7183 50.1014C17.7183 60.6433 26.2641 69.1892 36.806 69.1892Z"
        stroke="#54B7F9"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M46.8105 43.3887L33.3833 56.8159L26.8013 50.3656"
        stroke="currentColor"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M52.0762 16.6641H74.7182"
        stroke="currentColor"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M52.0762 27.7227H99.2032"
        stroke="#020038"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M61.8174 40.4922H99.2031"
        stroke="#020038"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M61.8174 53.1289H99.2031"
        stroke="#020038"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M61.8174 65.7676H99.2031"
        stroke="#020038"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M61.8174 78.4043H99.2031"
        stroke="#020038"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M126.189 1V5.34411"
        stroke="#020038"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M128.427 3.10547H124.083"
        stroke="#020038"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M3.10596 54.5781V58.9222"
        stroke="#020038"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.34411 56.6836H1"
        stroke="#020038"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M141.196 95.6484V100.124"
        stroke="#020038"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M143.434 97.8867H139.09"
        stroke="#020038"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </symbol>
    <symbol id="email_icon" viewBox="0 0 26 27" fill="none" stroke="currentColor">
      <path
        d="M24.9608 9.88281V26H1V9.88281"
        stroke="#020038"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.74854 20.7891L24.9606 9.88281"
        stroke="#020038"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M1 9.88281L12.9804 17.9453"
        stroke="#020038"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M2.36506 8.96875L1 9.88281"
        stroke="#020038"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M24.9605 9.88281L23.6035 8.96875"
        stroke="#020038"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.5127 9.58594V1H20.448V9.58594"
        stroke="#020038"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.45947 4.46875H12.6189"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.45947 7.49219H17.501"
        stroke="#54B7F9"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </symbol>
    <symbol id="calendar_icon" viewBox="0 0 28 26" fill="none" stroke="currentColor">
      <path
        d="M14.5896 22.0005H10.1866H3.06716C1.92537 22.0005 1 21.0751 1 19.9333V5.91091C1 4.76912 1.92537 3.84375 3.06716 3.84375H20.291C21.4328 3.84375 22.3582 4.76912 22.3582 5.91091V12.9258"
        stroke="#020038"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.47754 5.78358V1"
        stroke="#020038"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16.873 5.78358V1"
        stroke="#020038"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M22.358 24.88C19.858 24.88 17.8281 22.8502 17.8281 20.3502C17.8281 17.8502 19.858 15.8203 22.358 15.8203C24.858 15.8203 26.8878 17.8502 26.8878 20.3502C26.8878 22.8502 24.858 24.88 22.358 24.88Z"
        stroke="#54B7F9"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M20.6792 20.2838L21.8434 21.4405L24.0299 19.2539"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M1 9.40234H22.3582"
        stroke="#020038"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </symbol>
    <symbol id="phone_icon" viewBox="0 0 27 26" fill="none" stroke="currentColor">
      <path
        d="M7.46314 21.456C6.89597 20.956 6.32134 20.3888 5.73925 19.7619C3.42582 17.2694 2.09746 14.5754 1.33627 12.4709C0.567613 10.3142 1.14224 7.90373 2.79896 6.3291L4.25418 4.9485C4.43328 4.77686 4.6497 4.66492 4.88851 4.62015C5.36612 4.53806 5.84373 4.73209 6.12731 5.12015L7.90343 7.60522C8.58254 8.55298 8.4706 9.85895 7.63478 10.6724L6.44075 11.8366C5.81388 12.456 5.67955 13.4112 6.11239 14.1798C6.54522 14.9485 7.22433 15.9709 8.21687 17.0604C8.33627 17.1948 8.45567 17.3216 8.57508 17.441"
        stroke="#020038"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.58252 17.4473C9.35118 18.2383 10.0676 18.798 10.6422 19.1786C11.4034 19.6861 12.4034 19.604 13.0676 18.9697L13.6721 18.3876C14.5004 17.5965 15.7691 17.5144 16.6945 18.1861L19.2318 20.0294C19.7765 20.4249 20.0004 21.1264 19.7915 21.7682C19.687 22.0816 19.4855 22.3503 19.2168 22.5443L17.3736 23.8353C15.5751 25.0965 13.2094 25.2308 11.284 24.1786C10.7989 23.9174 10.284 23.604 9.73924 23.2383"
        stroke="#020038"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M22.2241 13.7603C21.9704 11.4244 20.948 9.15578 19.1644 7.3722C17.3734 5.58116 15.1122 4.56623 12.7764 4.3125"
        stroke="#54B7F9"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18.8663 14.1264C18.6872 12.5294 17.9931 10.9771 16.7693 9.75326C15.5454 8.52938 13.9931 7.83535 12.3887 7.65625"
        stroke="#020038"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M25.5228 13.3881C25.187 10.3284 23.8512 7.35075 21.5079 5.00746C19.1721 2.67164 16.1945 1.33582 13.1348 1"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </symbol>
    <symbol id="call_icon" viewBox="0 0 82 106" fill="none" stroke="currentColor">
      <path
        d="M68.2675 86.6341C70.2957 88.0989 71.0844 90.5778 70.2957 92.944C69.9577 94.0707 69.1689 95.0848 68.1548 95.7609L61.3943 100.493C54.8591 105.113 46.183 105.564 39.1971 101.733C37.3943 100.719 35.5915 99.5919 33.5633 98.3524L25.2253 91.9299C23.1971 90.1271 21.0563 88.0989 18.9154 85.7327C10.6901 76.6059 5.845 66.8031 3.02809 59.1411C0.211193 51.2538 2.35204 42.4651 8.32387 36.7186L13.6196 31.6482C14.2957 31.0848 15.0844 30.6341 15.9858 30.5214C17.676 30.1834 19.4788 30.9721 20.4929 32.3242L27.0281 41.3383C29.507 44.8313 29.0563 49.5637 26.014 52.4933L21.7323 56.775C19.4788 59.0285 18.9154 62.5214 20.4929 65.3383C22.0703 68.1552 24.5492 71.8735 28.1549 75.8172C28.6056 76.2679 29.0563 76.7186 29.507 77.1693"
        stroke="#020038"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M29.5071 77.2812C32.324 80.2108 34.9155 82.239 37.0564 83.5911C39.8733 85.3939 43.4789 85.1686 45.8451 82.8024L48.0986 80.6615C51.1409 77.732 55.7606 77.5066 59.1409 79.8728"
        stroke="#020038"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M58.3521 85.8458C58.3521 85.8458 69.6197 84.3811 73.5633 72.5501C77.507 60.7191 70.6337 53.5078 70.6337 53.5078"
        stroke="currentColor"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M78.8592 54.4089L70.2959 52.7188L68.6057 61.5075"
        stroke="currentColor"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M57.1126 25.1124C60.473 25.1124 63.1971 22.3882 63.1971 19.0279C63.1971 15.6675 60.473 12.9434 57.1126 12.9434C53.7522 12.9434 51.0281 15.6675 51.0281 19.0279C51.0281 22.3882 53.7522 25.1124 57.1126 25.1124Z"
        stroke="#54B7F9"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M50.4647 26.8027C48.6619 29.0563 48.2112 32.0985 48.2112 34.2394H57.2253H66.2394C66.2394 32.0985 65.7887 29.0563 63.9858 26.8027"
        stroke="#54B7F9"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M57.1126 47.7609C69.8074 47.7609 80.0985 37.4697 80.0985 24.775C80.0985 12.0802 69.8074 1.78906 57.1126 1.78906C44.4179 1.78906 34.1267 12.0802 34.1267 24.775C34.1267 37.4697 44.4179 47.7609 57.1126 47.7609Z"
        stroke="#020038"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </symbol>
    <symbol id="send_mail_icon" viewBox="0 0 115 87" fill="none" stroke="currentColor">
      <path
        d="M34.1077 5.8125H53.0096"
        stroke="currentColor"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M38.9214 29.2949H53.0098"
        stroke="#020038"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M21.3108 18.0234H53.0097"
        stroke="#54B7F9"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M86.2348 42.5597V67.097C86.2348 69.7973 84.0041 71.9106 81.4213 71.9106H5.81354C3.11326 71.9106 1 69.6799 1 67.097V46.434V15.9092V10.626C1 7.92576 3.23066 5.8125 5.81354 5.8125H24.5981"
        stroke="#020038"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M1 56.8848H86.2348"
        stroke="#020038"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M53.9489 86.0003H33.2859L36.5732 72.0293H50.5442L53.9489 86.0003Z"
        stroke="#020038"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M25.0676 86H62.0497"
        stroke="#020038"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M113.355 1H58.293V36.221H113.355V1Z"
        stroke="#54B7F9"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M58.293 1L85.7653 18.4931L113.355 1"
        stroke="#54B7F9"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </symbol>
    <symbol id="app_sub_icon" viewBox="0 0 31 36" fill="none" stroke="currentColor">
      <g>
        <path
          d="M21.9569 12.1379V5.25C21.9569 2.90517 20.0517 1 17.7069 1H5.25C2.75862 1 1 2.75862 1 5.25V30.75C1 33.0948 2.75862 35 5.25 35H17.8534C20.1983 35 22.1034 33.0948 22.1034 30.75V23.569"
          stroke="currentColor"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M21.9569 7.1543H1"
          stroke="currentColor"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M21.9569 28.6992H1"
          stroke="currentColor"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M17.707 18H29.2846"
          stroke="currentColor"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M25.3276 14.043L29.4311 18.1464L25.3276 22.2499"
          stroke="currentColor"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M11.9917 22.25H17.4141"
          stroke="currentColor"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M12.8706 13.1641H17.8534"
          stroke="currentColor"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
    </symbol>
    <symbol id="conditonal_off_icon" viewBox="0 0 31 36" fill="none" stroke="currentColor">
      <path
        d="M25.4464 9.92704V6.21888L20.0901 1H16.382H1V32.8627L25.4464 33V30.6652"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M20.0903 1V7.18026H24.7599"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.18018 10.6133H19.266"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.18018 15.832H14.5965"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M29.0171 17.8926L24.4849 22.4248L22.4248 20.3647"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M25.4463 27.3692C29.3905 27.3692 32.5879 24.1718 32.5879 20.2276C32.5879 16.2834 29.3905 13.0859 25.4463 13.0859C21.5021 13.0859 18.3047 16.2834 18.3047 20.2276C18.3047 24.1718 21.5021 27.3692 25.4463 27.3692Z"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </symbol>
    <symbol id="supporting_doc_icon" viewBox="0 0 28 34" fill="none" stroke="currentColor">
      <path
        d="M3.82352 27.3085H17.7512C19.2532 27.3085 20.6187 26.0796 20.6187 24.441V3.82255C20.6187 2.32054 19.3898 0.955078 17.7512 0.955078H3.82352C2.32152 0.955078 0.956055 2.18399 0.956055 3.82255V24.5776C1.0926 26.0796 2.32152 27.3085 3.82352 27.3085Z"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.51025 30.5849V31.131C7.51025 32.0869 8.32953 32.9061 9.28535 32.9061H25.1247C26.0805 32.9061 26.8998 32.0869 26.8998 31.131V8.32783C26.8998 7.37201 26.0805 6.55273 25.1247 6.55273H24.1689"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.5542 7.91797H15.0201"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.5542 12.1523H15.0201"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.5542 16.5215H15.0201"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </symbol>
    <symbol id="unconditonal_off_icon" viewBox="0 0 32 34" fill="none" stroke="currentColor">
      <path
        d="M25.4464 9.92704V6.21888L20.0901 1H16.382H1V32.8627L25.4464 33V30.6652"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M20.0903 1V7.18026H24.7599"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.18018 10.6133H19.266"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.18018 15.832H14.5965"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M30.6652 19.8145H20.3647V27.6428H30.6652V19.8145Z"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M21.4634 19.8148V16.7934C21.4634 14.596 23.2488 12.8105 25.4462 12.8105C27.6436 12.8105 29.429 14.596 29.429 16.7934V19.8148"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M25.4464 24.8966C26.0532 24.8966 26.5451 24.4047 26.5451 23.7979C26.5451 23.1911 26.0532 22.6992 25.4464 22.6992C24.8396 22.6992 24.3477 23.1911 24.3477 23.7979C24.3477 24.4047 24.8396 24.8966 25.4464 24.8966Z"
        fill="currentColor"
      />
    </symbol>
    <symbol id="loan_settle_active" viewBox="0 0 32 32" fill="none" stroke="currentColor">
      <path
        d="M23.9036 9.38076V5.91088L18.8915 1.02734H15.4217H1.02808V30.8426L23.9036 30.9711V28.7864"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18.8916 1.02734V6.81048H23.2611"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.81128 10.0234H18.1205"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.81128 14.9062H13.751"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M23.9036 12.3379C27.502 12.3379 30.5863 15.2937 30.5863 19.0206C30.5863 22.7475 27.6305 25.7034 23.9036 25.7034"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M23.9037 25.5744C20.3053 25.5744 17.2209 22.6186 17.2209 18.8917C17.2209 15.1648 20.1768 12.209 23.9037 12.209"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M22.2331 20.5609C22.2331 21.4605 23.1327 21.9745 24.1608 21.9745C25.1889 21.9745 25.703 21.2035 25.703 20.4324C25.703 19.6613 24.8034 19.1472 23.9038 19.1472C22.8757 19.1472 21.9761 18.3761 21.9761 17.4765C21.9761 16.5769 22.8757 16.1914 23.9038 16.1914C24.9319 16.1914 25.5745 16.7055 25.5745 17.348"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M24.0322 16.0637V15.1641"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M24.0322 22.7473V21.8477"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </symbol>
    <symbol id="uncomplete_task_icon" viewBox="0 0 108 112" fill="none" stroke="currentColor">
      <path
        d="M84.125 81.375V105.625C84.125 108.5 81.75 111 78.75 111H6.375C3.5 111 1 108.625 1 105.625V11.25C1 8.375 3.375 5.875 6.375 5.875H20.125"
        stroke="#020038"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M71.5 6H78.875C81.75 6 84.25 8.375 84.25 11.375V41.875"
        stroke="#020038"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M62.5 11.25H22.625C21.375 11.25 20.375 10.25 20.375 9V3.25C20.375 2 21.375 1 22.625 1H62.5C63.75 1 64.75 2 64.75 3.25V9C64.75 10.25 63.75 11.25 62.5 11.25Z"
        stroke="#020038"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M73.625 79.125L63.375 79.625L63.875 69.375L96.375 36.875L106.125 46.625L73.625 79.125Z"
        stroke="#54B7F9"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M31.625 35.25H69.375"
        stroke="#020038"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M31.625 59.875H63.375"
        stroke="#020038"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M31.625 84.5H69.375"
        stroke="#020038"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M23.75 29.875H11.125V42.5H23.75V29.875Z"
        stroke="#020038"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M20.5 34.25L16.5 38.375L14.625 36.5"
        stroke="currentColor"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M23.75 53.25H11.125V65.875H23.75V53.25Z"
        stroke="#020038"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M20.5 57.625L16.5 61.625L14.625 59.875"
        stroke="currentColor"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M23.75 76.625H11.125V89.25H23.75V76.625Z"
        stroke="#020038"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M20.5 81L16.5 85L14.625 83.25"
        stroke="currentColor"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </symbol>
    <symbol id="loan_summary_icon" viewBox="0 0 25 26" fill="none" stroke="currentColor">
      <g clip-path="url(#clip0_57_73278)">
        <path
          d="M18.5934 11.727V4.32045L14.813 0.617188H0.617188V23.1454H10.724"
          stroke="#020038"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M18.5934 4.32045L14.813 0.617188V4.32045H18.5934Z"
          stroke="#020038"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M4.552 7.63867H14.0416"
          stroke="#020038"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M4.552 11.6504H14.0416"
          stroke="#54B7F9"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M4.552 15.7383H8.40957"
          stroke="currentColor"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M17.2819 23.8402C19.8811 23.8402 21.9881 21.7331 21.9881 19.134C21.9881 16.5348 19.8811 14.4277 17.2819 14.4277C14.6827 14.4277 12.5757 16.5348 12.5757 19.134C12.5757 21.7331 14.6827 23.8402 17.2819 23.8402Z"
          stroke="currentColor"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M20.6765 22.6055L23.5311 25.3829"
          stroke="currentColor"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_57_73278">
          <rect width="24.1484" height="26" fill="white" />
        </clipPath>
      </defs>
    </symbol>
    <symbol id="supporting_document" viewBox="0 0 23 27" fill="none" stroke="currentColor">
      <path
        d="M3.24335 21.6197H14.1408C15.316 21.6197 16.3844 20.6581 16.3844 19.3761V3.24359C16.3844 2.06838 15.4228 1 14.1408 1H3.24335C2.06813 1 0.999756 1.96154 0.999756 3.24359V19.4829C1.10659 20.6581 2.06813 21.6197 3.24335 21.6197Z"
        stroke="#020038"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.12802 24.1843V24.6116C6.12802 25.3595 6.76905 26.0005 7.51691 26.0005H19.9101C20.6579 26.0005 21.299 25.3595 21.299 24.6116V6.76975C21.299 6.02188 20.6579 5.38086 19.9101 5.38086H19.1622"
        stroke="#020038"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.3799 6.44922H12.0038"
        stroke="#020038"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.3799 9.75977H12.0038"
        stroke="#54B7F9"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.3799 13.1797H12.0038"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </symbol>
  </svg>
</template>
