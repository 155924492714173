<template>
  <div class="timeline-container">
    <h3 class="title pm-txt-color gr-medium">
      {{ profileDetails.firstName }} {{ profileDetails.middleName }}
      {{ profileDetails.lastName }}
      <span v-if="profileDetails.firstName">-</span> Current application progress
    </h3>
    <Spinner class="flex" v-if="isLoading" />
    <div v-else class="progress-steps-wrapper">
      <div class="progress-steps p-d-flex p-ai-center">
        <div :class="renderDynamicClass(progress.STEP_1)">
          <div class="icon-wrapper">
            <div class="svg-icon">
              <SvgIcon name="app_sub_icon" size="2.1rem" />
            </div>
            <div class="svg-icon-active">
              <SvgIcon name="app_sub_icon" size="2.1rem" />
            </div>
            <div class="svg-icon-true">
              <SvgIcon name="app_sub_icon" size="2.1rem" />
            </div>
          </div>
          <div class="circle">
            <i v-if="rednerCheckIcon(progress.STEP_1)" class="pi pi-check check"></i>
          </div>
          <p class="text gr-medium">Application<br />submission</p>
        </div>
        <div :class="renderDynamicClass(progress.STEP_2)">
          <div class="icon-wrapper">
            <div class="svg-icon">
              <SvgIcon name="conditonal_off_icon" size="2.1rem" />
            </div>
            <div class="svg-icon-active">
              <SvgIcon name="conditonal_off_icon" size="2.1rem" />
            </div>
            <div class="svg-icon-true">
              <SvgIcon name="conditonal_off_icon" size="2.1rem" />
            </div>
          </div>
          <span class="circle">
            <i v-if="rednerCheckIcon(progress.STEP_2)" class="pi pi-check check"></i>
          </span>
          <p class="text gr-medium">
            Conditional<br />
            offer
          </p>
        </div>
        <div :class="renderDynamicClass(progress.STEP_3)">
          <div class="icon-wrapper">
            <div class="svg-icon">
              <SvgIcon name="supporting_doc_icon" size="2.1rem" />
            </div>
            <div class="svg-icon-active">
              <SvgIcon name="supporting_doc_icon" size="2.1rem" />
            </div>
            <div class="svg-icon-true">
              <SvgIcon name="supporting_doc_icon" size="2.1rem" />
            </div>
          </div>
          <span class="circle">
            <i v-if="rednerCheckIcon(progress.STEP_3)" class="pi pi-check check"></i>
          </span>
          <p class="text gr-medium">Supporting<br />documents</p>
        </div>
        <div :class="renderDynamicClass(progress.STEP_4)">
          <div class="icon-wrapper">
            <div class="svg-icon">
              <SvgIcon name="unconditonal_off_icon" size="2.1rem" />
            </div>
            <div class="svg-icon-active">
              <SvgIcon name="unconditonal_off_icon" size="2.1rem" />
            </div>
            <div class="svg-icon-true">
              <SvgIcon name="unconditonal_off_icon" size="2.1rem" />
            </div>
          </div>
          <span class="circle">
            <i v-if="rednerCheckIcon(progress.STEP_4)" class="pi pi-check check"></i>
          </span>
          <p class="text gr-medium">Unconditional<br />offer</p>
        </div>
        <div :class="renderDynamicClass(progress.STEP_5)">
          <div class="icon-wrapper">
            <div class="svg-icon">
              <SvgIcon name="loan_settle_active" size="2.1rem" />
            </div>
            <div class="svg-icon-active">
              <SvgIcon name="loan_settle_active" size="2.1rem" />
            </div>
          </div>
          <span class="circle">
            <i v-if="rednerCheckIcon(progress.STEP_5)" class="pi pi-check check"></i>
          </span>
          <p class="text gr-medium">Loan<br />settlement</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import SvgIcon from '../../../../../components/common/SvgIcon.vue';

export default {
  components: {
    SvgIcon,
  },
  props: ['progressStatus', 'getProgress'],
  data() {
    return {
      progress: {
        STEP_1: false,
        STEP_2: false,
        STEP_3: false,
        STEP_4: false,
        STEP_5: false,
      },
      isLoading: true,
    };
  },
  methods: {
    ...mapActions({
      fetchLoanProgress: 'loans/fetchLoanProgress',
      setLoanStatus: 'loans/setLoanStatus',
    }),
    renderDynamicClass(step) {
      if (step === 'active') {
        return 'active stepper';
      } else if (step === true) {
        return 'completed stepper';
      } else {
        return 'stepper';
      }
    },

    rednerCheckIcon(step) {
      return step === true ? true : false;
    },

    clickEvent(index, step) {
      const keys = Object.keys(this.progress);

      keys.forEach((el, idx) => {
        if (idx == index - 1) {
          this.progress[step] = 'active';
        }
        if (idx < index - 1) {
          this.progress[el] = true;
        } else if (idx > index - 1) {
          this.progress[el] = false;
        }
      });
    },
    emitToParentProgress(status) {
      this.$emit('getProgress', status);
    },
    setQueryStep() {
      if (this.$route?.query?.type) {
        this.clickEvent(this.$route?.query?.type, 'STEP_3');
        this.emitToParentProgress('supporting');
      }
    },
    init() {
      const status = this.selectedLoanDetails?.applicationStatus;
      this.setLoanStatus(status);
      this.$emit('getProgress', status);
      switch (status) {
        case 'application_submitted':
        case 'assessment_ready':
        case 'application_in_progress':
          this.progress.STEP_1 = 'active';
          this.progress.STEP_2 = false;
          this.progress.STEP_3 = false;
          this.progress.STEP_4 = false;
          this.progress.STEP_5 = false;
          break;
        /* */
        case 'conditional_offer':
          this.progress.STEP_1 = true;
          this.progress.STEP_2 = 'active';
          this.progress.STEP_3 = false;
          this.progress.STEP_4 = false;
          this.progress.STEP_5 = false;
          break;
        case 'supporting_documents':
          this.progress.STEP_1 = true;
          this.progress.STEP_2 = true;
          this.progress.STEP_3 = 'active';
          this.progress.STEP_4 = false;
          this.progress.STEP_5 = false;
          break;
        case 'loan_confirmation_pending':
          this.progress.STEP_1 = true;
          this.progress.STEP_2 = true;
          this.progress.STEP_3 = 'active';
          this.progress.STEP_4 = false;
          this.progress.STEP_5 = false;
          break;
        case 'unconditional_offer':
          this.progress.STEP_1 = true;
          this.progress.STEP_2 = true;
          this.progress.STEP_3 = true;
          this.progress.STEP_4 = 'active';
          this.progress.STEP_5 = false;
          break;
        case 'legal_documents_sign_pending':
          this.progress.STEP_1 = true;
          this.progress.STEP_2 = true;
          this.progress.STEP_3 = true;
          this.progress.STEP_4 = true;
          this.progress.STEP_5 = 'active';
          break;
        case 'loan_settlement':
          this.progress.STEP_1 = true;
          this.progress.STEP_2 = true;
          this.progress.STEP_3 = true;
          this.progress.STEP_4 = true;
          this.progress.STEP_5 = 'active';
          break;
        case 'sattlement_completed':
          this.progress.STEP_1 = true;
          this.progress.STEP_2 = true;
          this.progress.STEP_3 = true;
          this.progress.STEP_4 = true;
          this.progress.STEP_5 = 'active';
          break;
        case 'repayment_complete':
          this.progress.STEP_1 = true;
          this.progress.STEP_2 = true;
          this.progress.STEP_3 = true;
          this.progress.STEP_4 = true;
          this.progress.STEP_5 = 'active';
          break;
        case 'cancelled':
          this.progress.STEP_1 = true;
          this.progress.STEP_2 = true;
          this.progress.STEP_3 = true;
          this.progress.STEP_4 = true;
          this.progress.STEP_5 = 'active';
          break;
        case 'rejected':
          this.progress.STEP_1 = true;
          this.progress.STEP_2 = true;
          this.progress.STEP_3 = true;
          this.progress.STEP_4 = true;
          this.progress.STEP_5 = 'active';
          break;
      }
    },
  },
  computed: {
    ...mapGetters({
      profileDetails: 'settings/profileDetails',
      selectedLoanProgress: 'loans/selectedLoanProgress',
      selectedLoanDetails: 'loans/selectedLoanDetails',
    }),
  },
  async created() {
    this.isLoading = true;
    this.setQueryStep();
    // await this.fetchLoanProgress();
    const status = this.selectedLoanDetails?.applicationStatus;
    this.setLoanStatus(status);
    this.$emit('getProgress', status);
    switch (status) {
      case 'application_submitted':
      case 'assessment_ready':
      case 'application_in_progress':
        this.progress.STEP_1 = 'active';
        this.progress.STEP_2 = false;
        this.progress.STEP_3 = false;
        this.progress.STEP_4 = false;
        this.progress.STEP_5 = false;
        break;
      /* */
      case 'conditional_offer':
        this.progress.STEP_1 = true;
        this.progress.STEP_2 = 'active';
        this.progress.STEP_3 = false;
        this.progress.STEP_4 = false;
        this.progress.STEP_5 = false;
        break;
      case 'supporting_documents':
        this.progress.STEP_1 = true;
        this.progress.STEP_2 = true;
        this.progress.STEP_3 = 'active';
        this.progress.STEP_4 = false;
        this.progress.STEP_5 = false;
        break;
      case 'loan_confirmation_pending':
        this.progress.STEP_1 = true;
        this.progress.STEP_2 = true;
        this.progress.STEP_3 = 'active';
        this.progress.STEP_4 = false;
        this.progress.STEP_5 = false;
        break;
      case 'unconditional_offer':
        this.progress.STEP_1 = true;
        this.progress.STEP_2 = true;
        this.progress.STEP_3 = true;
        this.progress.STEP_4 = 'active';
        this.progress.STEP_5 = false;
        break;
      case 'legal_documents_sign_pending':
        this.progress.STEP_1 = true;
        this.progress.STEP_2 = true;
        this.progress.STEP_3 = true;
        this.progress.STEP_4 = true;
        this.progress.STEP_5 = 'active';
        break;
      case 'loan_settlement':
        this.progress.STEP_1 = true;
        this.progress.STEP_2 = true;
        this.progress.STEP_3 = true;
        this.progress.STEP_4 = true;
        this.progress.STEP_5 = 'active';
        break;
      case 'sattlement_completed':
        this.progress.STEP_1 = true;
        this.progress.STEP_2 = true;
        this.progress.STEP_3 = true;
        this.progress.STEP_4 = true;
        this.progress.STEP_5 = 'active';
        break;
      case 'repayment_complete':
        this.progress.STEP_1 = true;
        this.progress.STEP_2 = true;
        this.progress.STEP_3 = true;
        this.progress.STEP_4 = true;
        this.progress.STEP_5 = 'active';
        break;
      case 'cancelled':
        this.progress.STEP_1 = true;
        this.progress.STEP_2 = true;
        this.progress.STEP_3 = true;
        this.progress.STEP_4 = true;
        this.progress.STEP_5 = 'active';
        break;
      case 'rejected':
        this.progress.STEP_1 = true;
        this.progress.STEP_2 = true;
        this.progress.STEP_3 = true;
        this.progress.STEP_4 = true;
        this.progress.STEP_5 = 'active';
        break;
    }
    this.isLoading = false;
  },
  watch: {
    selectedLoanDetails() {
      this.init();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';

.timeline-container {
  margin-top: 2rem;

  .title {
    font-size: bold;
    color: $primary-text-color;
    font-size: 1.25rem;
  }

  .progress-steps-wrapper {
    margin: 2rem 0 0 0;
    border-bottom: 1px solid $light-text-color;
    padding-bottom: 2rem;
    box-sizing: border-box !important;

    .connector {
      background-color: black;
      height: 2px;
      width: 50px;
    }

    .progress-steps {
      box-sizing: border-box !important;
      width: 100%;
      padding: 0 1rem;
      gap: 3rem;
      justify-content: space-between;
      overflow: hidden;
      @media (max-width: 992px) {
        overflow: auto;
      }

      .stepper {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        align-items: center;
        justify-content: center;
        z-index: 90;
        box-sizing: border-box !important;

        .icon-wrapper {
          .icon {
            font-size: 2rem;
            color: #b1b1b1;
          }
        }

        .circle {
          width: 25px;
          height: 25px;
          border: 3px solid $light-text-color;
          border-radius: 50%;
          position: relative;
          background-color: $white-color;

          &::after {
            content: '';
            display: block;
            position: absolute;
            width: 50px;
            height: 10px;
            top: 0.4rem;
            left: -15px;
            background-color: white;
            z-index: -1;
          }
        }

        &:not(:last-child) {
          .circle {
            &::after {
              content: '';
              display: block;
              position: absolute;
              width: 50px;
              height: 10px;
              top: 0.4rem;
              left: -15px;
              background-color: white;
              z-index: -1;
            }

            &::before {
              content: '';
              display: block;
              position: absolute;
              width: 150px;
              height: 2px;
              left: 2rem;
              top: 0.5rem;
              background-color: #b1b1b1;
              z-index: -1;

              @media screen and (min-width: 1300px) {
                width: 420px;
              }
            }
          }
        }

        &:last-child {
          .circle {
            &::after {
              width: 250px;
              left: -15px;
              background-color: white;
              z-index: -1;
            }
          }
        }

        &:first-child {
          .circle {
            &::before {
              top: 0.45rem;
            }
          }
        }

        .text {
          white-space: wrap;
          color: $light-text-color;
          font-size: 1.2rem;
          text-align: center;
        }
      }

      .svg-icon {
        display: block;
        color: $light-text-color;
      }

      .svg-icon-true,
      .svg-icon-active {
        display: none;
      }

      .active {
        .svg-icon-active {
          display: block;
          color: $primary-color;
        }

        .svg-icon {
          display: none;
        }

        .svg-icon-true {
          display: none;
          color: $primary-color;
        }
      }

      .completed {
        .svg-icon-active {
          display: none;
        }

        .svg-icon {
          display: none;
        }

        .svg-icon-true {
          display: block;
          color: $primary-color;
        }
      }

      .active .text {
        color: $primary-color !important;
        // font-weight: bold;
      }

      .active .circle {
        border: 3px solid $primary-color !important;
      }

      .completed .text {
        color: $primary-text-color !important;
        // font-weight: bold;
      }

      .completed .circle {
        border: 3px solid $primary-text-color !important;
        background-color: $primary-text-color;
        display: flex;
        align-items: center;
        justify-content: center;

        & .check {
          color: $white-color;
          font-weight: bold;
          font-size: 0.8rem;
        }
      }

      .completed .circle {
        &::before {
          background-color: $primary-text-color !important;
        }
      }
    }
  }
}
</style>
