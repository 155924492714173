export default {
  namespaced: true,
  state: {
    employmentFormData: {
      number_of_full_time: 0,
      number_of_casual: 0,
      number_of_part_time: 0,
      number_of_self: 0,
      number_of_unemployed: 0,
      number_of_retired: 0,
      fullTime_1: [],
      casual_1: [],
      parttime_1: [],
      selfemployed_1: [],
      additional_1: [],
      empdetails_1: [],
      incomeexpectation_1: [],
      applicant_2_isUnemployed: false,
      applicant_2_isRetired: false,
    },
    incomeData: {},
  },
  getters: {
    employmentFormData: ({ employmentFormData }) => employmentFormData,
    incomeData: ({ incomeData }) => incomeData,
  },
  mutations: {
    setIncomeData(storeState, { fieldName, index, value, type, rootState }) {
      const { secondapplicationform } = rootState;
      let obj = {};
      switch (type) {
        case 'fulltime1':
          obj[fieldName] = value;
          obj['applicantType'] = secondapplicationform.applicantType == 'primary' ? 'primary' : 'secondary';
          obj['jobType'] = 'full_time';
          storeState.employmentFormData['fullTime_1'][index] = {
            ...storeState.employmentFormData['fullTime_1'][index],
            ...obj,
          };
          break;
        case 'casual1':
          obj[fieldName] = value;
          obj['applicantType'] = secondapplicationform.applicantType == 'primary' ? 'primary' : 'secondary';
          obj['jobType'] = 'casual';
          storeState.employmentFormData['casual_1'][index] = {
            ...storeState.employmentFormData['casual_1'][index],
            ...obj,
          };
          break;
        case 'parttime1':
          obj[fieldName] = value;
          obj['applicantType'] = secondapplicationform.applicantType == 'primary' ? 'primary' : 'secondary';
          obj['jobType'] = 'part_time';
          storeState.employmentFormData['parttime_1'][index] = {
            ...storeState.employmentFormData['parttime_1'][index],
            ...obj,
          };
          break;
        case 'selfemployed1':
          obj[fieldName] = value;
          obj['applicantType'] = secondapplicationform.applicantType == 'primary' ? 'primary' : 'secondary';
          obj['jobType'] = 'self_employed';
          storeState.employmentFormData['selfemployed_1'][index] = {
            ...storeState.employmentFormData['selfemployed_1'][index],
            ...obj,
          };
          break;
        case 'additional1':
          obj[fieldName] = value;
          obj['applicantType'] = secondapplicationform.applicantType == 'primary' ? 'primary' : 'secondary';
          storeState.employmentFormData['additional_1'][index] = {
            ...storeState.employmentFormData['additional_1'][index],
            ...obj,
          };
          break;
        case 'empdetails1':
          obj[fieldName] = value;
          obj['applicantType'] = secondapplicationform.applicantType == 'primary' ? 'primary' : 'secondary';
          storeState.employmentFormData['empdetails_1'][index] = {
            ...storeState.employmentFormData['empdetails_1'][index],
            ...obj,
          };
          break;
        case 'incomeexpectation1':
          obj[fieldName] = value;
          obj['applicantType'] = secondapplicationform.applicantType == 'primary' ? 'primary' : 'secondary';
          storeState.employmentFormData['incomeexpectation_1'][index] = {
            ...storeState.employmentFormData['incomeexpectation_1'][index],
            ...obj,
          };
          break;
      }
    },
    setIncomeDataEmpty(storeState, { type }) {
      switch (type) {
        case 'fulltime1':
          storeState.employmentFormData['fullTime_1'] = [];
          break;
        case 'casual1':
          storeState.employmentFormData['casual_1'] = [];
          break;
        case 'parttime1':
          storeState.employmentFormData['parttime_1'] = [];
          break;
        case 'selfemployed1':
          storeState.employmentFormData['selfemployed_1'] = [];
          break;
        case 'all1':
          storeState.employmentFormData['fullTime_1'] = [];
          storeState.employmentFormData['casual_1'] = [];
          storeState.employmentFormData['parttime_1'] = [];
          storeState.employmentFormData['selfemployed_1'] = [];
          storeState.employmentFormData.applicant_2_isRetired = false;
          storeState.employmentFormData.applicant_2_isUnemployed = false;
          break;
      }
    },
    setEmploymentFormData(storeState, { fieldName, value }) {
      storeState.employmentFormData[fieldName] = value;
    },
    setFinalData(storeState, rootState) {
      const { secondapplicationform } = rootState;
      let obj = {
        salaried: [
          ...storeState.employmentFormData.fullTime_1,
          ...storeState.employmentFormData.casual_1,
          ...storeState.employmentFormData.parttime_1,
        ],
        self_employed: [...storeState.employmentFormData.selfemployed_1],
        additional_income: [...storeState.employmentFormData.additional_1],
        income_expectations: [...storeState.employmentFormData.incomeexpectation_1],
        employment_details: [...storeState.employmentFormData.empdetails_1],
      };
      if (secondapplicationform.applicantType == 'primary') {
        obj.applicant_1_isUnemployed = storeState.employmentFormData.applicant_2_isUnemployed
          ? storeState.employmentFormData.applicant_2_isUnemployed
          : false;
        obj.applicant_1_isRetired = storeState.employmentFormData.applicant_2_isRetired
          ? storeState.employmentFormData.applicant_2_isRetired
          : false;
      } else {
        obj.applicant_2_isUnemployed = storeState.employmentFormData.applicant_2_isUnemployed
          ? storeState.employmentFormData.applicant_2_isUnemployed
          : false;
        obj.applicant_2_isRetired = storeState.employmentFormData.applicant_2_isRetired
          ? storeState.employmentFormData.applicant_2_isRetired
          : false;
      }
      storeState.incomeData = obj;
    },
    setFinalDataEmpty(storeState) {
      storeState.employmentFormData = {
        number_of_full_time: 0,
        number_of_casual: 0,
        number_of_part_time: 0,
        number_of_self: 0,
        number_of_unemployed: 0,
        number_of_retired: 0,
        fullTime_1: [],
        casual_1: [],
        parttime_1: [],
        selfemployed_1: [],
        additional_1: [],
        empdetails_1: [],
        incomeexpectation_1: [],
      };
      storeState.incomeData = {};
      let obj = {
        salaried: [],
        self_employed: [],
        additional_income: [],
        income_expectations: [],
        employment_details: [],
        applicant_2_isUnemployed: false,
        applicant_2_isRetired: false,
      };
      storeState.incomeData = obj;
    },
    setAdditionalIncomeDataEmpty(storeState, { title, count }) {
      switch (title) {
        case 'full_time':
          storeState.employmentFormData.fullTime_1.splice(count);
          return;
        case 'part_time':
          storeState.employmentFormData.parttime_1.splice(count);
          return;
        case 'casual':
          storeState.employmentFormData.casual_1.splice(count);
          return;
        case 'self_employed':
          storeState.employmentFormData.selfemployed_1.splice(count);
          return;
      }
    },
  },
  actions: {
    setEmploymentFormData({ commit }, payload) {
      commit('setEmploymentFormData', payload);
    },
    setIncomeData({ commit, rootState }, payload) {
      payload.rootState = rootState;
      commit('setIncomeData', payload);
    },
    setFinalData({ commit, rootState }) {
      commit('setFinalData', rootState);
    },
    setIncomeDataEmpty({ commit }, payload) {
      commit('setIncomeDataEmpty', payload);
    },
    setFinalDataEmpty({ commit }, payload) {
      commit('setFinalDataEmpty', payload);
    },
    setAdditionalIncomeDataEmpty({ commit }, payload) {
      commit('setAdditionalIncomeDataEmpty', payload);
    },
  },
};
