import { TECHLEND_BE_URL } from '@/constants.api';
import { _get } from '@/services/crudService';

export default {
  namespaced: true,

  state: {
    sellingFormData: {
      number_of_property: 0,
      selling_property_details: [],
    },
    sellingData: {},
  },
  getters: {
    sellingFormData: ({ sellingFormData }) => sellingFormData,
    propertyId: ({ propertyId }) => propertyId,
    sellingData: ({ sellingData }) => sellingData,
  },
  mutations: {
    setSellingState(storeState, { fieldName, value }) {
      storeState.sellingFormData[fieldName] = value;
    },
    setPropertyDetails(storeState, { fieldName, index, value }) {
      let propertyObj = {};
      propertyObj[fieldName] = value;
      storeState.sellingFormData['selling_property_details'][index] = {
        ...storeState.sellingFormData['selling_property_details'][index],
        ...propertyObj,
      };
    },
    setFinalData(storeState) {
      const finalObj = {
        properties_intend_to_sell: Number(storeState.sellingFormData.number_of_property),
        outgoing_properties: storeState.sellingFormData.selling_property_details,
      };
      storeState.sellingData = finalObj;
    },
    setPrePopulatedData(storeState, payload) {
      storeState.sellingFormData.number_of_property = payload.outgoing_properties.length;
      storeState.sellingFormData.selling_property_details = payload.outgoing_properties;
      storeState.sellingFormData.selling_property_details.map((obj) => {
        if (obj.propertyStatus == 'list_for_sale') {
          obj.propertyStatus = 'listedForSale';
        }
        obj.country = 'Australia';
      });
    },
    setAdditonalPropertyEmpty(storeState, numberOfProperty) {
      storeState.sellingFormData.selling_property_details.splice(numberOfProperty);
    },
    setResetState(storeState) {
      (storeState.sellingFormData = {
        number_of_property: 0,
        selling_property_details: [],
      }),
        (storeState.sellingData = {});
    },
  },
  actions: {
    setSellingState({ commit }, payload) {
      commit('setSellingState', payload);
      commit('setFinalData');
    },
    setPropertyDetails({ commit }, payload) {
      commit('setPropertyDetails', payload);
      commit('setFinalData');
    },
    async fetchZoneByPostal(storeState, payload) {
      const res = await _get(`${TECHLEND_BE_URL}post_code?post_code=${payload}`);
      const { result } = res.data;
      return {
        zone: result?.sp_category.replace('-', '_').toLowerCase(),
        premium: result.premium ? result.premium : false,
        category: result.category ? result.category : '',
        region: result.region ? result.region : '',
        high_density_area: result.high_density_area ? (result.high_density_area == 'true' ? true : false) : false,
      };
    },
    setAdditonalPropertyEmpty({ commit }, payload) {
      commit('setAdditonalPropertyEmpty', payload);
    },
  },
};
