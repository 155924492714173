export function incomingProperties(arr) {
  return arr.map((property) => ({
    propertyType: property?.type || 'residential',
    line1: '',
    line2: '',
    city: '',
    state: property?.state || '',
    postCode: property?.postCode || '',
    country: 'Australia',
    depositAmount: property.depositAmount,
    purchasePrice: property.purchasePrice,
    settlementDate: property.settlementDate,
    depositPaidType: property.depositPaidType,
    stamp_duty_status: property.stampDutyStatus,
    zone: property.propertyZone,
  }));
}

export function getLoanScenarioPrefilledData(loanScenarioData) {
  console.log(`getLoanScenarioPrefilledData`, loanScenarioData);
  const primaryApplicant = loanScenarioData.applicants.find((applicant) => applicant.applicantType == 'primary');
  const secondaryApplicant = loanScenarioData.applicants.find((applicant) => applicant.applicantType == 'secondary');
  return {
    buying: {
      buyingFormData: {
        loanPurpose: '',
        number_of_property: loanScenarioData?.incomingProperties?.length || '',
        buyingPlans: '',
        buying_property_details: loanScenarioData.incomingProperties.map((property) => ({
          propertyType: property?.type,
          line1: '',
          line2: '',
          city: '',
          state: property?.state || '',
          postCode: property?.postCode || '',
          country: 'Australia',
          depositAmount: property.depositAmount,
          purchasePrice: property.purchasePrice,
          settlementDate: property.settlementDate,
          depositPaidType: property.depositPaidType,
          stamp_duty_status: property.stampDutyStatus,
          zone: property.propertyZone,
        })),
      },
      isPreApproval: false,
      buyingData: {},
      showContractForSale: true,
      customerReferalId: '',
      requestedAppId: '',
      isFromCRM: false,
    },
    selling: {
      sellingFormData: {
        number_of_property: loanScenarioData?.outgoingProperties?.length || 0,
        selling_property_details: loanScenarioData?.outgoingProperties?.map((property) => ({
          type: property.type,
          line1: '',
          line2: '',
          city: '',
          state: property.state || '',
          postCode: property.postCode || '',
          country: 'Australia',
          zone: property.propertyZone,
          outstandingMortgageAmount: property.currentMortgage || 0,
          mortageAgainst: property?.currentMortgage ? true : false,
          depositAmountReceived: property?.depositAmount,
          salesPrice: property.salesPrice || 0,
          purchase_price_used: property.purchasePrice || 0,
          settlementDate: property.settlementDate || '',
          estimatedPropertyPrice: property.estimatedPropertyPrice || 0,
          propertyStatus: property?.propertySold == 'yes' ? 'sold' : 'listed_for_sale',
          depositHeldInTrust: property.depositHeldInTrust,
        })),
      },
      sellingData: {},
    },
    loanDetails: {
      addditionalFundsData: {
        addditional_funds: {
          hasAdditionalSavings: null,
          hasGiftedFunds: null,
          additionalSavingAmount: 0,
          savingsAmount: 0,
          giftedFundsAmount: 0,
          giftedFundsPercentage: 0,
        },
        loan_date: null,
        loanData: {},
        loanFormData: {},
        loan_amount: 0,
        loan_term: 0,
        brokerFee: 0,
        brokerFeeAmount: 0,
        brokerNumber: '',
        brokerFirstName: '',
        brokerLastName: '',
        brokerFeeFromApplication: 0,
        loanNote: '',
        loanCalcFields: {
          op_score_value: '',
          ip_score_value: '',
          ip_score_zone_value: '',
          ip_score_category_value: '',
          op_score_zone_value: '',
          op_score_category_value: '',
        },
      },
    },
    applicant: {
      applicantFormData: {
        number_of_applicants: '',
        applicant_1_relationship: primaryApplicant?.relationshipStatus || '',
        applicant_2_relationship: secondaryApplicant?.relationshipStatus || '',
        applicant_2_relationship_with_applicant_1: '',
        applicant_2_other_info: '',
        applicant_details: [],
        sellingPropertiesOwnerShip: [],
        buyingPropertiesOwnerShip: [],
      },
      applicantData: {},
      endDebt: 0,
      endDebtObj: {},
    },
    income: {
      employmentFormData: {
        number_of_full_time: 0,
        number_of_casual: 0,
        number_of_part_time: 0,
        number_of_self: 0,
        number_of_unemployed: 0,
        number_of_retired: 0,
        fullTime_1: [],
        casual_1: [],
        parttime_1: [],
        selfemployed_1: [],
        additional_1: [],
        empdetails_1: [],
        incomeexpectation_1: [],
        applicant_1_isUnemployed: false,
        applicant_1_isRetired: false,
        applicant_2_isUnemployed: false,
        applicant_2_isRetired: false,
      },
      employmentFormData2: {
        number_of_full_time: 0,
        number_of_casual: 0,
        number_of_part_time: 0,
        number_of_self: 0,
        number_of_unemployed: 0,
        number_of_retired: 0,
        fullTime_2: [],
        casual_2: [],
        parttime_2: [],
        selfemployed_2: [],
        additional_2: [],
        empdetails_2: [],
        incomeexpectation_2: [],
      },
      incomeData: {},
    },
    expense: {
      expenseFormData: {
        numberOfDependents: primaryApplicant?.numberOfDependants || '',
        expenses: [],
        decrease_month: 0,
        increase_month: 0,
        changes_in_living_expenses: '',
        totalExpenses: 0,
      },
      expenseData: {
        expenses: [],
        applicant_1_dependents: '0',
        applicant_1_decrease_month: 0,
        applicant_1_increase_month: 0,
        applicant_1_total_expenses: 0,
      },
    },

    expense2: {
      expenseFormData: {
        numberOfDependents: secondaryApplicant?.numberOfDependants || '',
        expenses: [],
        decrease_month: 0,
        increase_month: 0,
        changes_in_living_expenses: '',
        totalExpenses: 0,
      },
      expenseData: {
        expenses: [],
        applicant_2_dependents: '0',
        applicant_2_decrease_month: 0,
        applicant_2_increase_month: 0,
        applicant_2_total_expenses: 0,
      },
    },
  };
}
