<template>
  <div class="modal-container box-shadow" style="z-index: 10 !important">
    <Dialog
      class="dialog-wrapper"
      :visible="displayBasic"
      :style="{ width: '70vw', 'z-index': '10 !important' }"
      :modal="true"
    >
      <template #header>
        <h1 class="title pm-txt-color">Property - Summary</h1>
      </template>
      <Spinner class="flex" v-if="isLoadingPage" />
      <div v-else class="modal-content">
        <div class="icon-wrapper">
          <img :src="require('@/assets/icons/document_icon.svg')" alt="" />
        </div>
        <form action="">
          <div class="input-wrapper">
            <p class="heading pm-txt-color">Property address</p>
            <p class="text pm-txt-color">
              To complete your application submission, please update the address of the incoming property.
            </p>
            <div class="field">
              <AddressAutocompleteKleber label="Enter street address" @onChange="onAddressChange" />
            </div>
          </div>
          <div class="input-wrapper">
            <p class="heading pm-txt-color">Property details</p>
            <p class="text pm-txt-color">
              Please check to ensure the following information is true and update it if necessary.
            </p>
            <div class="p-d-flex p-ai-center field">
              <div class="col mr-5">
                <label class="mb-0 pm-txt-color" for="address">Property type</label>
                <Dropdown
                  :items="tasktypes"
                  @change="getFormData($event.value, 'type')"
                  width="100%"
                  optionLabel="name"
                  placeholder="-Select-"
                  :value="type"
                  :key="typeKey"
                />
              </div>
              <div class="col">
                <label class="pm-txt-color" for="address">Deposit amount</label>
                <InputNumberField
                  width="100%"
                  @onInput="getFormData($event, 'deposit_amount')"
                  type="number"
                  placeholder="Deposit price"
                  prefix="$"
                  :format="true"
                  :value="formData.buying_property.deposit_amount && formData.buying_property.deposit_amount"
                />
              </div>
            </div>
          </div>
          <div class="p-d-flex p-ai-center mt-3">
            <div class="col mr-5">
              <label class="mb-0 pm-txt-color" for="address">Purchase price</label><br />
              <InputNumberField
                @onInput="getFormData($event, 'purchase_price')"
                width="100%"
                type="number"
                placeholder="Purchase price"
                prefix="$"
                :format="true"
                :value="formData.buying_property.purchase_price && formData.buying_property.purchase_price"
              />
            </div>
            <div class="col">
              <label class="pm-txt-color mb-3" for="address">Purchase date</label>
              <DatePicker
                :value="formData.buying_property.purchase_date && formData.buying_property.purchase_date"
                width="100%"
                @change="getFormData($event, 'purchase_date')"
                :key="dateKey"
              />
            </div>
          </div>
          <div class="radio-input field">
            <div class="pm-txt-color">How was your deposit paid?</div>
            <div class="radio-wrapper">
              <div class="radio-col">
                <input
                  id="cash"
                  type="radio"
                  name="deposit"
                  value="cash"
                  v-model="formData.buying_property.deposit_type"
                />
                <label class="pm-txt-color" for="cash">Cash payment</label>
              </div>
              <div class="radio-col">
                <input
                  id="bond"
                  type="radio"
                  name="deposit"
                  value="bond"
                  v-model="formData.buying_property.deposit_type"
                />
                <label class="pm-txt-color" for="bond">Deposit bond</label>
              </div>
            </div>
          </div>
        </form>
      </div>
      <template #footer>
        <div class="modal-foodter">
          <p class="close" @click="closeBasic">Back</p>

          <Button :disabled="isSubmitDisabled() || isLoading" label="Update and Submit" type="primary" @click="save" />
        </div>
      </template>
    </Dialog>
  </div>
</template>

<script>
import { TECHLEND_BE_URL } from '@/constants.api';
import { _get, _put } from '@/services/crudService';
import { helpers, required } from '@vuelidate/validators';
import { mapActions, mapGetters } from 'vuex';
import { useVuelidate } from '@vuelidate/core';
import AddressAutocompleteKleber from '@/components/common/AddressAutocompleteKleber.vue';
import Dialog from 'primevue/dialog';

const postcodeRegex = /^(?:0[289][0-9]{2})|(?:[1-9][0-9]{3})$/;
const isPostcodeValid = helpers.regex('isPostcodeValid', postcodeRegex);

export default {
  props: ['closeBasic', 'openBasic', 'displayBasic'],
  components: { Dialog, AddressAutocompleteKleber },
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      isLoading: false,
      tasktypes: [
        { name: 'Residential', value: 'residential' },
        { name: 'Rural Residential', value: 'rural_residential' },
        { name: 'Off the plan residential', value: 'off_plan_residential' },
        { name: 'Commercial', value: 'commercial' },
        { name: 'Vacant Land', value: 'vacant_land' },
        { name: 'Retirement, Land Lease, or Community home', value: 'retirement_land_lease_or_community_home' },
      ],
      isLoadingPage: false,
      typeKey: 0,
      dateKey: 10,
      formData: {
        application_id: '',
        buying_property_id: '',
        application_stage: '',
        buying_property: {
          line1: '',
          add_2: '',
          postCode: 0,
          country: '',
          city: '',
          state: '',
          deposit_type: '',
          deposit_amount: 0,
          purchase_price: 0,
          purchase_date: '',
          type: '',
          unitNumber: '',
          streetNumber: '',
          streetType: '',
          streetName: '',
          zone: '',
          premium: false,
          category: '',
        },
        status: '',
      },
    };
  },
  validations() {
    return {
      formData: {
        buying_property: {
          line1: { required },
          postCode: { required, isPostcodeValid },
          country: { required },
          city: { required },
          state: { required },
          deposit_type: { required },
          deposit_amount: { required },
          purchase_price: { required },
          purchase_date: { required },
          type: { required },
        },
      },
    };
  },
  methods: {
    ...mapActions({
      fetchZoneByPostal: 'applicationform/selling/fetchZoneByPostal',
    }),
    isSubmitDisabled() {
      return this.v$.$invalid;
    },
    async onAddressChange(address) {
      this.formData.buying_property.line1 = address.address_1 ? address.address_1 : '';
      this.formData.buying_property.add_2 = address.address_2 ? address.address_2 : '';
      this.formData.buying_property.city = address.address_1 ? address.city : '';
      this.formData.buying_property.state = address.state ? address.state : '';
      this.formData.buying_property.country = address.country ? address.country : '';
      this.formData.buying_property.postCode = address.postCode ? Number(address.postCode) : 0;
      this.formData.buying_property.unitNumber = address.unitNumber ? address.unitNumber : '';
      this.formData.buying_property.streetNumber = address.streetNumber ? address.streetNumber : '';
      this.formData.buying_property.streetType = address.streetType ? address.streetType : '';
      this.formData.buying_property.streetName = address.streetName ? address.streetName : '';
      const res = await this.fetchZoneByPostal(
        this.formData.buying_property.postCode ? this.formData.buying_property.postCode : '',
      );
      this.formData.buying_property.zone = res.zone ? res.zone : '';
      this.formData.buying_property.premium = res.premium;
      this.formData.buying_property.category = res.category ? res.category : '';
    },
    getFormData(value, fieldName) {
      this.formData.buying_property[fieldName] = value;
      this.formData = { ...this.formData };
    },
    async save() {
      try {
        this.isLoading = true;

        await _put(TECHLEND_BE_URL + 'update_application_task_status?id=' + this.selectedTaskId, {
          application_id: this.selectedLoanId,
          status: 'review',
          buying_property: {
            ...this.formData.buying_property,
          },
          buying_property_id: this.propId,
          application_stage: 'created',
        });
        this.isLoading = false;
        this.$emit('completed');
      } catch (err) {
        if (err.response && err.response.data && err.response.data.message) {
          await this.$toast.add({
            severity: 'error',
            summary: 'Error',
            detail: err.response.data.message,
            life: 3000,
          });
        } else {
          await this.$toast.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Something went wrong',
            life: 3000,
          });
        }
        this.isLoading = false;
      }
    },
  },
  computed: {
    ...mapGetters({
      selectedTaskId: 'loans/selectedTaskId',
      selectedLoanId: 'loans/selectedLoanId',
      propId: 'loans/propId',
    }),
  },
  async created() {
    if (this.displayBasic) {
      this.isLoadingPage = true;
      const result = await _get(TECHLEND_BE_URL + 'get_buying_property_details?id=' + this.propId);
      if (result && result.data && result.data.result && result.data.result.length) {
        this.formData.buying_property.deposit_amount = result.data.result[0].deposit_amount
          ? result.data.result[0].deposit_amount
          : 0;
        this.formData.buying_property.purchase_price = result.data.result[0].purchase_price
          ? result.data.result[0].purchase_price
          : 0;
        this.formData.buying_property.purchase_date = result.data.result[0].settlement_date
          ? new Date(result.data.result[0].settlement_date)
          : 0;
        this.formData.buying_property.deposit_type = result.data.result[0].deposit_type
          ? result.data.result[0].deposit_type
          : 0;
        this.type = this.tasktypes.find((obj) => obj.value == result.data.result[0].type);
        this.formData.buying_property.type = this.type?.value;
        this.typeKey += 1;
        this.dateKey += 1;
        this.isLoadingPage = false;
      }
    }
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';
.title {
  font-weight: bold;
  color: $primary-color;
  font-size: 2.25rem;
}
.modal-content {
  border: none;
  .p-dialog {
    z-index: 10 !important;
  }
  .icon-wrapper {
    .icon {
      font-size: 2rem;
    }
  }
  .col {
    padding: unset !important;
  }
  form {
    margin-top: 1rem;

    .input-wrapper {
      display: block;

      &:not(:first-child) {
        margin-top: 1.5rem;
      }

      .heading {
        margin: 0;
        font-weight: bold;
        font-size: 1.1rem;
      }

      .text {
        margin-top: 0.5rem;
        font-size: 0.9rem;
        margin-bottom: 0;
      }

      .field {
        margin: 0;
        margin-top: 0.5rem;

        label {
          margin-bottom: 0;
          font-size: 1rem;
        }
      }
    }
    .radio-input {
      margin-top: 1rem;

      .radio-wrapper {
        margin-top: 0.5rem;
        display: flex;
        gap: 1rem;
      }

      .radio-col {
        display: flex;
        align-items: center;
        gap: 5px;
      }
    }
  }
}
.modal-foodter {
  gap: 2rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .close {
    margin: 0;
    cursor: pointer;
  }
}
</style>
