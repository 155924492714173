<template>
  <div class="modal-container">
    <Dialog
      :visible="display"
      :modal="true"
      :style="[isMR ? { width: '95vw', height: '100vh' } : { width: '70vw', height: '100vh' }]"
    >
      <div class="modal-content">
        <iframe style="height: 100vh" :src="link" :key="frameKey" />
      </div>
      <template #footer>
        <div class="modal-foodter mr-5 mt-4">
          <p class="close" @click="closeDialog">Cancel</p>
          <Button label="Complete" type="primary" @click="goToNext" />
        </div>
      </template>
    </Dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Dialog from 'primevue/dialog';
import Screensize from '@/mixins/screensize.mixin';

export default {
  mixins: [Screensize],
  props: ['display', 'openDialog'],
  components: { Dialog },
  data() {
    return {
      link: 'https://scv.bankstatements.com.au/XGYJ',
      frameKey: 0,
    };
  },
  methods: {
    closeDialog() {
      this.$emit('closeDialog');
    },
    goToNext() {
      this.$emit('closeDialog');
      this.$emit('closeCondition');
    },
  },
  created() {
    this.link = this.link + this.selectedLoanId + '--' + this.profileDetails.type;
    this.frameKey += 1;
  },
  computed: {
    ...mapGetters({
      selectedLoanId: 'loans/selectedLoanId',
      profileDetails: 'settings/profileDetails',
    }),
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';

.title {
  font-weight: bold;
  color: $primary-color;
  font-size: 2.25rem;
}

.desc {
  font-size: 0.875rem;
  margin-bottom: 1.5rem;
  line-height: 1.7;
}

.text {
  margin-bottom: 0.3rem;
}

.field-not {
  width: 100%;
}

.heading,
.text {
  font-size: 0.875rem !important;
}

.inputs {
  gap: 1rem;
  align-items: flex-end;
}

.modal-content {
  border: none;

  .section-1 {
    margin-top: 2rem;
    width: 90%;

    .details-content {
      gap: 1.375rem;
    }

    .grid-items {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 0.5rem;
    }
  }

  .section-2 {
    margin-top: 1.875rem;

    .label {
      margin-bottom: 1rem;
    }
  }

  .section-3 {
    margin-top: 1.875rem;
    width: 100%;

    .label {
      margin-bottom: 1rem;
    }

    .mail-total {
      display: grid;
      grid-template-columns: 1fr 1fr;
      width: 100%;
      margin-top: 1rem;
      gap: 1rem;
    }
  }

  .icon-wrapper {
    gap: 1rem;

    .icon {
      font-size: 2rem;
    }
  }

  .heading {
    margin: 0;
    font-weight: bold;
    font-size: 1.25rem;
  }
}
.modal-foodter {
  gap: 2rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .close {
    margin: 0;
    cursor: pointer;
  }
}
</style>
