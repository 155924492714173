import { camelToUnderscore } from './util';

// add applicationIndex to objects
const addApplicantIndex = (arr) => {
  return (
    [...arr]?.map((i, idx) => {
      return {
        applicantIndex: idx,
        ...i,
      };
    }) || []
  );
};

// Fix state and country
const fixAddress = (arr) => {
  return arr.map((i) => ({
    ...i,
    state: i.state.toLowerCase(),
    country: 'au',
    postCode: i?.postCode?.toString(),
  }));
};

// handle incoming and outgoing payload
const handleIncomingAndOutgoingProperties = (props) =>
  props.map((prop) => {
    const resObj = {
      ...prop,
      salePrice: prop?.salesPrice || undefined,
      soldStatus: camelToUnderscore(prop?.propertyStatus),
      estimatedValuation: prop?.estimatedPropertyPrice,
      mortgageAgainst: prop?.mortageAgainst,
      propertyType: prop?.propertyType || prop?.type,
      contractForSale: prop?.contractForSale,
      foundPropertyToPurchase: prop?.foundPropertyToPurchase,
      line1: prop?.line1,
      line2: prop?.line2,
      city: prop?.city,
      state: prop.state?.toLowerCase(),
      postCode: prop?.postCode?.toString(),
      country: 'au' || prop?.country,
      depositPaidType:
        prop.depositPaidType === 'bond' ? 'deposit_bond' : prop.depositPaidType ? 'cash_payment' : undefined,
      depositAmount: prop?.depositAmount,
      purchasePrice: prop?.purchasePrice,
      settlementDate: prop?.settlementDate,
      stampDutyStatus: prop?.stamp_duty_status,
    };
    if (!resObj.settlementDate) {
      delete resObj.settlementDate;
    }
    return resObj;
  });

//handle funds salaried
const handleFundsSalaried = (funds) =>
  funds.map((fund) => ({
    applicantType: fund.applicantType,
    applicantIndex: fund.applicantType === 'primary' ? 0 : 1,
    jobType: fund.jobType,
    salaryAmount: fund.salaryAmount,
    salaryPackaged: fund.salaryPackaged,
    salaryFrequency: fund.salaryFrequency.toLowerCase() == 'yearly' ? 'annually' : fund.salaryFrequency.toLowerCase(),
    commissionsAmount: fund.commissionsAmount,
    commissionsFrequency:
      fund.commissionsFrequency.toLowerCase() == 'yearly' ? 'annually' : fund.commissionsFrequency.toLowerCase(),
    bonusesAmount: fund.bonusesAmount,
    bonusesFrequency:
      fund.bonusesFrequency.toLowerCase() == 'yearly' ? 'annually' : fund.bonusesFrequency.toLowerCase(),
    overtimeAmount: fund.overtimePayAmount,
    overtimeFrequency:
      fund.overtimePayFrequency.toLowerCase() == 'yearly' ? 'annually' : fund.overtimePayFrequency.toLowerCase(),
    nameOfEmployer: fund.nameOfEmployer,
    employerLine1: fund.employerAddress1,
    employerLine2: fund.employerAddress2,
    employerCity: fund.employerCity,
    employerState: fund.employerState.toLowerCase(),
    employerCountry: 'au',
    postCode: fund.postCode,
    isSalarySacrifice: fund.isSalarySacrifice,
    isTaxable: fund.isTaxable,
  }));

const handleFundsSelfEmployed = (funds) =>
  funds.map((fund) => ({
    ...fund,
    applicantIndex: fund.applicantType === 'primary' ? 0 : 1,
    state: fund?.state?.toLowerCase(),
    country: 'au',
    postCode: fund?.postCode?.toString(),
    salaryFrequency: fund?.selfEmployedFrequency?.toLowerCase(),
    australianBusinessNumber: fund?.aunBusinessNumber,
    salaryAmount: fund?.salary || fund?.salaryAmount,
  }));

// add structure to funds additional income
const handleFundsAdditionalIncome = (addFunds) =>
  addFunds.map((fund) => ({
    applicantType: fund.applicantType,
    applicantIndex: fund.applicantType === 'primary' ? 0 : 1,
    centerlinkPayments: fund.centerlinkPayments,
    centerlinkPaymentsFrequency: fund.centerlinkPaymentsFrequency.toLowerCase(),
    childSupportMaintenance: fund.childSupportMaintenance,
    childSupportMaintenanceFrequency: fund.childSupportMaintenanceFrequency.toLowerCase(),
    familyAllowance: fund.familyAllowance,
    familyAllowanceFrequency: fund.familyAllowanceFrequency.toLowerCase(),
    familyTaxBenefits: fund.familyTaxBenefits,
    familyTaxBenefitsFrequency: fund.familyTaxBenefitsFrequency.toLowerCase(),
    governmentPension: fund.governmentPension,
    governmentPensionFrequency: fund.governmentPensionFrequency.toLowerCase(),
    dividends: fund.dividends,
    superannuation: fund.super,
    superannuationFrequency: fund.superFrequency.toLowerCase(),
    other: fund.other,
    otherFrequency: fund.otherFrequency.toLowerCase(),
    dividendsFrequency: fund.dividendsFrequency.toLowerCase(),
  }));

// to remove undefined values in expenses
const handleExpenses = (expenses) =>
  expenses
    .filter((expense) => expense.expenseType != 'undefined')
    .map((expense) => ({
      ...expense,
      applicantIndex: expense.applicantType === 'primary' ? 0 : 1,
    }));

const handleAssets = (assets) =>
  assets.map((asset) => ({
    ...asset,
    applicantIndex: asset.applicantType === 'primary' ? 0 : 1,
  }));

// to format and add required fields and stuff for propertyOwnership
const handlePropertyOwnership = (shares) => {
  const resArr = [];

  shares.forEach((share) => {
    resArr.push({
      outgoingPropertyIndex: share.type === 'outgoing' ? share.index : -1,
      incomingPropertyIndex: share.type === 'incoming' ? share.index : -1,
      direction: share.type,
      applicant: 'primary',
      applicantIndex: 0,
      share: share.app_1_share,
    });
    if (share.app_2_share) {
      resArr.push({
        direction: share.type,
        outgoingPropertyIndex: share.type === 'outgoing' ? share.index : -1,
        incomingPropertyIndex: share.type === 'incoming' ? share.index : -1,
        applicant: 'secondary',
        applicantIndex: 1,
        share: share.app_2_share,
      });
    }
  });

  return resArr;
};

// to format and add required fields and stuff
const handleApplicants = (applicants, wholeData) =>
  applicants.map((applicant, idx) => {
    let data = {
      ...applicant,
      changesInLivingExpense: applicant.changesInLivingExpense || undefined,
      applicantIndex: idx,
      isRetired: false,
      isUnemployed: false,
      totalExpenses: 0,
      expenseDecreaseMonthlyAmount: 0,
      expenseIncreaseMonthlyAmount: 0,
      otherInfo: '',
      state: applicant?.state?.toLowerCase(),
      country: 'au',
      postCode: applicant?.postCode?.toString(),
      relationshipStatus: applicant.relationship,
      firstName: applicant?.firstName?.trim() || '',
      middleName: applicant?.middleName?.trim() || '',
      lastName: applicant?.lastName?.trim() || '',
    };

    const incomeData = wholeData.income.incomeData;

    if (idx == 0 || applicant.applicantType == 'primary') {
      const expense = wholeData.expense.expenseFormData;
      data.totalExpenses = expense?.totalExpenses || 0;
      data.expenseDecreaseMonthlyAmount = expense?.decrease_month || 0;
      data.expenseIncreaseMonthlyAmount = expense?.increase_month || 0;
      data.changesInLivingExpense = expense?.changes_in_living_expenses || undefined;
      data.numberOfDependants = parseInt(expense?.numberOfDependents) || 0;
      data.isRetired = incomeData?.applicant_1_isRetired;
      data.isUnemployed = incomeData?.applicant_1_isUnemployed;
    } else {
      const expense = wholeData.expense2.expenseFormData;

      data.totalExpenses = expense?.totalExpenses || 0;
      data.expenseDecreaseMonthlyAmount = expense?.decrease_month;
      data.expenseIncreaseMonthlyAmount = expense?.increase_month;
      data.changesInLivingExpense = expense?.changes_in_living_expenses || undefined;
      data.numberOfDependants = parseInt(expense?.numberOfDependents) || 0;
      data.isRetired = incomeData?.applicant_2_isRetired;
      data.isUnemployed = incomeData?.applicant_2_isUnemployed;
      data.relationshipWithPrimaryApplicant =
        wholeData?.applicant?.applicantData?.applicant_2_relationship_with_applicant_1;
    }

    return data;
  });

const generateDeclarations = () => {
  const types = [
    'bankrupt',
    'shareholder',
    'court',
    'obligations',
    'information',
    'personal',
    'identity',
    'disclosure',
  ];
  const result = types.map((type) => ({
    declarationType: 'agreement',
    declarationDescription: type,
    accepted: true,
    applicantType: 'primary',
    applicantIndex: 0,
  }));
  return result;
};

const handleAssetProperty = (properties) =>
  properties.map((props) => ({
    ...props,
    mortgageLimit: props?.mortgage_limit,
    applicantIndex: props?.applicantType === 'primary' ? 0 : 1,
  }));

export default function generateApplicationSubmitPayload(applicationFormData, brokerId) {
  const { buying, selling, applicant, verify, loanDetails, income, expense, assetsLiability } = applicationFormData;

  let resObj = {
    /* initialAmountFinanced: 0, */
    loanPurpose: buying.buyingData.loanPurpose == 'buy_now' ? 'buy_now' : 'equity_release',
    userType: brokerId ? 'broker' : 'customer',
    createdUserType: brokerId ? 'broker' : 'customer',
    propertiesIntendToBuy: buying.buyingData.properties_intend_to_buy,
    propertiesIntendToSell: selling.sellingData.properties_intend_to_sell,
    numberOfApplicants: applicant.applicantData.applicant_details.length,
    applicantType: 'primary',
    /* createdUserId:
			"9c4cdcd7-e586-4fd4-ae96-cbb6f5097d39" || localStorage.getItem("userID"), */
    howYouKnowTechlend: verify.verifyFormData.howYouKnowTechlend || 'broker',
    additionalFunds: loanDetails.loanData.additional_funds,
    incomingProperties: handleIncomingAndOutgoingProperties(buying.buyingData.incomigProperties),
    outgoingProperties: handleIncomingAndOutgoingProperties(selling.sellingData.outgoing_properties),
    fundsSalaried: handleFundsSalaried(income.incomeData.salaried) || [],
    fundsSelfEmployed: handleFundsSelfEmployed(income.incomeData.self_employed) || [],
    fundsAdditionalIncome: handleFundsAdditionalIncome(income.incomeData.additional_income) || [],
    incomeExpectations: addApplicantIndex(income.incomeData.income_expectations) || [],
    employmentDetails: addApplicantIndex(income.incomeData.employment_details) || [],
    applicants: handleApplicants(applicant.applicantData.applicant_details, applicationFormData),
    propertiesOwnership: handlePropertyOwnership(applicant.applicantData.property_ownership),
    fundsExpenses: handleExpenses(expense.expenseData.expenses) || [],
    fundsAssets: handleAssets(assetsLiability.assetLiabilityData.assets) || [],
    assetsProperties: handleAssetProperty(fixAddress(assetsLiability.property)) || [],
    liabilityCards: addApplicantIndex(assetsLiability.cards) || [],
    liabilityVehicles: addApplicantIndex(assetsLiability.vehicles) || [],
    liabilityOthers: addApplicantIndex(assetsLiability.assetLiabilityData.liability_others) || [],

    userDeclarations: generateDeclarations(applicant.applicantData.applicant_details),
    brokerFeeAmount: loanDetails.loanData?.broker_fee_amount || undefined,
    brokerFee: loanDetails.loanData?.broker_fee || undefined,
    reasonForFund: loanDetails?.addditionalFundsData?.loanFormData?.reasonForFunds || undefined,
    requestedLoanAmount:
      loanDetails?.loanData?.borrow_money ||
      loanDetails?.addditionalFundsData?.loanFormData?.reasonForFunds ||
      undefined,
    loanDate: loanDetails?.loanData?.loan_date || undefined,
    reasonForFundDescription: loanDetails?.loanData?.reason_describe || undefined,
  };
  //brokerFeeAmount brokerFee
  return resObj;
}

export const getIncomingPropertyPayloadForServiceCalculator = (incomingPropertyData) => {
  if (!incomingPropertyData) return [];

  return incomingPropertyData.map((property) => ({
    state: property?.state?.toLowerCase(),
    postCode: property?.postCode?.toString(),
    purchasePrice: property?.purchasePrice,
    stampDutyStatus: property?.stamp_duty_status,
    settlementDate: property?.settlementDate,
    depositPaid: property?.depositPaid,
    depositAmount: property?.depositAmount,
    type: property?.propertyType,
  }));
};

export const getOutgoingPropertyPayloadForServiceCalculator = (outgoingPropertyData) => {
  if (!outgoingPropertyData) return [];
  return outgoingPropertyData.map((outgoingProperty) => ({
    state: outgoingProperty?.state?.toLowerCase(),
    postCode: outgoingProperty?.postCode?.toString(),
    estimatedValuation: outgoingProperty.estimatedPropertyPrice || outgoingProperty.salesPrice,
    currentMortgage: outgoingProperty?.outstandingMortgageAmount,
    propertySold: outgoingProperty?.propertyStatus == 'sold' ? 'yes' : 'no',
    depositHeldInTrust: outgoingProperty?.depositHeldInTrust,
    depositAmount: outgoingProperty?.depositAmountReceived,
    type: outgoingProperty?.type,
  }));
};
