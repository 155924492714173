<template>
  <div>
    <div class="input-wrapper">
      <span v-if="icon" class="icon-container"><i :class="icon" class="icons field" /></span>
      <InputNumber
        class="input-field mt-3"
        :placeholder="placeholder"
        :class="inputClass"
        :format="format ? true : false"
        id="integeronly"
        :min="0"
        v-model="value"
        :disabled="disable"
        :style="{
          height: `${height}`,
          width: `${width}`,
          border: `1px solid ${color} !important`,
          color: `${color}`,
        }"
        @input="onInput"
        @keydown="keydown"
        :prefix="prefix"
        :mode="mode"
      />
    </div>
  </div>
</template>
<script>
import InputNumber from 'primevue/inputnumber';

export default {
  name: 'InputNumberField',
  props: [
    'icon',
    'placeholder',
    'label',
    'height',
    'width',
    'border',
    'color',
    'type',
    'labelClass',
    'value',
    'errorMessages',
    'required',
    'inputClass',
    'disable',
    'maxDigit',
    'prefix',
    'format',
    'mode',
  ],
  methods: {
    onInput(value) {
      this.editValue = value.value;
      this.$emit('onInput', value.value);
    },
    keydown(e) {
      let max = this.maxDigit ? this.maxDigit : 8;
      if (this.editValue.toString().length > max) {
        e.preventDefault();
        return false;
      }
    },
  },
  components: { InputNumber },
  watch: {
    editValue() {
      this.$emit('change', this.editValue);
    },
  },
  mounted() {
    this.editValue = this.value;
  },
};
</script>
<style lang="scss" scoped>
.input-wrapper {
  display: flex;
  align-items: center;
}
.input-field {
  border-color: unset;
  outline: 0 none;
  outline-offset: 0;
  box-shadow: unset !important;
  border-radius: unset !important;
}

.icons {
  font-weight: bold !important;
  height: 100%;
  font-size: 1.2rem;
  padding: 10px;
  min-width: 40px;
  // border-right: 1px solid #ced4da;
  border: 1px solid #ced4da;
  margin-top: 1rem;
  background: #fff;
}

:deep(.p-inputtext) {
  border: 1px solid rgba(2, 0, 56, 0.15);
  border-radius: 4px !important;
  width: 100%;
}
:deep(.p-inputtext:enabled:focus) {
  border-color: rgba(2, 0, 56, 0.15);
  border: 1px solid rgba(100, 97, 170, 0.3);
  box-shadow: unset;
}
:deep(.p-inputtext:enabled:hover) {
  border-color: rgba(2, 0, 56, 0.15);
  border: 1px solid rgba(100, 97, 170, 0.3);
  box-shadow: unset;
}
:deep(.p-inputtext:enabled:focus) {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.1rem rgba(2, 0, 56, 0.3) !important;
  border-color: rgba(2, 0, 56, 0.15);
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.required {
  color: red;
}
.error-text {
  font-size: 14px;
}
</style>
