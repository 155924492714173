import { API, TECHLEND_CALCS_URL } from '@/constants.api';
import { _post } from '@/services/crudService';
import {
  getIncomingPropertyPayloadForServiceCalculator,
  getOutgoingPropertyPayloadForServiceCalculator,
} from '@/services/generateApplicationSubmitPayload';

export default {
  namespaced: true,

  state: {
    applicantFormData: {
      number_of_applicants: '',
      applicant_1_relationship: '',
      applicant_2_relationship: '',
      applicant_2_relationship_with_applicant_1: '',
      applicant_2_other_info: '',
      applicant_details: [],
      sellingPropertiesOwnerShip: [],
      buyingPropertiesOwnerShip: [],
    },
    applicantData: {},
    endDebt: 0,
    endDebtObj: {},
  },
  getters: {
    applicantFormData: ({ applicantFormData }) => applicantFormData,
    applicantData: ({ applicantData }) => applicantData,
    sellingPropertiesOwnerShip: ({ sellingPropertiesOwnerShip }) => sellingPropertiesOwnerShip,
    buyingPropertiesOwnerShip: ({ buyingPropertiesOwnerShip }) => buyingPropertiesOwnerShip,
    endDebt: ({ endDebt }) => endDebt,
  },
  mutations: {
    setApplicantState(storeState, { fieldName, value }) {
      storeState.applicantFormData[fieldName] = value;
    },
    setApplicantDetails(storeState, { fieldName, index, value }) {
      let applicantObj = {};
      applicantObj[fieldName] = value;
      storeState.applicantFormData['applicant_details'][index] = {
        ...storeState.applicantFormData['applicant_details'][index],
        ...applicantObj,
      };
    },
    setFinalData(storeState) {
      if (
        storeState.applicantFormData.applicant_details &&
        storeState.applicantFormData.applicant_details.length == 1
      ) {
        storeState.applicantFormData.applicant_details[0].relationship = storeState.applicantFormData
          .applicant_1_relationship
          ? storeState.applicantFormData.applicant_1_relationship
          : '';
      }
      if (
        storeState.applicantFormData.applicant_details &&
        storeState.applicantFormData.applicant_details.length == 2
      ) {
        storeState.applicantFormData.applicant_details[0].relationship = storeState.applicantFormData
          .applicant_1_relationship
          ? storeState.applicantFormData.applicant_1_relationship
          : '';
        storeState.applicantFormData.applicant_details[1].relationship = storeState.applicantFormData
          .applicant_2_relationship
          ? storeState.applicantFormData.applicant_2_relationship
          : '';
      }
      const finalObj = {
        number_of_applicants: storeState.applicantFormData.number_of_applicants,
        applicant_1_relationship: storeState.applicantFormData.applicant_1_relationship,
        applicant_2_relationship: storeState.applicantFormData.applicant_2_relationship,
        applicant_2_relationship_with_applicant_1:
          storeState.applicantFormData.applicant_2_relationship_with_applicant_1,
        applicant_2_other_info: storeState.applicantFormData.applicant_2_other_info,
        applicant_details: [...storeState.applicantFormData.applicant_details],
        property_ownership: [
          ...storeState.applicantFormData.sellingPropertiesOwnerShip,
          ...storeState.applicantFormData.buyingPropertiesOwnerShip,
        ],
        calculated_fields: { ...storeState.endDebtObj },
      };
      storeState.applicantData = finalObj;
    },
    setSellingPropertiesOwnerShip(storeState, payload) {
      storeState.applicantFormData.sellingPropertiesOwnerShip = payload;
    },
    setBuyingPropertiesOwnerShip(storeState, payload) {
      storeState.applicantFormData.buyingPropertiesOwnerShip = payload;
    },
    setAdditonalApplicantEmpty(storeState, numberOfApplicants) {
      storeState.applicantFormData.applicant_details.splice(numberOfApplicants);
    },
    setEndDebt(storeState, payload) {
      storeState.endDebt = payload;
    },
    setEndDebtObj(storeState, payload) {
      let {
        final_score,
        setup_fee,
        qouted_setup_fee,
        loan_amount,
        peak_debt,
        end_debt,
        walvr,
        initial_lvr,
        end_debt_LVR,
        peak_debt_LVR,
        initial_lvr_within_range,
        op_lvr_max,
        ip_lvr_max,
        initial_amount_financed,
        funds_to_complete_purchase,
        valuation_fee,
        interest_on_caps_loan,
        current_mortgage,
        loan_term,
        yield_value,
        savings,
        other_liabilities,
        purchase_property,
        stampDuty,
        deposit_paid,
      } = payload;
      storeState.endDebtObj = {
        final_score: final_score ? final_score : 0,
        setup_fee: setup_fee ? setup_fee : 0,
        qouted_setup_fee: qouted_setup_fee ? qouted_setup_fee : 0,
        loan_amount: loan_amount ? loan_amount : 0,
        peak_debt: peak_debt ? peak_debt : 0,
        end_debt: end_debt ? Number(end_debt) : 0,
        walvr: walvr ? walvr : 0,
        initial_lvr: initial_lvr ? initial_lvr : 0,
        end_debt_LVR: end_debt_LVR ? end_debt_LVR : 0,
        peak_debt_LVR: peak_debt_LVR ? peak_debt_LVR : 0,
        initial_lvr_within_range: initial_lvr_within_range ? initial_lvr_within_range : '',
        op_lvr_max: op_lvr_max ? op_lvr_max : 0,
        ip_lvr_max: ip_lvr_max ? ip_lvr_max : 0,
        initial_amount_financed: initial_amount_financed ? initial_amount_financed : '',
        funds_to_complete_purchase: funds_to_complete_purchase ? funds_to_complete_purchase : '',
        valuation_fee: valuation_fee ? valuation_fee : 0,
        interest_on_caps_loan: interest_on_caps_loan ? interest_on_caps_loan : 0,
        current_mortgage: current_mortgage ? current_mortgage : 0,
        loan_term: loan_term ? loan_term : '0',
        yield_value: yield_value ? yield_value : 0,
        savings: savings ? savings : '0',
        other_liabilities: other_liabilities ? other_liabilities : '0',
        purchase_property: purchase_property ? purchase_property : '0',
        stampDuty: stampDuty ? stampDuty : '0',
        deposit_paid: deposit_paid ? deposit_paid : '0',
        total_savings_to_purchase: savings ? savings : '0',
      };
    },
    setResetState(storeState) {
      storeState.applicantFormData = {
        number_of_applicants: '',
        applicant_1_relationship: '',
        applicant_2_relationship: '',
        applicant_2_relationship_with_applicant_1: '',
        applicant_2_other_info: '',
        applicant_details: [],
        sellingPropertiesOwnerShip: [],
        buyingPropertiesOwnerShip: [],
      };
      storeState.applicantData = {};
      storeState.endDebt = 0;
      storeState.endDebtObj = {};
    },
  },
  actions: {
    setApplicantState({ commit }, payload) {
      commit('setApplicantState', payload);
      commit('setFinalData');
    },
    setApplicantDetails({ commit }, payload) {
      commit('setApplicantDetails', payload);
      commit('setFinalData');
    },
    setSellingPropertiesOwnerShip({ commit }, payload) {
      commit('setSellingPropertiesOwnerShip', payload);
      commit('setFinalData');
    },
    setBuyingPropertiesOwnerShip({ commit }, payload) {
      commit('setBuyingPropertiesOwnerShip', payload);
      commit('setFinalData');
    },
    setAdditonalApplicantEmpty({ commit }, numberOfApplicants) {
      commit('setAdditonalApplicantEmpty', numberOfApplicants);
    },
    setEndDebtObj({ commit }, payload) {
      commit('setEndDebtObj', payload);
    },

    async fetchEndDebt({ state, rootState, commit }) {
      const payload = {
        incomingProperties: getIncomingPropertyPayloadForServiceCalculator(
          rootState.applicationform.buying.buyingFormData.buying_property_details,
        ),
        outgoingProperties: getOutgoingPropertyPayloadForServiceCalculator(
          rootState.applicationform.selling.sellingFormData.selling_property_details,
        ),
        applicants: [...state.applicantFormData.applicant_details].map((applicant) => ({
          ...applicant,
          numberOfDependants: 0,
        })),
        brokerFeeIncludingGST: rootState.applicationform.loanDetails.addditionalFundsData.brokerFee
          ? Number(rootState.applicationform.loanDetails.addditionalFundsData.brokerFee)
          : 0,
        savingsToContributeToPurchase: rootState.applicationform.loanDetails.addditionalFundsData.addditional_funds
          .additionalSavingAmount
          ? Number(rootState.applicationform.loanDetails.addditionalFundsData.addditional_funds.additionalSavingAmount)
          : 0,
        /*  initialAmountFinanced: 0, */
        country: 'au',
        savingsToContributeToEndDebt: 0,
        additionalFundsRequested: rootState.applicationform.loanDetails.addditionalFundsData.loanFormData
          .additional_fund
          ? Number(rootState.applicationform.loanDetails.addditionalFundsData.loanFormData.additional_fund)
          : 0,
        jointApplication:
          state.applicantFormData.applicant_2_relationship_with_applicant_1 &&
          state.applicantFormData.applicant_2_relationship_with_applicant_1 == 'married_or_defacto'
            ? true
            : false,
        interestFreeTerm: 0,
        numberOfDependants: 0,
        fundsAssets: [],
        fundsSalaried: [],
        fundsAdditionalIncome: [],
        fundsSelfEmployed: [],
        additionalFunds: {
          additionalSavingsAmount: 0,
        },
      };
      //want to change 100 to 0
      try {
        const result = await _post(TECHLEND_CALCS_URL + API.CALCULATORS.SERVICE, payload);
        const { end_debt } = result.data?.result;
        commit('setEndDebt', end_debt ? Number(end_debt) : 0);
        commit('setEndDebtObj', result.data.result);
      } catch (err) {
        commit('setEndDebt', 1219115);
      }
    },
  },
};
