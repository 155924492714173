<template>
  <div>
    <Toolbar>
      <template #left>
        <template v-if="customLogo">
          <img v-if="logoNavigate" class="header-toolbar cursor-pointer" :src="customLogo" @click="goToLogin" />
          <img @click="goToLogin" v-else class="header-toolbar" :src="customLogo" />
        </template>
        <template v-else>
          <img
            v-if="logoNavigate"
            class="header-toolbar cursor-pointer"
            src="@/assets/bridgit.svg"
            @click="goToLogin"
          />
          <img @click="goToLogin" v-else class="header-toolbar" src="@/assets/bridgit.svg" />
        </template>
      </template>
      <template #right>
        <div class="header-toolbar flex">
          <img src="@/assets/icons/phone.svg" class="p-mr-3" />
          <!-- <img class="ml-2" src="@/assets/icons/phone-no.svg" alt="" /> -->
          <div class="header-info">
            <p class="phone-info">
              <a :href="`tel:+${phoneInfo.replace(/ /g, '')}`" target="_blank">{{ phoneInfo }}</a>
            </p>
            <span class="week-info">Weekdays 9am - 5:30pm</span>
          </div>
        </div>
      </template>
    </Toolbar>
    <Toast group="bc">
      <template #message>
        <div class="p-d-flex p-flex-column">
          <div class="p-text-center">
            <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
            <h4>Are you sure you would like to exit the application?</h4>
          </div>
          <div class="p-grid p-fluid">
            <div class="p-col-6">
              <Button class="p-button-success" label="Yes" @click="onConfirm" />
            </div>
            <div class="p-col-6">
              <Button class="p-button-secondary" label="No" @click="onReject" />
            </div>
          </div>
        </div>
      </template>
    </Toast>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import Toast from 'primevue/toast';
import Toolbar from 'primevue/toolbar';

export default {
  name: 'ApplicationToolbarComponent',
  components: {
    Toolbar,
    Toast,
  },
  data() {
    return {
      isToast: false,
    };
  },
  props: ['logoNavigate'],
  methods: {
    ...mapActions({ setLogout: 'auth/setLogout' }),
    goToLogin() {
      // this.setLogout({ token: false });
      // history.pushState(null, null, `${location.origin}/login`);
      this.isToast = true;
      this.$toast.add({
        severity: 'warn',
        summary: 'Are you sure?',
        detail: 'Proceed to confirm',
        group: 'bc',
        closable: false,
      });
      // window.open("https://www.bridgit.com.au/", "_self");
      // history.go(1);
      // location.reload();
    },
    onConfirm() {
      this.onReject();
      window.open('https://www.bridgit.com.au/');
    },
    onReject() {
      this.isToast = false;
      this.$toast.removeGroup('bc');
    },
  },
  computed: {
    customLogo() {
      return process.env.VUE_APP_CUSTOM_THEME_LOGO;
    },
    phoneInfo() {
      return process.env.VUE_APP_CUSTOM_THEME_CONTACT ?? '1300 141 161';
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';
.p-toolbar {
  background: $white-color;
  border: unset !important;
  padding: 0;
  padding: 0 1rem;
  height: 80px;
}
@media (max-width: 425px) {
  .header-toolbar {
    width: 130px;
  }
}

.right-col {
  margin: 0 !important;
  padding: 0 !important;
}
.phone-info {
  margin: 0px;
  font-size: 24px;
  color: $secondary-color;
  line-height: 27px;
  a {
    color: $secondary-color !important;
    cursor: pointer;
  }
}
.week-info {
  font-size: 11px;
  color: $secondary-color;
  position: absolute;
}
@media (max-width: 480px) {
  .phone-info a {
    cursor: pointer;
    color: $secondary-color !important;
    pointer-events: auto;
  }
  .phone-info {
    font-size: 14px;
  }
  .week-info {
    font-size: 9px;
    display: none;
  }
  .header-info {
    display: flex;
    align-items: center;
  }
}
</style>
