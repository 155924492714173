<template>
  <div>
    <label class="p-float-label hk-grotest-medium">{{ label }}</label>
    <div class="flex align-items-center">
      <Slider
        v-model="editValue"
        :style="{
          width: `${width}`,
        }"
        @change="onChange"
      />
      <p class="float-value">{{ value }}%</p>
    </div>
  </div>
</template>

<script>
import Slider from 'primevue/slider';
export default {
  components: { Slider },
  props: ['label', 'width', 'value'],
  data() {
    return {
      editValue: 0,
    };
  },
  methods: {
    onChange(value) {
      this.$emit('onChange', value);
    },
  },
  mounted() {
    this.editValue = this.value;
  },
};
</script>

<style lang="scss">
@import '@/styles/global.scss';

.p-slider .p-slider-range {
  background: $primary-color !important;
}

.p-slider .p-slider-handle {
  height: 1.543rem;
  width: 1.543rem;
  background: #ffffff;
  border: 6px solid $primary-color;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.p-slider-horizontal .p-slider-handle {
  top: 10%;
}
.p-slider:not(.p-disabled) .p-slider-handle:hover {
  background: #ffffff;
  border-color: unset;
}
.float-value {
  margin: 0;
}
.flex .align-items-center {
  gap: 1rem;
}
</style>
