<template>
  <div class="loan-details-container" v-if="item">
    <p class="application-num font-size-1 pm-txt-color">Loan #{{ item.loanReferenceNumber }}</p>
    <div class="application-content">
      <div class="application-detail" v-if="item.createdAt">
        <p class="heading lt-txt-color">Submitted On</p>
        <p class="text pm-txt-color">
          {{ formatDate(item.createdAt) }}
        </p>
      </div>
      <div class="application-detail">
        <p class="heading lt-txt-color">Application status</p>
        <p class="text status capital" :class="{ 'inactive-status': buttonText === 'View details' }">
          <span
            v-if="
              item?.applicationStatus === 'cancelled' ||
              item?.loanStatus === 'dropped_off' ||
              item?.loanStatus === 'withdrawn' ||
              item?.loanStatus === 'rejected' ||
              item?.loanStatus === 'on_hold'
            "
            class="color-red"
          >
            Inactive</span
          >
          <span v-else>{{ changeStatus(item.applicationStatus) }}</span>
        </p>
      </div>
      <div v-if="item.calculatedValue?.interestFreeTermMonths > 0" class="application-detail">
        <p class="heading lt-txt-color">Interest free period</p>
        <p class="text pm-txt-color">
          <span>{{ item.calculatedValue?.interestFreeTermMonths }}</span>
        </p>
      </div>
      <div v-else class="application-detail"></div>
      <div class="application-detail">
        <p class="heading lt-txt-color">Total set up fee (%)</p>
        <p class="text pm-txt-color">
          <span v-if="item.calculatedValue && item.calculatedValue?.setupFeeRate">{{
            getTotalSetupFee(item.calculatedValue)
          }}</span
          ><span v-else>-</span>
        </p>
      </div>
      <div v-if="item.calculatedValue?.interestFreeTermMonths > 0" class="application-detail">
        <p class="heading lt-txt-color">Term</p>
        <p class="text pm-txt-color">{{ item.calculatedValue?.interestFreeTermMonths }} months</p>
      </div>
      <div v-else class="application-detail"></div>
      <div class="application-detail">
        <p class="heading lt-txt-color">Loan Amount</p>
        <p class="text pm-txt-color" v-if="item.calculatedValue && item.calculatedValue">
          ${{ Math.round(item.calculatedValue.loanAmount).toLocaleString() }}
        </p>
        <span v-else>-</span>
      </div>
      <div class="application-detail">
        <p class="heading lt-txt-color">Purchase Properties</p>
        <p class="text pm-txt-color">
          <span v-if="item.propertiesIntendToBuy">{{ item.propertiesIntendToBuy }} properties</span
          ><span v-else>-</span>
        </p>
      </div>
      <div class="application-detail">
        <p class="heading lt-txt-color">Properties for sale</p>
        <p class="text pm-txt-color">
          <span v-if="item.propertiesIntendToSell">{{ item.propertiesIntendToSell }} properties</span
          ><span v-else>-</span>
        </p>
      </div>
    </div>
    <div class="footer">
      <router-link :to="aLink ? aLink : ''">
        <Button
          @click="openBasic(item)"
          class="mt-3"
          :label="buttonText"
          :type="item.status === 'cancelled' ? 'outline' : 'primary'"
        />
      </router-link>
    </div>
  </div>
</template>

<script>
import { changeStatusName } from '@/services/statusService';
import { formatDate } from '@/services/format';

export default {
  props: ['buttonText', 'openBasic', 'aLink', 'buttonType', 'item'],
  setup() {
    return {
      formatDate,
    };
  },
  methods: {
    changeStatus(status) {
      return changeStatusName(status);
    },
    getTotalSetupFee(selectedLoanDetails) {
      let setupFee = selectedLoanDetails?.setupFeeRate || 0;
      let brokerFee = selectedLoanDetails?.brokerFee || 0;
      return ((Number(setupFee) + Number(brokerFee)) * 100).toFixed(2);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';

a {
  text-decoration: none;
}

.loan-details-container {
  margin-top: 2rem;
  max-width: 400px;
  padding: 1.5rem 1.5rem 2.5rem 1.5rem;
  border: 1px solid rgba(2, 0, 56, 0.15);
  box-sizing: border-box;
  border-radius: 5px;
  flex-basis: true;
  flex-grow: 1;
  .application-num {
    // font-weight: bold;
    font-size: 1.2rem;
    margin: 0;
    word-break: break-all;
    max-width: 300px;
  }

  .address-line {
    font-size: 0.875rem;
  }

  .application-content {
    margin: 1.5rem 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;

    .application-detail {
      .heading {
        color: #818181;
        margin: 0;
        font-size: 0.875rem;
      }
      .text {
        margin: 0;
        // font-weight: bold;
        font-size: 0.875rem;
      }
      .status {
        color: $primary-color;
      }
      .inactive-status {
        color: $primary-color !important;
      }
    }
  }
  @media screen and (max-width: 810px) {
    width: unset;
  }
}
.color-red {
  color: #ffbcbd;
}
</style>
