import { applicationProgress, applicationSubProgress, utmTags } from '../constant';
import applicant from './applicant';
import assetsLiability from './assetsLiability';
import buying from './buying';
import declaration from './declaration';
import expense from './expense';
import income from './income';
import loanDetails from './loanDetails';
import router from '../../router';
import selling from './selling';
import verify from './verify';
// Broker flow applicant 2
import assetsLiability2 from './assetsLiability2';
import expense2 from './expense2';

export default {
  namespaced: true,

  state: {
    applicationProgress: applicationProgress,
    applicationSubProgress: applicationSubProgress,
    selectedProgressIndex: 0,
    selectedSubProgressIndex: 0,
    applicationProgressPercentage: 11,
    brokerId: '',
    utmTags: utmTags,
  },
  getters: {
    applicationProgress: ({ applicationProgress }) => applicationProgress,
    applicationSubProgress: ({ applicationSubProgress }) => applicationSubProgress,
    selectedProgressIndex: ({ selectedProgressIndex }) => selectedProgressIndex,
    selectedSubProgressIndex: ({ selectedSubProgressIndex }) => selectedSubProgressIndex,
    applicationProgressPercentage: ({ applicationProgressPercentage }) => applicationProgressPercentage,
    brokerId: ({ brokerId }) => brokerId,
  },
  mutations: {
    setProgressIndex(storeState, payload) {
      storeState.selectedProgressIndex = payload;
    },
    setSubProgressIndex(storeState, payload) {
      storeState.selectedSubProgressIndex = payload;
    },
    setApplicationPercentage(storeState, payload) {
      storeState.applicationProgressPercentage = payload;
    },
    resetState(storeState) {
      storeState.applicationProgress = applicationProgress;
      storeState.applicationSubProgress = applicationSubProgress;
      storeState.applicationProgressPercentage = 11;
      storeState.selectedProgressIndex = 0;
      storeState.selectedSubProgressIndex = 0;
      storeState.utmTags = utmTags;
    },
    setBrokerId(storeState, payload) {
      storeState.brokerId = payload ? payload : null;
    },
    setUTMTags(storeState, payload) {
      storeState.utmTags = payload;
    },
    setApplicationSubProgress(storeState, payload) {
      storeState.applicationSubProgress[payload.index1][payload.index2].value = payload.value;
    },
  },
  actions: {
    setProgressIndex({ commit }, payload) {
      commit('setProgressIndex', payload);
    },
    setSubProgressIndex({ commit }, payload) {
      commit('setSubProgressIndex', payload);
    },
    setApplicationPercentage({ commit }, payload) {
      commit('setApplicationPercentage', payload);
    },
    resetState({ commit }) {
      commit('resetState');
      commit('applicationform/buying/setResetState', '', { root: true });
      commit('applicationform/selling/setResetState', '', { root: true });
      commit('applicationform/loanDetails/setResetState', '', { root: true });
      commit('applicationform/applicant/setResetState', '', { root: true });
      commit('applicationform/income/setResetState', '', { root: true });
      commit('applicationform/expense/setResetState', '', { root: true });
      commit('applicationform/assetsLiability/setResetState', '', {
        root: true,
      });
      commit('applicationform/verify/setResetState', '', { root: true });
      commit('applicationform/declaration/setResetState', '', { root: true });
    },
    activeCheck({ state, commit }, payload) {
      if (payload === 100) {
        commit('setApplicationPercentage', 100);
        return;
      }
      let percentage = (payload + 1) * 11;
      commit('setApplicationPercentage', percentage);
      if (!state.applicationProgress[payload].isActive) {
        let activeIndex = 0;
        state.applicationProgress.map((item, index) => {
          if (item.isActive && index < payload) {
            activeIndex = index;
          }
        });
        router.push(state.applicationProgress[activeIndex].path);
      }
    },
    setBrokerId({ commit }, payload) {
      commit('setBrokerId', payload);
    },
    setUTMTags({ commit }, payload) {
      commit('setUTMTags', payload);
    },
    setMobileNumber({ commit }, payload) {
      commit(
        'applicationform/applicant/setApplicantState',
        { fieldName: 'number_of_applicants', value: 1 },
        { root: true },
      );
      let newPropertyArray = Array.apply(null, Array(1)).map(() => {
        return {
          isActive: false,
          isCompleted: false,
          progress: [
            { title: 'Name', isActive: false, isCompleted: false },
            { title: 'Gender', isActive: false, isCompleted: false },
            { title: 'Date of birth', isActive: false, isCompleted: false },
            { title: 'Address', isActive: false, isCompleted: false },
            { title: 'Mobile', isActive: false, isCompleted: false },
            { title: 'Email', isActive: false, isCompleted: false },
          ],
        };
      });
      commit(
        'applicationform/setApplicationSubProgress',
        { value: newPropertyArray, index1: 3, index2: 1 },
        { root: true },
      );
      commit(
        'applicationform/applicant/setApplicantDetails',
        { fieldName: 'email', index: 0, value: payload.email },
        { root: true },
      );
      commit(
        'applicationform/applicant/setApplicantDetails',
        {
          fieldName: 'mobilePhoneNumber',
          index: 0,
          value: payload.mobilePhoneNumber,
        },
        { root: true },
      );
    },
  },
  modules: {
    buying,
    selling,
    loanDetails,
    applicant,
    income,
    expense,
    assetsLiability,
    verify,
    declaration,
    // Broker flow applicant 2
    expense2,
    assetsLiability2,
  },
};
