const LOAN_APP_URL = process.env.VUE_APP_LOAN_APPLICATION_URL;

function redirectToExternalCustomerLoanApp(to) {
  const params = Object.entries(to.query)
    .map(([k, v]) => `${k}=${v}`)
    .join('&');
  const query = params ? '?' + params : '';
  window.location.href = LOAN_APP_URL + query;
}

export const ApplicationRoutes = [
  {
    path: '/application',
    beforeEnter: redirectToExternalCustomerLoanApp,
  },
  {
    path: '/application/:step',
    beforeEnter: redirectToExternalCustomerLoanApp,
  },
  {
    path: '/application/:step/:subStep',
    beforeEnter: redirectToExternalCustomerLoanApp,
  },
  {
    path: '/second-applicant',
    beforeEnter: redirectToExternalCustomerLoanApp,
  },
  {
    path: '/second-applicant/:step',
    beforeEnter: redirectToExternalCustomerLoanApp,
  },
  {
    path: '/second-applicant/:step/:subStep',
    beforeEnter: redirectToExternalCustomerLoanApp,
  },
];

export default ApplicationRoutes;
