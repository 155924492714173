import { API, TECHLEND_BE_URL } from '@/constants.api';
import { _get, _post } from '@/services/crudService';
import { incomingProperties } from '@/services/loanScenarioHelper';

export default {
  namespaced: true,

  state: {
    buyingFormData: {
      loanPurpose: '',
      number_of_property: '',
      buyingPlans: '',
      buying_property_details: [],
    },
    isPreApproval: false,
    buyingData: {},
    showContractForSale: true,
    customerReferalId: '',
    requestedAppId: '',
    isFromCRM: false,
  },
  getters: {
    buyingFormData: ({ buyingFormData }) => buyingFormData,
    propertyId: ({ propertyId }) => propertyId,
    buyingData: ({ buyingData }) => buyingData,
    isPreApproval: ({ isPreApproval }) => isPreApproval,
    showContractForSale: ({ showContractForSale }) => showContractForSale,
    customerReferalId: ({ customerReferalId }) => customerReferalId,
    requestedAppId: ({ requestedAppId }) => requestedAppId,
    isFromCRM: ({ isFromCRM }) => isFromCRM,
  },
  mutations: {
    setBuyingState(storeState, { fieldName, value }) {
      storeState.buyingFormData[fieldName] = value;
    },
    setPropertyDetails(storeState, { fieldName, index, value }) {
      let propertyObj = {};
      propertyObj[fieldName] = value;
      storeState.buyingFormData['buying_property_details'][index] = {
        ...storeState.buyingFormData['buying_property_details'][index],
        ...propertyObj,
      };
    },
    setPropertyDetailsReset(storeState) {
      storeState.buyingFormData.number_of_property = 0;
      storeState.buyingFormData.buying_property_details = [];
    },
    setAddressEmpty(storeState, index) {
      let propertyObj = {
        line1: '',
        line2: '',
        city: '',
        state: '',
        country: '',
        postCode: 0,
        zone: '',
        premium: false,
        category: '',
      };
      storeState.buyingFormData['buying_property_details'][index] = {
        ...storeState.buyingFormData['buying_property_details'][index],
        ...propertyObj,
      };
    },
    setFinalData(storeState) {
      const finalObj = {
        loanPurpose: storeState.buyingFormData.loanPurpose,
        properties_intend_to_buy: Number(storeState.buyingFormData.number_of_property),
        incomigProperties: storeState.buyingFormData.buying_property_details,
      };
      storeState.buyingData = finalObj;
    },
    setIsPreApproval(storeState, payload) {
      storeState.isPreApproval = payload;
    },
    setShowContractForSale(storeState, payload) {
      storeState.showContractForSale = payload;
    },
    setPrePopulatedData(storeState, payload) {
      storeState.buyingFormData.number_of_property = payload.incomigProperties.length;
      storeState.buyingFormData.buying_property_details = payload.incomigProperties;
    },
    setAdditonalPropertyEmpty(storeState, numberOfProperty) {
      storeState.buyingFormData.buying_property_details.splice(numberOfProperty);
    },
    setReferalCustomerID(storeState, payload) {
      storeState.customerReferalId = payload;
    },
    setRequestedAppId(storeState, payload) {
      storeState.requestedAppId = payload.id;
      storeState.isFromCRM = payload.isCRM;
    },
    setResetState(storeState) {
      (storeState.buyingFormData = {
        loanPurpose: '',
        number_of_property: '',
        buyingPlans: '',
        buying_property_details: [],
      }),
        (storeState.isPreApproval = false),
        (storeState.buyingData = {}),
        (storeState.showContractForSale = true),
        (storeState.customerReferalId = ''),
        (storeState.requestedAppId = ''),
        (storeState.isFromCRM = false);
    },
  },
  actions: {
    setBuyingState({ commit }, payload) {
      commit('setBuyingState', payload);
      commit('setFinalData');
    },
    setPropertyDetails({ commit }, payload) {
      commit('setPropertyDetails', payload);
      commit('setFinalData');
    },
    setPropertyDetailsReset({ commit }) {
      commit('setPropertyDetailsReset');
      commit('setFinalData');
    },
    setAddressEmpty({ commit }, payload) {
      commit('setAddressEmpty', payload);
      commit('setFinalData');
    },
    async fetchZoneByPostal(storeState, payload) {
      const res = await _get(`${TECHLEND_BE_URL}post_code?post_code=${payload}`);
      const { result } = res.data;
      return {
        zone: result?.sp_category.replace('-', '_').toLowerCase(),
        premium: result.premium ? result.premium : false,
        category: result.category ? result.category : '',
        region: result.region ? result.region : '',
        high_density_area: result.high_density_area ? (result.high_density_area == 'true' ? true : false) : false,
      };
    },
    async fetchStampDuty(storeState, payload) {
      const res = await _post(`${TECHLEND_BE_URL}find-stamp-duty`, payload);
      const { result } = res.data;
      return result ? result : '0';
    },
    setIsPreApproval({ commit }, payload) {
      commit('setIsPreApproval', payload);
    },
    setShowContractForSale({ commit }, payload) {
      commit('setShowContractForSale', payload);
    },
    async getGeneratedApplication({ commit }, payload) {
      try {
        const { data } = await _get(`${TECHLEND_BE_URL}get-by-id?id=${payload}`);
        if (data.length) {
          const applicationJSON = data[0].data;
          let parsedData = JSON.parse(applicationJSON);
          //Setting contract for sale value from Broker portal based on stamp duty
          if (parsedData && parsedData.incomigProperties) {
            parsedData.incomigProperties = parsedData.incomigProperties.map((item, index) => {
              item.contractForSale = item.stamp_duty_status == 'already_paid';
              item.purchase_property_selected = item.postCode_area_looking ? false : true;
              commit('setShowContractForSale', item.postCode_area_looking ? false : true);
              commit('setIsPreApproval', item.postCode_area_looking ? true : false);
              commit('setPropertyDetails', {
                fieldName: 'contractForSale',
                index: index,
                value: item.stamp_duty_status == 'already_paid',
              });
              return item;
            });
          }
          commit('setPrePopulatedData', parsedData);
          commit('applicationform/selling/setPrePopulatedData', JSON.parse(applicationJSON), { root: true });
          commit('applicationform/loanDetails/setBrokerFeeFromApplication', JSON.parse(applicationJSON), {
            root: true,
          });
          //Setting Loan data in Loan details page
          commit(
            'applicationform/loanDetails/setLoanData',
            {
              fieldName: 'additional_fund',
              value: parsedData.add_funds_requested ? parsedData.add_funds_requested : 0,
            },
            { root: true },
          );
          commit(
            'applicationform/loanDetails/setLoanData',
            {
              fieldName: 'require_add_fund',
              value: parsedData.add_funds_requested && parsedData.add_funds_requested > 0,
            },
            { root: true },
          );
          //Setting Loan data in Additonal funds page
          commit(
            'applicationform/loanDetails/setAdditionalFundsState',
            {
              fieldName: 'additionalSavingAmount',
              value: parsedData.total_savings_to_purchase ? parsedData.total_savings_to_purchase : 0,
            },
            { root: true },
          );
          commit(
            'applicationform/loanDetails/setAdditionalFundsState',
            {
              fieldName: 'hasAdditionalSavings',
              value: parsedData.total_savings_to_purchase && parsedData.total_savings_to_purchase > 0,
            },
            { root: true },
          );
          //Setting applicant details
          commit(
            'applicationform/applicant/setApplicantState',
            {
              fieldName: 'number_of_applicants',
              value: parsedData.applicant_details && parsedData.applicant_details[1].dob ? 2 : 1,
            },
            { root: true },
          );
          commit(
            'applicationform/applicant/setApplicantState',
            {
              fieldName: 'applicant_1_relationship',
              value:
                parsedData.income_and_expenses && parsedData.income_and_expenses.applicant_1_relationship
                  ? parsedData.income_and_expenses.applicant_1_relationship
                  : '',
            },
            { root: true },
          );
          commit(
            'applicationform/applicant/setApplicantState',
            {
              fieldName: 'applicant_2_relationship',
              value:
                parsedData.income_and_expenses && parsedData.income_and_expenses.applicant_2_relationship
                  ? parsedData.income_and_expenses.applicant_2_relationship
                  : '',
            },
            { root: true },
          );
          commit(
            'applicationform/applicant/setApplicantState',
            {
              fieldName: 'applicant_2_relationship_with_applicant_1',
              value:
                parsedData.income_and_expenses &&
                parsedData.income_and_expenses.applicant_2_relationship_with_applicant_1
                  ? parsedData.income_and_expenses.applicant_2_relationship_with_applicant_1
                  : '',
            },
            { root: true },
          );
          let count = parsedData.applicant_details && parsedData.applicant_details[1].dob ? 2 : 1;
          let newPropertyArray = Array.apply(null, Array(count)).map(() => {
            return {
              isActive: false,
              isCompleted: false,
              progress: [
                { title: 'Name', isActive: false, isCompleted: false },
                { title: 'Gender', isActive: false, isCompleted: false },
                { title: 'Date of birth', isActive: false, isCompleted: false },
                { title: 'Address', isActive: false, isCompleted: false },
                { title: 'Mobile', isActive: false, isCompleted: false },
                { title: 'Email', isActive: false, isCompleted: false },
              ],
            };
          });
          commit(
            'applicationform/setApplicationSubProgress',
            { value: newPropertyArray, index1: 3, index2: 1 },
            { root: true },
          );
          commit(
            'applicationform/applicant/setApplicantDetails',
            {
              fieldName: 'dob',
              index: 0,
              value:
                parsedData.applicant_details && parsedData.applicant_details[0].dob
                  ? parsedData.applicant_details[0].dob
                  : '',
            },
            { root: true },
          );
          commit(
            'applicationform/applicant/setApplicantDetails',
            {
              fieldName: 'age',
              index: 0,
              value:
                parsedData.applicant_details && parsedData.applicant_details[0].age
                  ? parsedData.applicant_details[0].age
                  : '',
            },
            { root: true },
          );
          // set applicant 2 dob and age
          if (parsedData.applicant_details && parsedData.applicant_details[1].dob) {
            commit(
              'applicationform/applicant/setApplicantDetails',
              {
                fieldName: 'dob',
                index: 1,
                value:
                  parsedData.applicant_details && parsedData.applicant_details[1].dob
                    ? parsedData.applicant_details[1].dob
                    : '',
              },
              { root: true },
            );
            commit(
              'applicationform/applicant/setApplicantDetails',
              {
                fieldName: 'age',
                index: 1,
                value:
                  parsedData.applicant_details && parsedData.applicant_details[1].age
                    ? parsedData.applicant_details[1].age
                    : '',
              },
              { root: true },
            );
          }
          commit(
            'applicationform/expense/setExpenseState',
            {
              fieldName: 'numberOfDependents',
              value:
                parsedData.income_and_expenses && parsedData.income_and_expenses.applicant_1_dependants
                  ? parsedData.income_and_expenses.applicant_1_dependants
                  : '',
            },
            { root: true },
          );
          commit(
            'applicationform/expense2/setExpenseState',
            {
              fieldName: 'numberOfDependents',
              value:
                parsedData.income_and_expenses && parsedData.income_and_expenses.applicant_2_dependants
                  ? parsedData.income_and_expenses.applicant_2_dependants
                  : '',
            },
            { root: true },
          );
        }
      } catch (err) {
        return err;
      }
    },
    async getGeneratedCRMApplication({ commit }, payload) {
      try {
        const { data } = await _get(TECHLEND_BE_URL + API.PUBLIC.GET_LOAN_NO_TOKEN + payload);
        if (data.length) {
          const applicationJSON = data.result.applicationData;
          let parsedData = JSON.parse(applicationJSON);
          //Setting contract for sale value from CRM portal based on stamp duty
          if (parsedData && parsedData.incomingProperties) {
            const properties = incomingProperties(parsedData.incomingProperties);
            parsedData.incomingProperties = properties.map((item, index) => {
              item.contractForSale = item.stampDutyStatus == 'already_paid';
              commit('setPropertyDetails', {
                fieldName: 'contractForSale',
                index: index,
                value: item.stampDutyStatus == 'already_paid',
              });
              return item;
            });
          }
          commit('setPrePopulatedData', parsedData);
          commit('applicationform/selling/setPrePopulatedData', JSON.parse(applicationJSON), { root: true });
          //Setting Loan data in Loan details page
          commit(
            'applicationform/loanDetails/setLoanData',
            {
              fieldName: 'additional_fund',
              value: parsedData.add_funds_requested ? parsedData.add_funds_requested : 0,
            },
            { root: true },
          );
          commit(
            'applicationform/loanDetails/setLoanData',
            {
              fieldName: 'require_add_fund',
              value: parsedData.add_funds_requested && parsedData.add_funds_requested > 0,
            },
            { root: true },
          );
          //Setting Loan data in Additonal funds page
          commit(
            'applicationform/loanDetails/setAdditionalFundsState',
            {
              fieldName: 'additionalSavingAmount',
              value: parsedData.total_savings_to_purchase ? parsedData.total_savings_to_purchase : 0,
            },
            { root: true },
          );
          commit(
            'applicationform/loanDetails/setAdditionalFundsState',
            {
              fieldName: 'hasAdditionalSavings',
              value: parsedData.total_savings_to_purchase && parsedData.total_savings_to_purchase > 0,
            },
            { root: true },
          );
        }
      } catch (err) {
        return err;
      }
    },
    setAdditonalPropertyEmpty({ commit }, payload) {
      commit('setAdditonalPropertyEmpty', payload);
    },
    setReferalCustomerID({ commit }, payload) {
      commit('setReferalCustomerID', payload);
    },
    setRequestedAppId({ commit }, payload) {
      commit('setRequestedAppId', payload);
    },
    setBuyingResetState({ commit }) {
      commit('setResetState');
    },
  },
};
