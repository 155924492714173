import { authGuard } from '@/services/Authentication';
import { createRouter, createWebHistory } from 'vue-router';
import ApplicationRoutes from './application';
import CustomerRoutes from './customer';
import JotFormsRoutes from './jotforms';
import Login from '@/views/Login/index.vue';

const routes = [
  {
    path: '/',
    redirect: '/dashboard',
  },
  {
    path: '/login',
    component: Login,
    name: 'Login',
    beforeEnter: authGuard,
  },
  ...CustomerRoutes,
  ...ApplicationRoutes,
  ...JotFormsRoutes,
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
