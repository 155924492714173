<template>
  <div class="progress-steps-wrapper">
    <Spinner v-if="isLoading" class="flex" />
    <div v-else class="progress-steps p-d-flex p-ai-center">
      <div :class="renderDynamicClass(progress.STEP_1)">
        <div class="icon-wrapper">
          <img class="svg-icon" :src="require(`@/assets/icons/app_sub_icon.svg`)" alt="" />
          <img class="svg-icon-active" :src="require(`@/assets/icons/app_sub_active.svg`)" alt="" />
          <img class="svg-icon-true" :src="require(`@/assets/icons/app_sub_true.svg`)" alt="" />
        </div>
        <div class="circle">
          <i v-if="rednerCheckIcon(progress.STEP_1)" class="pi pi-check check"></i>
        </div>
        <p class="text">Loan<br />Funded</p>
      </div>
      <div :class="renderDynamicClass(progress.STEP_2)">
        <div class="icon-wrapper">
          <img class="svg-icon" :src="require(`@/assets/icons/conditonal_off_icon.svg`)" alt="" />
          <img class="svg-icon-active" :src="require(`@/assets/icons/conditional_off_active.svg`)" alt="" />
          <img class="svg-icon-true" :src="require(`@/assets/icons/conditonal_off_true.svg`)" alt="" />
        </div>
        <span class="circle">
          <i v-if="rednerCheckIcon(progress.STEP_2)" class="pi pi-check check"></i>
        </span>
        <p class="text">
          Loan<br />
          repaid
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  props: ['progressStatus', 'getProgress'],
  data() {
    return {
      progress: {
        STEP_1: 'active',
        STEP_2: false,
      },
      isLoading: true,
    };
  },
  computed: {
    ...mapGetters({
      selectedLoanDetails: 'loans/selectedLoanDetails',
      selectedLoanId: 'loans/selectedLoanId',
      selectedTaskId: 'loans/selectedTaskId',
      selectedLoanProgress: 'loans/selectedLoanProgress',
    }),
  },
  methods: {
    ...mapActions({
      fetchLoanProgress: 'loans/fetchLoanProgress',
    }),
    renderDynamicClass(step) {
      if (step === 'active') {
        return 'active stepper';
      } else if (step === true) {
        return 'completed stepper';
      } else {
        return 'stepper';
      }
    },

    rednerCheckIcon(step) {
      return step === true ? true : false;
    },

    clickEvent(index, step) {
      const keys = Object.keys(this.progress);

      keys.forEach((el, idx) => {
        if (idx == index - 1) {
          this.progress[step] = 'active';
        }
        if (idx < index - 1) {
          this.progress[el] = true;
        } else if (idx > index - 1) {
          this.progress[el] = false;
        }
      });
    },
    emitToParentProgress(status) {
      this.$emit('getProgress', status);
    },
  },
  async created() {
    this.isLoading = true;
    // await this.fetchLoanProgress();
    const status = this.selectedLoanProgress?.status;
    this.$emit('getProgress', status);
    switch (status) {
      case 'loan_funded':
        this.progress.STEP_1 = 'active';
        this.progress.STEP_2 = false;
        break;
      case 'loan_repaid':
        this.progress.STEP_1 = true;
        this.progress.STEP_2 = 'active';
        break;
    }
    this.isLoading = false;
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';

.timeline-container {
  margin-top: 2rem;

  .title {
    font-size: bold;
    color: $primary-text-color;
    font-size: 1.25rem;
  }

  .progress-steps-wrapper {
    margin: 2rem 0 0 0;
    box-sizing: border-box !important;

    .connector {
      background-color: black;
      height: 2px;
      width: 50px;
    }

    .progress-steps {
      box-sizing: border-box !important;
      width: 100%;
      padding: 0 1rem;
      gap: 3rem;
      justify-content: space-between;
      overflow: hidden;

      .stepper {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        align-items: center;
        justify-content: center;
        z-index: 99;
        box-sizing: border-box !important;

        .icon-wrapper {
          .icon {
            font-size: 2rem;
            color: #b1b1b1;
          }
        }

        .circle {
          cursor: pointer;
          width: 25px;
          height: 25px;
          border: 3px solid $light-text-color;
          border-radius: 50%;
          position: relative;
          background-color: $white-color;

          &::after {
            content: '';
            display: block;
            position: absolute;
            width: 50px;
            height: 10px;
            top: 0.5rem;
            left: -15px;
            background-color: white;
            z-index: -1;
          }
        }

        &:not(:last-child) {
          .circle {
            &::after {
              content: '';
              display: block;
              position: absolute;
              width: 50px;
              height: 10px;
              top: 0.5rem;
              left: -15px;
              background-color: white;
              z-index: -1;
            }

            &::before {
              content: '';
              display: block;
              position: absolute;
              width: 650px !important;
              height: 2px;
              left: 2rem;
              top: 0.5rem;
              background-color: #b1b1b1;
              z-index: -1;

              @media screen and (min-width: 1300px) {
                width: 250px;
              }
            }
          }
        }

        &:last-child {
          .circle {
            &::after {
              width: 250px;
              left: -15px;
              background-color: white;
              z-index: -1;
            }
          }
        }

        .text {
          white-space: wrap;
          color: $light-text-color;
          font-size: 1.2rem;
          text-align: center;
        }
      }

      .svg-icon {
        display: block;
      }

      .svg-icon-true,
      .svg-icon-active {
        display: none;
      }

      .active {
        .svg-icon-active {
          display: block;
        }

        .svg-icon {
          display: none;
        }

        .svg-icon-true {
          display: none;
        }
      }

      .completed {
        .svg-icon-active {
          display: none;
        }

        .svg-icon {
          display: none;
        }

        .svg-icon-true {
          display: block;
        }
      }

      .active .text {
        color: $primary-color !important;
        // font-weight: bold;
      }

      .active .circle {
        border: 3px solid $primary-color !important;
      }

      .completed .text {
        color: $primary-text-color !important;
        // font-weight: bold;
      }

      .completed .circle {
        border: 3px solid $primary-text-color !important;
        background-color: $primary-text-color;
        display: flex;
        align-items: center;
        justify-content: center;

        & .check {
          color: $white-color;
          font-weight: bold;
          font-size: 0.8rem;
        }
      }

      .completed .circle {
        &::before {
          background-color: $primary-text-color !important;
        }
      }
    }
  }
}
</style>
