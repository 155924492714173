<template>
  <Card>
    <template #title><div class="module-sub-title">Send us a message</div> </template>
    <template #content>
      <TextField
        placeholder="Subject of your message"
        width="100%"
        label="Subject"
        @onInput="getFormData($event, 'subject')"
        :value="formData.subject"
      />
      <TextArea
        placeholder="Write your message here"
        width="100%"
        label="Message"
        class="mt-3"
        @onInput="getFormData($event, 'body')"
        :value="formData.body"
        rows="4"
      />
      <div @click="save">
        <Button :disabled="isSubmitDisabled()" class="mt-3" label="Send" type="primary" />
      </div>
    </template>
  </Card>
</template>

<script>
import { mapActions } from 'vuex';
import { required } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import Card from 'primevue/card';

export default {
  name: 'MessageComponent',
  components: {
    Card,
  },
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      formData: {
        subject: { required },
        body: { required },
      },
    };
  },
  data() {
    return {
      formData: {
        subject: '',
        body: '',
      },
    };
  },
  methods: {
    ...mapActions({
      sendMessage: 'settings/sendMessage',
    }),
    async save() {
      try {
        if (!this.v$.$invalid) {
          await this.sendMessage({ ...this.formData, userType: 'customer' });
          this.$toast.add({
            severity: 'success',
            detail: 'Message sent successfully',
            summary: 'Success',
            life: 3000,
          });
        }
      } catch (err) {
        if (err.response && err.response.data && err.response.data.message) {
          await this.$toast.add({
            severity: 'error',
            summary: 'Error',
            detail: err.response.data.message,
            life: 3000,
          });
        } else {
          await this.$toast.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Something went wrong',
            life: 3000,
          });
        }
      }
    },
    getFormData(value, fieldName) {
      this.formData[fieldName] = value;
      this.formData = { ...this.formData };
    },
    isSubmitDisabled() {
      return this.v$.$invalid;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';
.p-card {
  box-shadow: unset !important;
  border-radius: 4px !important;
  color: $primary-text-color !important;
  font-family: $font-family !important;
}
:deep(.p-card-title) {
  color: $secondary-color !important;
}
:deep(.p-card-content) {
  font-size: 14px !important;
  color: $secondary-color !important;
  font-weight: 500;
}
</style>
