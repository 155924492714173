/* eslint-disable no-mixed-spaces-and-tabs */
import { API, TECHLEND_BE_URL } from '@/constants.api';
import { PMT } from '@/services/findPMT';
import { _get, _post } from '@/services/crudService';
import generateApplicationSubmitPayload from '@/services/generateApplicationSubmitPayload';

export default {
  namespaced: true,

  state: {
    declarationFormData: [],
    agreementFormData: [],
    discussionFormData: {
      need_discussion: null,
      discussion_msg: '',
    },
    declarationData: {},
    existing_app_confirmation: false,
    hemCalcResult: {},
    comRefResult: {},
    incomeTaxCalcResult: {},
    reload: true,
  },
  getters: {
    declarationFormData: ({ declarationFormData }) => declarationFormData,
    agreementFormData: ({ agreementFormData }) => agreementFormData,
    discussionFormData: ({ discussionFormData }) => discussionFormData,
    declarationData: ({ declarationData }) => declarationData,
    reload: ({ reload }) => reload,
  },
  mutations: {
    setDiscussionFormData(storeState, { fieldName, value }) {
      storeState.discussionFormData[fieldName] = value;
    },
    setDeclarationFormData(storeState, payload) {
      storeState.declarationFormData = payload;
    },
    setAgreementFormData(storeState, payload) {
      storeState.agreementFormData = payload;
    },
    setFinalData(storeState) {
      const finalObj = {
        user_declaration: [...storeState.declarationFormData, ...storeState.agreementFormData],
        need_discusssion: storeState.discussionFormData.need_discussion,
        discussion_msg: storeState.discussionFormData.discussion_msg,
      };
      storeState.declarationData = finalObj;
    },
    setExistingAppConfirmation(storeState, payload) {
      storeState.existing_app_confirmation = payload;
    },
    setHemCalculatorFields(storeState, payload) {
      storeState.hemCalcResult = payload
        ? {
            ...payload[0],
            ...payload[1],
            ...payload[2],
          }
        : {};
    },
    setCommReferenceFields(storeState, payload) {
      storeState.comRefResult = payload;
      storeState.comRefResult.loan_term = storeState.comRefResult.loan_term
        ? storeState.comRefResult.loan_term.toString()
        : '0';
      delete storeState.comRefResult.loan_term;
      storeState.comRefResult.settlement_fee = storeState.comRefResult.settlement_fee
        ? storeState.comRefResult.settlement_fee.toString()
        : '0';
    },
    setResetState(storeState) {
      (storeState.declarationFormData = []),
        (storeState.agreementFormData = []),
        (storeState.discussionFormData = {
          need_discussion: null,
          discussion_msg: '',
        });
      storeState.declarationData = {};
      storeState.existing_app_confirmation = false;
      storeState.hemCalcResult = {};
      storeState.comRefResult = {};
    },
    setIncomeTax(storeState, payload) {
      storeState.incomeTaxCalcResult = payload;
    },
    setReload(storeState, payload) {
      storeState.reload = payload;
    },
  },
  actions: {
    setDeclarationFormData({ commit }, payload) {
      commit('setDeclarationFormData', payload);
      commit('setFinalData');
    },
    setAgreementFormData({ commit }, payload) {
      commit('setAgreementFormData', payload);
      commit('setFinalData');
    },
    setDiscussionFormData({ commit }, payload) {
      commit('setDiscussionFormData', payload);
      commit('setFinalData');
    },
    setExistingAppConfirmation({ commit }, payload) {
      commit('setExistingAppConfirmation', payload);
    },
    async runDecisionSeeker(storeState, payload) {
      try {
        const res = await _post(TECHLEND_BE_URL + `run-decision-seeker-engine?application_id=${payload}`);
        const { data } = res;
        return data?.decision_seeker_result;
      } catch (err) {
        return 'fail';
      }
    },
    async createLoanTask({ rootState }, payload) {
      const { applicationform } = rootState;
      try {
        let reqObj = {
          application_id: payload,
          created_user_type: applicationform.brokerId ? 'broker' : 'customer',
        };
        await _post(`${TECHLEND_BE_URL}insert-application-task-by-id`, reqObj);
      } catch (err) {
        return err;
      }
    },
    async submitApplicationV2({ rootState }, data) {
      const { applicationform } = rootState;
      const payload = generateApplicationSubmitPayload(applicationform);
      if (data.existingApplications && data.existingApplications.length) {
        payload.existingApplications = data.existingApplications;
      }
      let url = TECHLEND_BE_URL + API.AUTH.APPLICATION_SUBMIT;
      if (applicationform.brokerId) {
        url = TECHLEND_BE_URL + API.AUTH.BROKER_APPLICATION_SUBMIT;
      }
      await _post(url, payload);
    },
    async submitApplication({ rootState, state, commit }, payload) {
      const { applicationform } = rootState;
      const { applicant, loanDetails } = applicationform;
      const { applicantData } = applicant;
      const { applicant_details } = applicantData;
      const { addditionalFundsData } = loanDetails;
      let applicant1 = '';
      let applicant2 = '';
      if (applicant_details.length > 0) {
        applicant1 = applicant_details[0];
        applicant2 = applicant_details[1];
      }
      let expensesData = {
        ...applicationform.expense.expenseData,
        ...applicationform?.expense2?.expenseData,
        expenses: [...applicationform.expense.expenseData.expenses, ...applicationform.expense2?.expenseData?.expenses],
      };
      let assetsData = {
        assets: [
          ...applicationform.assetsLiability.assetLiabilityData.assets,
          ...applicationform.assetsLiability2.assetLiabilityData.assets,
        ],
        assets_properties: [
          ...applicationform.assetsLiability.assetLiabilityData.assets_properties,
          ...applicationform.assetsLiability2.assetLiabilityData.assets_properties,
        ],
        liability_cards: [
          ...applicationform.assetsLiability.assetLiabilityData.liability_cards,
          ...applicationform.assetsLiability2.assetLiabilityData.liability_cards,
        ],
        liability_vechicles: [
          ...applicationform.assetsLiability.assetLiabilityData.liability_vechicles,
          ...applicationform.assetsLiability2.assetLiabilityData.liability_vechicles,
        ],
        liability_others: [
          ...applicationform.assetsLiability.assetLiabilityData.liability_others,
          ...applicationform.assetsLiability2.assetLiabilityData.liability_others,
        ],
      };
      // console.log('Customer referal id', applicationform.buying.customerReferalId)
      const reqObj = {
        ...applicationform.buying.buyingData,
        ...applicationform.selling.sellingData,
        ...applicationform.loanDetails.loanData,
        ...applicationform.income.incomeData,
        ...applicationform.applicant.applicantData,
        // ...applicationform.expense.expenseData,
        // ...applicationform.assetsLiability.assetLiabilityData,
        ...expensesData,
        ...assetsData,
        ...applicationform.verify.verifyData,

        ...applicationform.declaration.declarationData,
        referredBy: applicationform.buying.customerReferalId ? applicationform.buying.customerReferalId : '',
        created_user_id: applicationform.buying.requestedAppId ? applicationform.buying.requestedAppId : '',
        existing_app_confirmation: state.existing_app_confirmation,
        created_user_type: applicationform.buying.isFromCRM ? 'crm' : applicationform.brokerId ? 'broker' : 'customer',
        applicant_1_sign: payload.signature ? payload.signature : '',
        primary_applicant_mobile: applicant1 && applicant1.mobilePhoneNumber ? applicant1.mobilePhoneNumber : '',
        secondary_applicant_mobile: applicant2 && applicant2.mobilePhoneNumber ? applicant2.mobilePhoneNumber : '',
        isPreApproval: applicationform.buying.isPreApproval,
        deposit: 900,
        legal_fees: 1650,
        disbursement_fees: 115,
        pexa_fees: 115,
        settlement_buffer: 5000,
        utm_tags: applicationform.utmTags,
      };
      reqObj.calculated_fields = {
        ...reqObj.calculated_fields,
        ...state.hemCalcResult,
        ...state.comRefResult,
        ...state.incomeTaxCalcResult,
        ...addditionalFundsData.loanCalcFields,
        loan_amount: addditionalFundsData.loan_amount,
        transaction_date:
          applicationform.buying.buyingData.incomigProperties &&
          applicationform.buying.buyingData.incomigProperties[0] &&
          applicationform.buying.buyingData.incomigProperties[0].settlementDate
            ? applicationform.buying.buyingData.incomigProperties[0].settlementDate
            : '',
      };
      if (reqObj.applicant_2_relationship_with_applicant_1 == 'married_or_defacto') {
        reqObj.applicant_2_dependents = reqObj.applicant_1_dependents;
      }

      if (reqObj.created_user_type == 'broker') {
        reqObj.mobile_no = applicationform.verify.mobile_no;
      }
      if (reqObj.loan_date) {
        reqObj.settlementDate = reqObj.loan_date;
      } else {
        reqObj.settlementDate =
          reqObj.incomigProperties && reqObj.incomigProperties.length && reqObj.incomigProperties[0].settlementDate;
      }
      commit('setReload', true);
      return await _post(TECHLEND_BE_URL + 'application-submit', reqObj);
    },
    async runHemCalculator({ rootState, commit, state }) {
      const commReference = await _get(TECHLEND_BE_URL + 'common_references');
      commit('setCommReferenceFields', commReference.data && commReference.data.length ? commReference.data[0] : {});
      // const applicant_1_credit_card_limit = rootState?.applicationform?.assetsLiability?.cards.map(item => item.creditLimit).reduce((prev, next) => prev + next, 0);
      // const applicant_1_other_mortgage_repayment_limit = rootState?.applicationform?.assetsLiability?.property.map(item => item.mortgage_limit).reduce((prev, next) => prev + next, 0);
      // const applicant_1_other_loan_repayment_limit = rootState?.applicationform?.assetsLiability?.loans.map(item => item.monthlyRepayment).reduce((prev, next) => prev + next, 0);
      // DTI_limits.push({
      //     credit_card_limit: applicant_1_credit_card_limit,
      //     mortgage_repayment_limit: applicant_1_other_mortgage_repayment_limit,
      //     other_loan_repayment_limit: applicant_1_other_loan_repayment_limit,
      //     close_liability:false,
      //     applicantType: 'primary',
      // })

      // Function to find anual income
      let salaried = rootState?.applicationform?.income?.incomeData?.salaried
        ? [...rootState.applicationform.income.incomeData.salaried]
        : [];
      let app_1_income = salaried.reduce((a, b) => {
        if (b.applicantType == 'primary') {
          let amount = 0;
          switch (b.salaryFrequency) {
            case 'Weekly':
              if (b.jobType == 'casual') {
                amount = b.salaryAmount * 46;
              } else {
                amount = b.salaryAmount * 52;
              }
              break;
            case 'Fortnightly':
              amount = b.salaryAmount * 26;
              break;
            case 'Monthly':
              amount = b.salaryAmount * 12;
              break;
            case 'Quarterly':
              amount = b.salaryAmount * 4;
              break;
            case 'Annually':
              amount = b.salaryAmount * 1;
              break;
          }
          return a + parseInt(amount);
        } else {
          return a;
        }
      }, 0);
      let app_2_income = salaried.reduce((a, b) => {
        if (b.applicantType == 'secondary') {
          let amount = 0;
          switch (b.salaryFrequency) {
            case 'Weekly':
              if (b.jobType == 'casual') {
                amount = b.salaryAmount * 46;
              } else {
                amount = b.salaryAmount * 52;
              }
              break;
            case 'Fortnightly':
              amount = b.salaryAmount * 26;
              break;
            case 'Monthly':
              amount = b.salaryAmount * 12;
              break;
            case 'Quarterly':
              amount = b.salaryAmount * 4;
              break;
            case 'Annually':
              amount = b.salaryAmount * 1;
              break;
          }
          return a + parseInt(amount);
        } else {
          return a;
        }
      }, 0);
      // Function to find expenses
      const expensesList = rootState?.applicationform?.expense?.expenseData?.expenses
        ? rootState.applicationform.expense.expenseData.expenses
        : [];
      let monthlyExpenses = expensesList.reduce((a, b) => {
        return a + parseInt(b.amount);
      }, 0);
      // Function to find liability payment
      let liabilityCardAmount = 0,
        liabilityVehicleAmount = 0,
        liabilityOtherAmount = 0,
        assetPropertiesAmount = 0;
      let liabilityCards = rootState?.applicationform?.assetsLiability?.assetLiabilityData?.liability_cards
        ? rootState.applicationform.assetsLiability.assetLiabilityData.liability_cards
        : [];
      liabilityCardAmount = liabilityCards.reduce((a, b) => {
        return a + Number(b.creditLimit * 0.038);
      }, 0);

      let liabilityVehicles = rootState?.applicationform?.assetsLiability?.assetLiabilityData?.liability_vechicles
        ? rootState.applicationform.assetsLiability.assetLiabilityData.liability_vechicles
        : [];
      liabilityVehicleAmount = liabilityVehicles.reduce((a, b) => {
        return a + parseInt(b.monthlyRepayment);
      }, 0);

      let liabilityOthers = rootState?.applicationform?.assetsLiability?.assetLiabilityData?.liability_others
        ? rootState.applicationform.assetsLiability.assetLiabilityData.liability_others
        : [];
      liabilityOtherAmount = liabilityOthers.reduce((a, b) => {
        return a + parseInt(b.monthlyRepayment);
      }, 0);

      let assetProperties = rootState?.applicationform?.assetsLiability?.property
        ? rootState?.applicationform?.assetsLiability?.property
        : [];
      assetPropertiesAmount = assetProperties.reduce((a, b) => {
        return a + parseInt(b.amountOwed);
      }, 0);

      let service_rate =
        commReference.data && commReference.data[0].service_rate ? commReference.data[0].service_rate : 0;
      let tempValue = PMT(
        service_rate / 12,
        rootState.applicationform?.applicant?.endDebtObj?.loan_term
          ? rootState.applicationform.applicant.endDebtObj.loan_term * 12
          : 0,
        -assetPropertiesAmount,
      );

      let payload = {
        applicant_1_annual_income: app_1_income ? app_1_income : 0,
        applicant_2_annual_income: app_2_income ? app_2_income : 0,
        application_expenses: monthlyExpenses ? monthlyExpenses : 0,
        monthly_liability_payment: liabilityCardAmount + liabilityVehicleAmount + liabilityOtherAmount + tempValue,
        applicant_1_age:
          rootState.applicationform?.applicant?.applicantFormData?.number_of_applicants > 0
            ? rootState.applicationform?.applicant?.applicantFormData?.applicant_details[0]?.age
            : 0,
        applicant_2_age:
          rootState.applicationform?.applicant?.applicantFormData?.number_of_applicants > 1
            ? rootState.applicationform?.applicant?.applicantFormData?.applicant_details[1]?.age
            : 0,
        applicant_1_relationship: rootState.applicationform?.applicant?.applicantFormData?.applicant_1_relationship
          ? rootState.applicationform.applicant.applicantFormData.applicant_1_relationship
          : '',
        applicant_2_relationship: rootState.applicationform?.applicant?.applicantFormData?.applicant_2_relationship
          ? rootState.applicationform.applicant.applicantFormData.applicant_2_relationship
          : '',
        applicant_1_dependents: rootState.applicationform.expense.expenseFormData.numberOfDependents
          ? rootState.applicationform.expense.expenseFormData.numberOfDependents
          : '0',
        applicant_2_dependents: rootState.applicationform.expense2.expenseFormData.numberOfDependents
          ? rootState.applicationform.expense2.expenseFormData.numberOfDependents
          : '0',
        savings_end_debt: 0,
        // current_mortgage: 0,
        ip_deposit_amount: rootState.applicationform.buying.buyingFormData.buying_property_details[0]
          ? rootState.applicationform.buying.buyingFormData.buying_property_details[0].depositAmount
          : 0,
        assets_properties: rootState?.applicationform?.assetsLiability?.property
          ? rootState?.applicationform?.assetsLiability?.property
          : [],
        liability_cards: rootState?.applicationform?.assetsLiability?.cards
          ? rootState?.applicationform?.assetsLiability?.cards
          : [],
        liability_others: rootState?.applicationform?.assetsLiability?.loans
          ? rootState?.applicationform?.assetsLiability?.loans
          : [],
        joint_application:
          rootState.applicationform?.applicant?.applicantFormData?.applicant_2_relationship_with_applicant_1 &&
          rootState.applicationform?.applicant?.applicantFormData?.applicant_2_relationship_with_applicant_1 ==
            'married_or_defacto'
            ? true
            : false,
      };
      const outgoing_properties = rootState.applicationform.selling.sellingFormData.selling_property_details.map(
        (obj) => {
          return {
            postCode: obj.postCode,
            country: obj.country.toLowerCase(),
            zone: obj.zone,
            premium: obj.premium,
            category: obj.category,
            mortageAgainst: obj.mortageAgainst,
            outstandingMortgageAmount: obj.outstandingMortgageAmount,
            depositAmount: obj.depositAmount,
            salesPrice: obj.salesPrice,
            estimatedPropertyPrice: obj.estimatedPropertyPrice,
            propertyStatus: obj.propertyStatus,
            depositHeldInTrust: obj.depositHeldInTrust,
            purchasePrice_used: obj.purchasePrice_used,
          };
        },
      );
      payload.outgoing_properties = outgoing_properties;
      payload.current_mortgage = rootState.applicationform?.applicant?.endDebtObj?.current_mortgage
        ? Number(rootState.applicationform.applicant.endDebtObj.current_mortgage)
        : 0;
      payload.end_debt = rootState.applicationform?.applicant?.endDebtObj?.end_debt
        ? Number(rootState.applicationform.applicant.endDebtObj.end_debt)
        : 0;
      payload.implied_loan_term = rootState.applicationform?.applicant?.endDebtObj?.loan_term
        ? rootState.applicationform.applicant.endDebtObj.loan_term
        : '0';
      payload = {
        ...payload,
        ...state.incomeTaxCalcResult,
      };
      const result = await _post(TECHLEND_BE_URL + 'hem-calculator', payload);
      commit('setHemCalculatorFields', result.data && result.data.length ? result.data : {});
    },
    async runIncomeTaxCalculator({ rootState, commit }) {
      const payload = {
        salaried: rootState?.applicationform?.income?.incomeData?.salaried
          ? [...rootState.applicationform.income.incomeData.salaried]
          : [],
        self_employed: rootState?.applicationform?.income?.incomeData?.self_employed
          ? [...rootState.applicationform.income.incomeData.self_employed]
          : [],
        additional_income: rootState?.applicationform?.income?.incomeData?.additional_income
          ? [...rootState.applicationform.income.incomeData.additional_income]
          : [],
      };
      const result = await _post(TECHLEND_BE_URL + 'income-tax-calculator', payload);
      commit('setIncomeTax', result.data);
    },
    setReload({ commit }, payload) {
      commit('setReload', payload);
    },
  },
};
