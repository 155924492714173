import { API, TECHLEND_BE_URL } from '@/constants.api';
import { TOKEN } from '@/constants';
import { _post } from '@/services/crudService';

import { useCookies } from 'vue3-cookies';
const { cookies } = useCookies();

export default {
  namespaced: true,

  state: {
    verifyFormData: {
      mobilePhoneNumber: '',
      where_did_you_find_about_techlend: null,
      verification_code: '',
      token: '',
    },
    verifyData: {},
    mobile_no: '',
  },
  getters: {
    verifyFormData: ({ verifyFormData }) => verifyFormData,
    verifyData: ({ verifyData }) => verifyData,
  },
  mutations: {
    setVerifyState(storeState, { fieldName, value }) {
      storeState.verifyFormData[fieldName] = value;
    },
    setFinalData(storeState) {
      const finalObj = {
        mobile_no: storeState.verifyFormData.mobilePhoneNumber,
        how_you_know_techlend: storeState.verifyFormData.where_did_you_find_about_techlend,
        verification_code: storeState.verifyFormData.verification_code,
      };
      storeState.verifyData = finalObj;
    },
    setResetState(storeState) {
      storeState.verifyFormData = {
        mobile: '',
        where_did_you_find_about_techlend: null,
        verification_code: '',
        token: '',
      };
      storeState.verifyData = {};
    },
    setMobile(storeState, payload) {
      storeState.mobile_no = payload;
    },
  },
  actions: {
    setVerifyState({ commit }, payload) {
      commit('setVerifyState', payload);
      commit('setFinalData');
    },
    async fetchOtp(state, payload) {
      await _post(TECHLEND_BE_URL + API.REGISTER.REQUEST_OTP, payload);
    },
    async verifyOtp({ commit }, payload) {
      const { data } = await _post(TECHLEND_BE_URL + API.REGISTER.VERIFY_OTP, payload);
      commit('setVerifyState', { fieldName: TOKEN, value: data.token });
      cookies.set(TOKEN, data.token);
    },
    async fetchBrokerOtp(state, payload) {
      await _post(TECHLEND_BE_URL + 'send-otp-broker', payload);
    },
    async verifyBrokerOtp({ commit }, payload) {
      const { data } = await _post(TECHLEND_BE_URL + 'verify-otp-broker', payload);
      commit('setVerifyState', {
        fieldName: TOKEN,
        value: data?.token ? data?.token : null,
      });
      cookies.set(TOKEN, data?.token ? data?.token : null);
    },
    async verifyBrokerOtp2({ commit }, payload) {
      const { data } = await _post(TECHLEND_BE_URL + 'broker-gen-token', payload);
      commit('setVerifyState', {
        fieldName: TOKEN,
        value: data?.token ? data?.token : null,
      });
      cookies.set(TOKEN, data?.token ? data?.token : null);
      commit('setMobile', payload.mobilePhoneNumber);
    },
  },
};
