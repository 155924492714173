export default {
  namespaced: true,

  state: {
    expenseFormData: {
      numberOfDependents: '',
      expenses: [],
      decrease_month: 0,
      increase_month: 0,
      changes_in_living_expenses: '',
      totalExpenses: 0,
    },
    expenseData: {
      expenses: [],
      applicant_2_dependents: '0',
      applicant_2_decrease_month: 0,
      applicant_2_increase_month: 0,
      applicant_2_total_expenses: 0,
    },
  },
  getters: {
    expenseFormData: ({ expenseFormData }) => expenseFormData,
    expenseData: ({ expenseData }) => expenseData,
  },
  mutations: {
    setExpenseState(storeState, { fieldName, value }) {
      storeState.expenseFormData[fieldName] = value;
    },
    setFinalData(storeState) {
      const finalObj = {
        applicant_2_dependents: storeState.expenseFormData.numberOfDependents,
        applicant_2_decrease_month: storeState.expenseFormData.decrease_month,
        applicant_2_increase_month: storeState.expenseFormData.increase_month,
        applicant_2_changes_in_living_expenses: storeState.expenseFormData.changes_in_living_expenses,
        expenses: storeState.expenseFormData.expenses,
        applicant_2_total_expenses: storeState.expenseFormData.totalExpenses,
      };
      storeState.expenseData = finalObj;
    },
    setFinalDataEmpty(storeState) {
      storeState.expenseFormData = {
        numberOfDependents: '',
        expenses: [],
        decrease_month: 0,
        increase_month: 0,
        changes_in_living_expenses: '',
      };
      storeState.expenseData = {};
      let obj = {
        expenses: [],
        applicant_2_dependents: '0',
        applicant_2_decrease_month: 0,
        applicant_2_increase_month: 0,
        applicant_2_total_expenses: 0,
      };
      storeState.expenseData = obj;
    },
    setResetState(storeState) {
      storeState.expenseFormData = {
        numberOfDependents: '',
        expenses: [],
        decrease_month: 0,
        increase_month: 0,
        changes_in_living_expenses: '',
        totalExpenses: 0,
      };
      storeState.expenseData = {
        expenses: [],
        applicant_2_dependents: '0',
        applicant_2_decrease_month: 0,
        applicant_2_increase_month: 0,
        applicant_2_total_expenses: 0,
      };
    },
  },
  actions: {
    setExpenseState({ commit }, payload) {
      commit('setExpenseState', payload);
      commit('setFinalData');
    },
    setFinalDataEmpty({ commit }, payload) {
      commit('setFinalDataEmpty', payload);
    },
  },
};
