const currencyFormatter = new Intl.NumberFormat('en-AU', {
  style: 'currency',
  currency: 'AUD',
  minimumFractionDigits: 0,
});

const numberFormatter = new Intl.NumberFormat('en-AU', { minimumFractionDigits: 0 });
const dateFormatter = new Intl.DateTimeFormat('en-AU');

export const formatCurrency = (numVal) => currencyFormatter.format(numVal ?? 0);
export const formatNumber = (numVal) => numberFormatter.format(numVal ?? 0);
export const formatDate = (val) =>
  !val ? '-' : val instanceof Date ? dateFormatter.format(val) : dateFormatter.format(new Date(val));

/**
 * Converts an ENUM value of the format "some_string" to a presentable
 * format "Some string", with a special exception for Contract of Sale
 * abbreviations becoming uppercase
 * @param {*} val
 * @returns
 */
export const fromSnakeCase = (val) => {
  if (!val) return '';
  const sentence = val.replace(/_/g, ' ').trim();
  return (sentence.charAt(0).toUpperCase() + sentence.slice(1)).replace(' cos ', ' COS ');
};
