<template>
  <div class="mt-4">
    <!-- <h1 class="pm-txt-color font-size-2_25 sec-title text hk-grotest-bold">
      My Loans
    </h1> -->
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'index',
};
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';

.text {
  font-size: 2.25rem;
}
</style>
