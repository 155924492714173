<template>
  <div>
    <label class="p-float-label hk-grotest-medium">{{ label }}<span v-if="required" class="required"></span></label>
    <Dropdown
      v-model="selectedValue"
      :options="items"
      :optionLabel="optionLabel"
      :placeholder="placeholder"
      :style="{ width: `${width}`, height: `${height}` }"
      @change="onSelectChange"
      :disabled="disabled"
    />
    <label v-if="errorMessages" class="error-text required bold-400">{{ errorMessages }}</label>
  </div>
</template>

<script>
import Dropdown from 'primevue/dropdown';

export default {
  name: 'Select',
  components: {
    Dropdown,
  },
  props: [
    'width',
    'height',
    'items',
    'value',
    'placeholder',
    'optionLabel',
    'errorMessages',
    'required',
    'label',
    'disabled',
  ],
  data() {
    return {
      selectedValue: '',
    };
  },
  methods: {
    onSelectChange() {
      this.$emit('change', this.selectedValue);
    },
  },
  mounted() {
    this.selectedValue = this.value;
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';
.p-dropdown:not(.p-disabled):hover {
  border: 1px solid rgba(2, 0, 56, 0.3);
}
.p-dropdown:not(.p-disabled).p-focus {
  border: 1px solid rgba(2, 0, 56, 0.3);
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.1rem rgba(2, 0, 56, 0.3) !important;
  border-color: rgba(2, 0, 56, 0.15);
}
.p-dropdown {
  border: 1px solid rgba(2, 0, 56, 0.15);
}
::v-deep .p-inputtext:hover {
  border: 0px solid #ced4da !important;
}
.required {
  color: red;
}
.error-text {
  font-size: 14px;
}
</style>
