<template>
  <div class="mt-4">
    <!-- <h1 class="pm-txt-color font-size-2_25 sec-title gr-bold">Settings</h1> -->
    <Card>
      <template #content>
        <div class="flex p-3 pb-0 divider">
          <div
            class="pb-3 h-100 ml-2 cursor-pointer gr-medium"
            @click="selected = 'account'"
            :class="selected === 'account' ? 'selected-class' : ''"
          >
            Account overview
          </div>
          <div
            class="ml-6 h-100 pb-3 cursor-pointer gr-medium"
            @click="selected = 'payment'"
            :class="selected === 'payment' ? 'selected-class' : ''"
          >
            Payment details
          </div>
        </div>
        <div v-if="selected === 'account'">
          <AccountInformation class="p-4 pt-3" />
        </div>
        <PaymentSettings v-else class="pt-3" />
      </template>
    </Card>
    <div v-if="selected === 'account'">
      <NotificationSettings class="mt-4" />
    </div>
  </div>
</template>

<script>
import AccountInformation from './AccountInformation.vue';
import Card from 'primevue/card';
import NotificationSettings from './NotificationSettings.vue';
import PaymentSettings from './PaymentInformation.vue';

export default {
  name: 'index',
  components: {
    AccountInformation,
    NotificationSettings,
    Card,
    PaymentSettings,
  },
  data() {
    return {
      selected: 'account',
    };
  },
  created() {
    if (this.$route.query && this.$route.query.payment) {
      this.selected = 'payment';
    }
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';
:deep(.p-card-body) {
  padding: unset !important;
}
.p-card {
  box-shadow: unset !important;
  border-radius: 4px !important;
  color: $primary-text-color !important;
  font-family: $font-family !important;
  font-size: 1.4rem !important;
}
:deep(.p-card-title) {
  font-size: 1.25rem !important;
}
.title {
  color: rgba(2, 0, 56, 0.3);
}
.flex-column {
  flex-direction: column;
}
:deep(.p-card-content) {
  font-weight: 500;
  color: rgba(2, 0, 56, 0.3);
}
.selected-class {
  color: $primary-color;
  border-bottom: 3px solid $primary-color;
}
.divider {
  border-bottom: 2px solid rgba(2, 0, 56, 0.15);
}
.h-100 {
  height: 100%;
}
</style>
