import DischargeAuthority from '@/views/jotforms/DischargeAuthority/index.vue';
import IncomeAndExpenses from '@/views/jotforms/IncomeAndExpenses/index.vue';

export const JotFormsRoutes = [
  {
    path: '/discharge-authority',
    component: DischargeAuthority,
    name: 'DischargeAuthority',
  },
  {
    path: '/income-and-expenses',
    component: IncomeAndExpenses,
    name: 'IncomeAndExpenses',
  },
];

export default JotFormsRoutes;
