<template>
  <div class="">
    <div class="summary-container box-shadow">
      <div class="border-b">
        <div class="header-wrapper">
          <div class="header std-padding-x p-d-flex p-ai-center">
            <h3 @click="changingTab('Summary')" class="title active">Summary</h3>
          </div>
        </div>

        <div class="content std-padding-x">
          <Summary />
        </div>
      </div>
    </div>

    <div class="footer contact">
      <p>
        Do you want to resubmit this application?
        <span @click="navigateTo('/contact')" class="cursor-pointer color-red" :class="{}">Get in touch</span>
      </p>
    </div>
  </div>
</template>

<script>
import Summary from '../Progress-Summary/Summary/index.vue';

export default {
  components: { Summary },
  data() {
    return {
      isInactive: false,
    };
  },
  methods: {
    navigateTo(path) {
      this.$router.push(path);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';

.border-b {
  border-bottom: 1px solid $light-text-color;
}
.color-red {
  color: red !important;
}
.std-padding-x {
  padding: 0 1.875rem;
}

.content {
  padding-bottom: 1rem;
}

.summary-container {
  background-color: $white-color;
  // display: inline-block;

  .header-wrapper {
    margin: 0;
    padding: 0;
    border-bottom: 1px solid #02003826;

    .header {
      gap: 2rem;

      .title {
        color: #adadad;
        cursor: pointer;
        font-size: 1.4rem;
        margin: 0;
        padding: 1.5rem 0;
      }
      .active {
        color: $primary-color;
        border-bottom: 3px solid $primary-color;
        font-weight: bold;
      }
    }
  }
}
.footer {
  background-color: $white-color;
  padding: 1.875rem;

  p {
    color: $light-text-color;
    margin: 0;

    span {
      color: #ffbcbd !important;
    }
  }
}
</style>
