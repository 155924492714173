<template>
  <div>
    <Spinner v-if="isLoading" class="flex" />
    <div v-else class="content">
      <div class="sec-wrapper" :class="disableFooter ? '' : 'padding-bottom-1'">
        <div class="icon-wrapper p-d-flex p-ai-center">
          <div style="color: var(--primary-color)">
            <SvgIcon name="loan_summary_icon" size="1.75rem" />
          </div>
          <p class="sec-title pm-txt-color gr-bold">Loan Summary</p>
        </div>
        <div class="section-1">
          <div class="grid-items">
            <div class="wrap">
              <p class="heading lt-txt-color">Loan</p>
              <p class="text pm-txt-color">
                {{ selectedLoanDetails.loanReferenceNumber }}
              </p>
            </div>
            <div v-if="selectedLoanDetails.calculatedValue?.interestFreeTermMonths > 0" class="wrap">
              <p class="heading lt-txt-color">Interest free period</p>
              <p class="text pm-txt-color">{{ selectedLoanDetails.calculatedValue?.interestFreeTermMonths }} months</p>
            </div>
            <div v-else class="wrap"></div>
            <div class="wrap">
              <p class="heading lt-txt-color">Valuation Fee</p>
              <p class="text pm-txt-color">
                {{ formatCurrency(selectedLoanDetails.calculatedValue.valuationFee) }}
              </p>
            </div>
            <div class="wrap">
              <p class="heading lt-txt-color">Total set-up fee (%)</p>
              <p class="text pm-txt-color">{{ (totalSetup * 100).toFixed(2) }}%</p>
            </div>
            <div class="wrap">
              <p class="heading lt-txt-color">Term</p>
              <p class="text pm-txt-color">{{ selectedLoanDetails.calculatedValue.loanTermMonths }} months</p>
            </div>
            <div class="wrap">
              <template v-if="isPreSettlement && selectedLoanDetails.calculatedValue?.interestFreeTermMonths > 0">
                <p class="heading lt-txt-color">Interest rate</p>
                <p class="text">{{ (interestRate * 100).toFixed(2) }}% after interest free period</p>
              </template>
              <template v-else-if="isPreSettlement">
                <p class="heading lt-txt-color">Interest rate</p>
                <p class="text">{{ (interestRate * 100).toFixed(2) }}%</p>
              </template>
            </div>
            <div class="wrap">
              <p class="heading lt-txt-color">Loan Deposit</p>
              <p class="text pm-txt-color">
                {{ formatCurrency(selectedLoanDetails.calculatedValue.depositAmount) }}
              </p>
            </div>
            <div class="wrap">
              <p class="heading lt-txt-color">Loan Amount</p>
              <p class="text primary-color">
                {{ formatCurrency(selectedLoanDetails.calculatedValue.loanAmount) }}
              </p>
            </div>
          </div>
        </div>
        <div class="section-2">
          <div class="title-icon p-d-flex p-ai-center">
            <div style="color: var(--primary-color)">
              <SvgIcon name="supporting_document" size="1.75rem" />
            </div>
            <p class="sec-title pm-txt-color">Supporting Documents</p>
          </div>
          <div class="p-d-flex p-ai-center table">
            <div class="sec-content">
              <DataTable :value="supportingDocuments" responsiveLayout="scroll">
                <Column field="name" header="Document">
                  <template #body="slotProps">
                    <div class="status-text">
                      {{ slotProps.data.name }}
                    </div>
                  </template>
                </Column>
                <Column field="dueDate" header="Due Date" :style="{ width: '150px' }">
                  <template #body="slotProps">
                    <div class="status-text">
                      {{ new Date(slotProps.data.dueDate).toLocaleDateString() }}
                    </div>
                  </template>
                </Column>
                <Column field="status" header="Status" :style="{ width: '150px' }">
                  <template #body="{ data }">
                    <div
                      class="status-text"
                      :class="{ 'color-highlight': data.status === 'not_yet_started' }"
                      @click="data.status === 'not_yet_started' && openFileUploadModal(data.documentId)"
                    >
                      {{ data.statusDescription }}
                    </div>
                  </template>
                </Column>
              </DataTable>
            </div>
          </div>
        </div>
        <div class="section-3">
          <div class="title-icon p-d-flex p-ai-center">
            <div style="color: var(--primary-color)">
              <SvgIcon name="supporting_document" size="1.75rem" />
            </div>
            <p class="sec-title pm-txt-color">Special Conditions</p>
          </div>
          <div class="p-d-flex p-ai-center table">
            <div class="sec-content">
              <DataTable :value="specialConditions" responsiveLayout="scroll">
                <Column field="condition" header="Condition" />
                <Column field="dueDate" header="Due Date" :style="{ width: '150px' }">
                  <template #body="slotProps">
                    <div class="status-text">
                      {{
                        slotProps.data.dueDate
                          ? new Date(slotProps.data.dueDate).toLocaleDateString('en-GB', {
                              day: '2-digit',
                              month: 'short',
                              year: 'numeric',
                            })
                          : ''
                      }}
                    </div>
                  </template>
                </Column>
                <Column field="statusDescription" header="Status" :style="{ width: '150px' }">
                  <template #body="{ data }">
                    <div class="status-text">
                      {{ data.statusDescription }}
                    </div>
                  </template>
                </Column>
              </DataTable>
            </div>
          </div>
        </div>

        <div class="section">
          <Properties
            :items="selectedLoanDetails.incomingProperties"
            :settlementDate="selectedLoanDetails.settlementDate"
            :isPurchase="true"
            title="Purchase Properties"
          />
          <Properties
            :items="selectedLoanDetails.outgoingProperties"
            :isPurchase="false"
            class="mt-5"
            title="Properties for sale"
          />
        </div>
      </div>
      <FileUpload
        :display="displayFileuploadDialog"
        :openDialog="openBasic"
        :closeDialog="closeBasic"
        :supportingDocumentId="supportingDocumentId"
        @completed="fileUploadCompleted()"
      />
    </div>
    <ConditionalOfferDialog :display="displayConditonalDialog" @closeCondition="closeCondition" :key="conditionKey" />
  </div>
</template>

<script>
import { SPECIAL_CONDITION_STATUS, SUPPORTING_DOCUMENT_STATUS } from '@/constants';
import { formatCurrency, fromSnakeCase } from '@/services/format';
import { mapActions, mapGetters } from 'vuex';
import Column from 'primevue/column';
import ConditionalOfferDialog from '../Progress/ConditionalOfferDialog.vue';
import DataTable from 'primevue/datatable';
import FileUpload from '../Progress/FileUpload.vue';
import Properties from './Properties.vue';
import Screensize from '@/mixins/screensize.mixin';
import SvgIcon from '../../../../../components/common/SvgIcon.vue';

export default {
  components: {
    DataTable,
    Column,
    Properties,
    FileUpload,
    ConditionalOfferDialog,
    SvgIcon,
  },
  mixins: [Screensize],
  props: ['cmp', 'progressStage'],
  setup() {
    return {
      formatCurrency,
      fromSnakeCase,
    };
  },
  data() {
    return {
      displayConditonalDialog: false,
      conditionKey: 0,
      isLoading: true,
      displayFileuploadDialog: false,
      disableFooter: true,
      supportingDocumentId: '',
      selectedTaskId: '',
    };
  },
  computed: {
    ...mapGetters({
      selectedLoanDetails: 'loans/selectedLoanDetails',
      selectedLoanId: 'loans/selectedLoanId',
      tasksList: 'loans/tasksList',
      selectedLoanStatus: 'loans/selectedLoanStatus',
    }),
    isPreSettlement() {
      return !['loan_funded', 'loan_repaid'].includes(this.selectedLoanDetails.applicationStatus);
    },
    totalSetup() {
      return (
        (this.selectedLoanDetails.calculatedValue?.setupFeeRate ?? 0) +
        (this.selectedLoanDetails.calculatedValue?.brokerFee ?? 0)
      );
    },
    supportingDocuments() {
      return this.tasksList.map((task) => {
        return {
          taskId: task.id,
          name: fromSnakeCase(task?.taskTitle) || 'Supporting document',
          status: task.status,
          statusDescription: SUPPORTING_DOCUMENT_STATUS[task.status] ?? fromSnakeCase(task.status),
          dueDate: task?.dueDate,
        };
      });
    },
    specialConditions() {
      return this.selectedLoanDetails.specialConditions.map((sc) => {
        return {
          condition: sc.task?.externalDescription ?? '-',
          status: sc.specialConditionStatus,
          statusDescription:
            SPECIAL_CONDITION_STATUS[sc.specialConditionStatus] ?? fromSnakeCase(sc.specialConditionStatus),
          dueDate: sc.task?.dueDate,
        };
      });
    },
    interestRate() {
      const introRate = this.selectedLoanDetails.assessmentRates.find(
        (rate) => rate.assessmentRateType === 'introductory_variable_interest_rate',
      );
      if (introRate) {
        return introRate?.assessmentRate;
      } else {
        const bridgingRate = this.selectedLoanDetails.assessmentRates.find(
          (rate) => rate.assessmentRateType === 'bridging_variable_interest_rate',
        );
        return bridgingRate?.assessmentRate;
      }
    },
  },
  methods: {
    ...mapActions({
      fetchLoanSummary: 'loans/fetchLoanSummary',
      fetchLoanProgress: 'loans/fetchLoanProgress',
    }),
    toggle(event) {
      this.$refs.menu.toggle(event);
    },
    toggleCmp() {
      this.$emit('toggleCmp');
    },
    openFileUploadModal(documentId) {
      this.documentId = documentId;
      this.displayFileuploadDialog = true;
    },
    openBasic() {
      this.displayFileuploadDialog = true;
    },
    closeBasic() {
      this.displayFileuploadDialog = false;
    },
    async fileUploadCompleted() {
      this.displayFileuploadDialog = false;
      await this.fetchLoanSummary();
    },
    async acceptConditionalOffer() {
      await this.fetchLoanProgress();
      if (this.selectedLoanProgress?.tasks) {
        const list = this.selectedLoanProgress.tasks;
        const task = list.find((obj) => obj.modal_field == 'conditional');
        if (task) {
          this.disableFooter = false;
        } else {
          this.disableFooter = true;
        }
      } else {
        this.disableFooter = true;
      }
    },
    async closeCondition() {
      this.displayConditonalDialog = false;
      await this.acceptConditionalOffer();
    },
  },
  async created() {
    this.isLoading = false;
    try {
      await this.fetchLoanSummary();
    } finally {
      this.isLoading = false;
    }
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';

.content {
  margin-top: 2rem;
}

.padding-bottom-1 {
  padding-bottom: 1rem;
}

.sec-wrapper {
  border-bottom: 1px solid $light-text-color;
}

.footer {
  display: flex;
  justify-content: flex-end;
  padding: 2rem 1rem;
  @media (max-width: 992px) {
    padding: 0;
    justify-content: space-between;
  }
}

.title {
  font-weight: bold;
  color: $black-color;
  font-size: 2.1rem;
}

.desc {
  font-size: 0.875rem;
  margin-bottom: 1.5rem;
  line-height: 2;
}

.text {
  margin-bottom: 0.3rem;
}

.field-not {
  width: 100%;
}

.sec-title {
  font-size: 1.25rem;
  margin: 0;
  font-family: Gordita-medium !important;
}

.second-row {
  margin-top: 1rem;
}

.heading,
.text {
  font-size: 0.875rem !important;
  font-family: Gordita-medium !important;
}

.inputs {
  gap: 1rem;
  align-items: flex-end;
}

.section-1 {
  margin-top: 2rem;
  border-bottom: 1px solid $light-text-color;
  padding-bottom: 1rem;

  .details-content {
    gap: 1.375rem;
  }

  .grid-items {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 0.5rem;
    padding: 0 1rem;
    width: 90%;
    @media (max-width: 992px) {
      grid-template-columns: repeat(2, 1fr);
      width: 100%;
    }
  }
}

.section-2 {
  margin-top: 1.875rem;

  .table {
    width: 100%;
  }

  .title-icon {
    padding: 0 1rem;
    gap: 1rem;
  }
}

.sec-content {
  padding: 0 1rem;
  width: 100%;
}

:deep(.p-datatable .p-datatable-thead > tr > th) {
  color: $light-text-color;
  background-color: #fff;
  font-size: 1rem;
}

:deep(.p-datatable .p-datatable-tbody > tr > td) {
  text-align: left;
  border-bottom: 1px solid $light-text-color;
  color: $primary-text-color;
  font-size: 0.875rem;
}

.supporting-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

.section-3 {
  margin-top: 1.875rem;
  width: 100%;
}

.icon-wrapper {
  gap: 1rem;
  padding: 0 1rem;

  .icon {
    font-size: 2rem;
  }
}

.heading {
  margin: 0;
  // font-weight: bold;
  font-size: 1.25rem;
}
.color-highlight {
  color: $primary-color !important;
  // text-decoration: underline !important;
  cursor: pointer;
}
.back {
  color: rgba(2, 0, 56, 0.4);
}
.status-text {
  text-align: left;
  color: $primary-text-color;
  font-size: 0.875rem;
  text-decoration: none;
}
</style>
