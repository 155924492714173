<template>
  <div class="modal-container">
    <Dialog
      :visible="display"
      :modal="true"
      :style="[isMR ? { width: '85vw' } : { width: '70vw' }]"
      id="conditional-offer-dialog"
    >
      <template #header>
        <h1 class="title pm-txt-color">Accept Conditional Offer</h1>
      </template>
      <div class="modal-content">
        <p class="desc lt-txt-color">
          Based on the information you have provided us, after assessing your financial position we are pleased to
          advise that subject to the conditions outlined in the offer letter, your loan has been conditionally approved.
        </p>
        <p class="desc lt-txt-color">
          In order for us to proceed with a formal credit assessment, you will need to pay us an upfront cost, which
          includes your Loan deposit and Valuation cost. Your Loan Deposit will be credited against the Setup cost on
          settlement.
        </p>
        <p class="desc lt-txt-color">
          Once we receive the Upfront cost, we can proceed with your loan and undertaking a formal credit assessment.
        </p>
        <div class="icon-wrapper p-d-flex p-ai-center">
          <img :src="require('@/assets/icons/loan_summ_icon.svg')" alt="" />
          <p class="heading pm-txt-color">Loan Summary</p>
        </div>
        <div class="section-1">
          <div class="grid-items">
            <div class="wrap">
              <p class="heading lt-txt-color">Loan</p>
              <p class="text pm-txt-color" v-if="selectedLoanDetails.loanReferenceNumber">
                {{ formatPurpose(selectedLoanDetails.loanReferenceNumber) }}
              </p>
            </div>
            <div v-if="selectedLoanDetails.calculatedValue?.interestFreeTermMonths > 0" class="wrap">
              <p class="heading lt-txt-color">Interest free period</p>
              <p class="text pm-txt-color">
                {{ selectedLoanDetails.calculatedValue?.interestFreeTermMonths }}
                months
              </p>
            </div>
            <div v-else class="wrap"></div>
            <div class="wrap">
              <p class="heading lt-txt-color">Valuation cost</p>
              <p class="text pm-txt-color">{{ formatCurrency(valuationCost) }}</p>
            </div>
            <div class="wrap">
              <p class="heading lt-txt-color">Total set-up fee (%)</p>
              <p class="text pm-txt-color">{{ (totalSetupPercentage * 100).toFixed(2) }}%</p>
            </div>
            <div class="wrap">
              <p class="heading lt-txt-color">Term</p>
              <p class="text pm-txt-color">{{ selectedLoanDetails.calculatedValue?.loanTermMonths }} months</p>
            </div>
            <div v-if="selectedLoanDetails.calculatedValue?.interestFreeTermMonths > 0" class="wrap">
              <p class="heading lt-txt-color">Interest rate</p>
              <p class="text pm-txt-color">{{ (interestRate * 100).toFixed(2) }}% after interest free period</p>
            </div>
            <div v-else class="wrap">
              <p class="heading lt-txt-color">Interest rate</p>
              <p class="text pm-txt-color">{{ (interestRate * 100).toFixed(2) }}%</p>
            </div>

            <div class="wrap">
              <p class="heading lt-txt-color">Loan Deposit</p>
              <p class="text pm-txt-color">{{ formatCurrency(loanDeposit) }}</p>
            </div>
            <div class="wrap">
              <p class="heading lt-txt-color">Loan Amount</p>
              <p class="text primary-color">{{ formatCurrency(loanAmount) }}</p>
            </div>
            <div></div>
            <div></div>
            <div></div>
            <div class="wrap">
              <div class="text pm-txt-color">Total</div>
              <InputWithIcon :icon="dollar" type="number" :value="totalCost" disabled />
            </div>
          </div>
        </div>
        <!-- note: stopped using Dialog because it forces the tab key focus the first input -->
        <!-- <Dialog class="payment-modal" :visible="showPaymentForm" modal="true">
          <template #header>
            <h1 class="title pm-txt-color">Payment</h1>
          </template>
          <ConditionalOfferPayment
            :key="paymentKey"
            :total="totalCost"
            :draggable="false"
            @onError="onError"
            @close="closePayment()"
            @completed="paymentCompleted()"
          />
        </Dialog> -->
        <div
          class="payment-modal p-dialogue p-dialog-mask p-component-overlay p-component-overlay-enter"
          :style="(!showPaymentForm ? 'display: none' : '') + '; z-index: 9999'"
        >
          <ConditionalOfferPayment
            :key="paymentKey"
            :total="totalCost"
            @onError="onError"
            @close="closePayment()"
            @completed="paymentCompleted()"
          />
        </div>
      </div>
      <template #footer>
        <div :class="isMR ? '' : 'mr-5'" class="modal-footer">
          <p class="close" @click="closeDialog">Back</p>
          <Button
            data-test-id="proceed-to-payment-button"
            label="Proceed to payment"
            type="primary"
            @click="showPaymentForm = true"
          />
        </div>
      </template>
    </Dialog>
  </div>
</template>

<script>
import { formatCurrency } from '@/services/format';
import { mapGetters } from 'vuex';
import ConditionalOfferPayment from './ConditionalOfferPayment.vue';
import Dialog from 'primevue/dialog';
import Dollar from '@/assets/icons/dollar.svg';
import Screensize from '@/mixins/screensize.mixin';

export default {
  mixins: [Screensize],
  props: ['display', 'openDialog', 'closeDialog'],
  components: { Dialog, ConditionalOfferPayment },
  data() {
    return {
      dollar: Dollar,
      showPaymentForm: false,
      paymentKey: 0,
    };
  },
  computed: {
    ...mapGetters({
      selectedLoanDetails: 'loans/selectedLoanDetails',
    }),
    valuationCost() {
      return this.selectedLoanDetails.calculatedValue?.valuationFee;
    },
    totalSetupPercentage() {
      return (
        this.selectedLoanDetails.calculatedValue?.setupFeeRate +
        (this.selectedLoanDetails.calculatedValue?.brokerFee ?? 0)
      );
    },
    interestRate() {
      const introRate = this.selectedLoanDetails.assessmentRates.find(
        (rate) => rate.assessmentRateType === 'introductory_variable_interest_rate',
      );
      if (introRate) {
        return introRate?.assessmentRate;
      } else {
        const bridgingRate = this.selectedLoanDetails.assessmentRates.find(
          (rate) => rate.assessmentRateType === 'bridging_variable_interest_rate',
        );
        return bridgingRate?.assessmentRate;
      }
    },
    loanDeposit() {
      return this.selectedLoanDetails.calculatedValue?.depositAmount;
    },
    loanAmount() {
      return this.selectedLoanDetails.calculatedValue?.loanAmount;
    },
    totalCost() {
      return this.valuationCost + this.loanDeposit;
    },
  },
  methods: {
    formatPurpose(purpose) {
      const obj = {
        buy_now: 'Buy now',
        renovate: 'Renovate',
        cash_advance: 'Equity release',
        equity_release: 'Equity release',
      };
      return obj[purpose] ? obj[purpose] : purpose;
    },
    formatCurrency(val) {
      return formatCurrency(val);
    },
    closePayment() {
      this.showPaymentForm = false;
      this.paymentKey += 1;
    },
    paymentCompleted() {
      this.showPaymentForm = false;
      this.$emit('closeCondition');
    },
    onError(errorMessage) {
      const { title, details } = errorMessage ?? {};
      this.$toast.add({
        severity: 'error',
        summary: title || 'Error',
        detail: details || 'Something went wrong',
        life: 9000,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';

.title {
  font-weight: bold;
  color: $primary-color;
  font-size: 2.25rem;
}

.desc {
  font-size: 0.875rem;
  margin-bottom: 1.5rem;
  line-height: 1.7;
}

.text {
  margin-bottom: 0.3rem;
}

.field-not {
  width: 100%;
}

.heading,
.text {
  font-size: 0.875rem !important;
}

.inputs {
  gap: 1rem;
  align-items: flex-end;
}

.modal-content {
  border: none;

  .section-1 {
    margin-top: 2rem;
    width: 90%;

    .details-content {
      gap: 1.375rem;
    }

    .grid-items {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 0.5rem;
      @media (max-width: 992px) {
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }

  .section-2 {
    margin-top: 1.875rem;

    .label {
      margin-bottom: 1rem;
    }
  }

  .section-3 {
    margin-top: 1.875rem;
    width: 100%;

    .label {
      margin-bottom: 1rem;
    }

    .mail-total {
      display: grid;
      grid-template-columns: 1fr 1fr;
      width: 100%;
      margin-top: 1rem;
      gap: 1rem;
    }
  }

  .icon-wrapper {
    gap: 1rem;

    .icon {
      font-size: 2rem;
    }
  }

  .heading {
    margin: 0;
    font-weight: bold;
    font-size: 1.25rem;
  }
}
.modal-footer {
  gap: 2rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  @media (max-width: 992px) {
    gap: 0.5rem;
  }
  .close {
    margin: 0;
    cursor: pointer;
  }
}

:global(.payment-modal) {
  width: calc(100vw - 1rem);

  @media (min-width: 640px) {
    width: 600px;
  }
}
</style>
