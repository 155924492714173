<template>
  <div>
    <label class="p-float-label hk-grotest-medium">{{ label }}</label>
    <div class="relative">
      <div class="input-icons">
        <img src="@/assets/icons/location.svg" class="icon img" />
        <input
          autocomplete="off"
          class="input-field"
          placeholder="Type Address here"
          type="text"
          v-model="addressModel"
          v-debounce:500ms="addressAutocomplete"
          @keydown.down="onArrowDown"
          @keydown.up="onArrowUp"
          @keydown.enter="onEnter"
        />
        <div style="height: 2px">
          <ProgressBar v-if="isLoading" mode="indeterminate" style="height: 2px" />
        </div>
      </div>
      <ul v-if="showResults" class="address-list z-10">
        <li
          class="each-address"
          :class="{ 'is-active': index === arrowCounter }"
          v-for="(item, index) in mappedAddresses"
          @click="parseAddress(item, index)"
          :key="item.RecordId"
        >
          <img src="@/assets/icons/location.svg" class="icon img" />
          <p class="">
            {{ item.AddressLine }}, {{ item.Locality }} {{ item.State }}
            {{ item.Postcode }}
          </p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { getDirective } from 'vue-debounce';
import ClickOutside from 'vue-click-outside';
import ProgressBar from 'primevue/progressbar';
import axios from 'axios';

export default {
  props: {
    namespace: {
      type: String,
    },
    id: {
      type: String,
    },
    field: {
      type: String,
    },
    label: {
      type: String,
    },
    placeholder: {
      type: String,
      default: '',
    },
    validationRule: {
      default: '',
    },
    validationMessage: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  components: { ProgressBar },
  computed: {
    mappedAddresses() {
      return (this.addressResults || []).slice(0, 8);
    },
  },
  directives: {
    ClickOutside,
    // Pass in 3 to tell the function you're using vue 3, I'm going to work on improving this in the future
    debounce: getDirective(3),
  },
  data() {
    return {
      addressModel: '',
      addressResults: [],
      showResults: false,
      arrowCounter: -1,
      addressToolTip: {
        content:
          '<h3>No Address Found</h3><br> Just enter the full address. <br><br>For example:<br>12 Smith St, Richmond, VIC 2131',
        trigger: 'click',
      },
    };
  },
  methods: {
    kleberEndpoint(value) {
      return `https://kleber.datatoolscloud.net.au/KleberWebService/DtKleberService.svc/ProcessQueryStringRequest?RequestKey=${'RK-A3E16-85E40-57C19-C5E32-09F23-B19FF-A1E48-D3787'}&OutputFormat=json&AddressLine=${value}&Method=DataTools.Capture.Address.Predictive.AuPaf.SearchAddress`;
    },
    detailedAddress(recordId) {
      return `https://kleber.datatoolscloud.net.au/KleberWebService/DtKleberService.svc/ProcessQueryStringRequest?RequestKey=${'RK-A3E16-85E40-57C19-C5E32-09F23-B19FF-A1E48-D3787'}&OutputFormat=json&RecordId=${recordId}&Method=DataTools.Capture.Address.Predictive.AuPaf.RetrieveAddress`;
    },
    async addressAutocomplete(value) {
      // Hit the Kleber API and store results in `addressResults`
      const { data } = await axios.get(this.kleberEndpoint(value));

      this.addressResults = data.DtResponse.Result;

      // Show results if there are results and text in the input field is not empty
      this.showResults = this.addressResults.length;
    },
    async parseAddress(item, index) {
      // const Uppercase = (string) => {
      //   return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
      // }

      const { RecordId } = this.addressResults[index];

      const { data } = await axios.get(this.detailedAddress(RecordId));

      let {
        StreetNumber1,
        StreetName,
        StreetType,
        Locality,
        State,
        Postcode,
        UnitType,
        UnitNumber,
        LevelType,
        LevelNumber,
        StreetNumberSuffix1,
      } = data.DtResponse.Result[0];
      if (UnitNumber) {
        UnitNumber = UnitNumber + '/';
      }
      const address_1 = `${UnitType} ${UnitNumber}${StreetNumber1}${StreetNumberSuffix1} ${StreetName} ${StreetType}`;
      const address_2 = `${LevelType} ${LevelNumber}`.trim();

      this.$emit('onChange', {
        address_1: address_1,
        address_2: address_2,
        postCode: Postcode,
        city: Locality,
        state: State,
        country: 'Australia',
        unitNumber: UnitNumber,
        streetNumber: StreetNumber1,
        streetType: StreetType,
        streetName: StreetName,
      });
      this.addressModel = `${address_1} ${address_2}, ${Locality}, ${State}, ${'Australia'}, ${Postcode}`;
      this.showResults = false;
    },
    // controls for autocomplete dropdown
    // - 'arrowCounter' determines which item in the list of results should be selected
    onArrowDown() {
      if (this.arrowCounter < this.mappedAddresses.length) {
        this.arrowCounter++;
      }
    },
    onArrowUp() {
      if (this.arrowCounter > 0) {
        this.arrowCounter--;
      }
    },
    onEnter() {
      if (this.arrowCounter >= 0) {
        this.parseAddress('', this.arrowCounter);
        this.showResults = false;
        this.arrowCounter = -1;
      }
    },
    hideResults() {
      this.showResults = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.input-icons .img {
  position: absolute;
}

.p-float-label {
  margin-bottom: 1rem;
}

.input-icons {
  width: 100%;
  margin-bottom: 10px;
  position: relative;
}

.icon {
  padding: 10px;
  min-width: 40px;
}

.input-field {
  width: 100%;
  height: 45px !important;
  padding: 10px;
  padding-left: 40px;
  border-radius: 20px;
  outline: none;
  border: 1px solid #02003826;
  color: #495057 !important;
}

.input-field:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.1rem rgba(2, 0, 56, 0.3) !important;
  border-color: rgba(2, 0, 56, 0.15);
}

.address-list {
  border: 1px solid #02003826;
  width: 100%;
  z-index: 10;
  position: absolute;
  border-radius: 8px;
  background-color: #ffffff;
  list-style: none;
  padding-left: 0px;
}

.each-address {
  display: flex;
  align-items: center;
  margin-top: 8px;
  p {
    margin: 0px;
  }
}

.is-active {
  background-color: #e9ecef;
}
.each-address:hover {
  cursor: pointer;
  background-color: #e9ecef;
}
</style>
