import { API, TECHLEND_BE_URL } from '@/constants.api';
import { _delete, _post } from './crudService';
import axios from 'axios';

/**
 * Submits file information to the backend to create signed URLs used to
 * upload the files to Amazon S3
 * @param {*} taskId
 * @param {*} files
 * @param {*} completeTask
 * @returns
 */
export const uploadSupportingDocuments = async (taskId, files, completeTask) => {
  const { data } = await _post(`${TECHLEND_BE_URL}${API.TASKS.UPLOAD_SUPPORTING_DOCUMENTS}?taskId=${taskId}`, {
    completeTask,
    files: files.map((file) => {
      return {
        fileName: file.name,
        contentType: file.type,
        contentLength: file.size,
      };
    }),
  });
  return data.signedUrls.map((data) => data.signedUrl);
};

/**
 * Uploads a file to Amazon S3 using a signed URL
 * @param {*} signedUrl
 * @param {*} file
 * @param {*} progressBar
 * @returns
 */
export const uploadFileToS3 = async (signedUrl, file, progressBar) => {
  if (signedUrl === 'string') {
    return true;
  }
  return await axios.put(signedUrl, file, {
    onUploadProgress: (progressEvent) => {
      progressBar.style.width = Math.round(progressEvent.loaded / file.size) * 100 + '%';
    },
    headers: {
      'Content-Type': file.type,
    },
  });
};

/**
 * Removes an unsubmitted file from a supporting document
 * @param {*} documentId
 * @returns
 */
export const deleteSupportingDocument = async (documentId) => {
  const { data } = await _delete(`${TECHLEND_BE_URL}${API.TASKS.DELETE_SUPPORTING_DOCUMENT}?documentId=${documentId}`);
  return data.message;
};
