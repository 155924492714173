<template>
  <div class="contact-container flex justify-content-between align-items-center">
    <Card v-for="item of items" :key="item.id" @click="onSelect(item)" class="mb-3 col cursor-pointer">
      <template #content>
        <div :class="isXS ? 'flex-wrap' : ''" class="flex align-items-center justify-content-between pl-2 pr-2">
          <div :class="isXS ? 'flex-wrap' : ''" class="flex align-items-center">
            <!-- <img width="27" height="27" :src="require(`@/assets/icons/${item.icon}.svg`)" /> -->
            <div class="text-color-primary">
              <SvgIcon :name="item.icon" size="1.75rem" />
            </div>
            <div class="ml-1 font-size-1 pm-txt-color no-wrap">
              {{ item.text }}
            </div>
          </div>
          <img width="11" height="11" :src="require('@/assets/icons/right_arrow_icon.svg')" />
        </div>
      </template>
    </Card>
  </div>
</template>

<script>
import Card from 'primevue/card';
import Screensize from '@/mixins/screensize.mixin';
import SvgIcon from '../../../components/common/SvgIcon.vue';

import { TECHLEND_BROKER_URL } from '@/constants.api';

export default {
  name: 'Contact',
  mixins: [Screensize],
  components: {
    Card,
    SvgIcon,
  },
  data() {
    return {
      // items: ["Send us an email", "Schedule a call with us", "Call us now"],
      items: [
        {
          id: 1,
          icon: 'email_icon',
          text: 'Send us an email',
        },
        {
          id: 2,
          icon: 'calendar_icon',
          text: 'Schedule a call with us',
        },
        {
          id: 3,
          icon: 'phone_icon',
          text: 'Call us now',
        },
      ],
    };
  },
  methods: {
    onSelect(item) {
      if (item.text === 'Schedule a call with us') {
        window.open(`${TECHLEND_BROKER_URL}/schedule-a-call`);
      } else {
        this.$router.push('/contact');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';
.p-card {
  background: #ffffff;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.06) !important;
  border-radius: 8px !important;
  color: $primary-text-color !important;
  font-family: $font-family !important;
  height: max-content !important;
  // width: 250px;
  @media (max-width: 992px) {
    margin: 10px 10px 0px 10px !important;
  }
}
.contact-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 992px) {
    flex-wrap: wrap;
  }
}
:deep(.p-card-body) {
  padding: unset !important;
}
:deep(.p-card-content) {
  font-size: 14px !important;
}

.flex-gap {
  gap: 1rem;
}

.no-wrap {
  white-space: nowrap;
}

.p-card .p-card-content {
  padding: 0 !important;
  width: 100%;
}

.full-width {
  width: 100%;
}
</style>
