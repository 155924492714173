<template>
  <div class="authentication-wrapper">
    <Header />
    <div class="login-screen flex justify-content-center"><Login /></div>
    <Footer />
  </div>
</template>

<script>
import Footer from './Footer.vue';
import Header from './Header.vue';
import Login from './Login.vue';

export default {
  name: 'index',
  components: {
    Header,
    Footer,
    Login,
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';
.login-screen {
  width: 100%;
  margin: 2rem auto;
  // background-image: linear-gradient(#f1f6fb, white);
}
.authentication-wrapper {
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  display: flex;
  background-image: linear-gradient(#f1f6fb, white);
  @media (max-width: 992px) {
    height: 100%;
  }
}
</style>
