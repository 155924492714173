import { API, TECHLEND_BE_URL } from '@/constants.api';
import { _get, _patch, _post, _put } from '@/services/crudService';
import LogRocket from 'logrocket';

const INIT_STATE = {
  Id: '',
  email: '',
  mobilePhoneNumber: '',
  firstName: '',
  middleName: '',
  lastName: '',
  line1: '',
  line2: '',
  city: '',
  state: '',
  country: '',
  postCode: 0,
};

export default {
  namespaced: true,

  state: {
    profileDetails: INIT_STATE,
    notificationsDetails: {
      isStatementNotifications: false,
      isMarketingMails: false,
    },
  },
  getters: {
    profileDetails: ({ profileDetails }) => profileDetails,
  },
  mutations: {
    setProfileDetails(state, payload) {
      state.profileDetails = payload || state.profileDetails;

      try {
        LogRocket.identify(state.profileDetails.id, {
          name: [state.profileDetails.firstName, state.profileDetails.lastName].filter(Boolean).join(' '),
          email: state.profileDetails.email,
        });
      } catch (error) {
        console.error(error);
      }
    },
  },
  actions: {
    async updateNotification(context, payload) {
      await _put(TECHLEND_BE_URL + API.USER.UPDATE_NOTIFICATION_SETTINGS, payload);
    },
    async updateProfile(context, payload) {
      await _patch(TECHLEND_BE_URL + API.USER.UPDATE_PERSONAL_INFORMATION, payload);
    },
    async getCustomerById({ commit }) {
      const result = await _get(TECHLEND_BE_URL + API.USER.CURRENT_USER);
      commit('setProfileDetails', result.data?.applicant ? result.data.applicant : INIT_STATE);
    },
    async updatePaymentDetails(context, payload) {
      await _put(TECHLEND_BE_URL + API.USER.UPDATE_PAYMENT_INFORMATION, payload);
    },
    async sendMessage(context, payload) {
      await _post(TECHLEND_BE_URL + API.CONTACT.SEND_EMAIL, payload);
    },
  },
};
