<template>
  <div>
    <div class="flex mt-3 align-items-center justify-content-between">
      <p class="heading">Loan amount</p>
      <p class="text" v-if="selectedLoanDetails.calculatedValue">
        ${{ Math.round(selectedLoanDetails.calculatedValue.loanAmount).toLocaleString() }}
      </p>
    </div>
    <div class="flex mt-3 align-items-center justify-content-between">
      <p class="heading">Loan term</p>
      <p class="text" v-if="selectedLoanDetails.calculatedValue">
        {{ selectedLoanDetails.calculatedValue.loanTermMonths }} months
      </p>
    </div>
    <div
      v-if="isPreSettlement && selectedLoanDetails.calculatedValue.interestFreeTermMonths > 0"
      class="flex mt-3 align-items-center justify-content-between"
    >
      <p class="heading">Interest rate</p>
      <p
        class="text"
        v-if="selectedLoanDetails.calculatedValue && selectedLoanDetails.calculatedValue.capitalizingLoanRate"
      >
        {{ getFixedValue(selectedLoanDetails.calculatedValue.capitalizingLoanRate) }}% (First
        {{ selectedLoanDetails.calculatedValue.interestFreeTermMonths }} months free)
      </p>
    </div>
    <div v-else-if="isPreSettlement" class="flex mt-3 align-items-center justify-content-between">
      <p class="heading">Interest rate</p>
      <p
        class="text"
        v-if="selectedLoanDetails.calculatedValue && selectedLoanDetails.calculatedValue.capitalizingLoanRate"
      >
        {{ getFixedValue(selectedLoanDetails.calculatedValue.capitalizingLoanRate) }}%
      </p>
    </div>
    <div class="flex mt-3 align-items-center justify-content-between">
      <p class="heading">Total Outstanding Balance</p>
      <p class="text primary-color" v-if="selectedLoanDetails.calculatedValue.outstandingValue">
        ${{ Math.round(selectedLoanDetails.calculatedValue.outstandingValue).toLocaleString() }}
      </p>
      <div v-else>-</div>
    </div>
    <div class="flex mt-3 align-items-center justify-content-between">
      <p class="heading">Date repaid</p>
      <p class="text" v-if="selectedLoanDetails.calculatedValue && selectedLoanDetails.calculatedValue.date_repaid">
        {{ getFormatDate(selectedLoanDetails.calculatedValue.date_repaid) }}
      </p>
      <p class="text" v-else>-</p>
    </div>
  </div>
</template>

<script>
import { formatDate } from '@/services/format';
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      selectedLoanDetails: 'loans/selectedLoanDetails',
      selectedLoanId: 'loans/selectedLoanId',
      selectedTaskId: 'loans/selectedTaskId',
    }),
    isPreSettlement() {
      return !['loan_funded', 'loan_repaid'].includes(this.selectedLoanDetails.applicationStatus);
    },
  },
  props: ['supportingDocumentId'],
  setup() {
    return {
      formatDate,
    };
  },
  methods: {
    getFixedValue(value) {
      return value || '';
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';

.heading {
  font-size: 14px;
  font-family: Gordita;
  color: $light-text-color;
}

.text {
  font-family: Gordita;
  color: $secondary-color;
  font-size: 14px;
}

p {
  margin-bottom: 0;
}
</style>
