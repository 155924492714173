<template>
  <Card>
    <template #content>
      <div>
        <div v-if="specialConditions && specialConditions.length">
          <div class="section-3">
            <div class="title-icon p-d-flex p-ai-center">
              <div style="color: var(--primary-color)">
                <SvgIcon name="supporting_document" size="1.75rem" />
              </div>
              <p class="sec-title pm-txt-color">Special Conditions</p>
            </div>
            <div class="p-d-flex p-ai-center table">
              <div class="sec-content">
                <DataTable :value="specialConditions" responsiveLayout="scroll">
                  <Column field="condition" header="Condition" />
                  <Column field="dueDate" header="Due Date" :style="{ width: '150px' }">
                    <template #body="slotProps">
                      <div class="status-text">
                        {{
                          slotProps.data.dueDate
                            ? new Date(slotProps.data.dueDate).toLocaleDateString('en-GB', {
                                day: '2-digit',
                                month: 'short',
                                year: 'numeric',
                              })
                            : ''
                        }}
                      </div>
                    </template>
                  </Column>
                  <Column field="statusDescription" header="Status" :style="{ width: '150px' }">
                    <template #body="{ data }">
                      <div class="status-text">
                        {{ data.statusDescription }}
                      </div>
                    </template>
                  </Column>
                </DataTable>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div style="height: 217px">
            <p class="lt-txt-color font-size-1 mt-5">No special conditions available.</p>
          </div>
        </div>
      </div>
    </template>
  </Card>
</template>

<script>
import { SPECIAL_CONDITION_STATUS } from '@/constants';
import { fromSnakeCase } from '@/services/format';
import { mapGetters } from 'vuex';
import Card from 'primevue/card';
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import Screensize from '@/mixins/screensize.mixin';
import SvgIcon from '../../../components/common/SvgIcon.vue';

export default {
  name: 'SpecialConditions',
  mixins: [Screensize],
  components: {
    Card,
    DataTable,
    Column,
    SvgIcon,
  },
  computed: {
    ...mapGetters({
      selectedLoanDetails: 'loans/selectedLoanDetails',
    }),
    specialConditions() {
      return (
        this.selectedLoanDetails?.specialConditions?.map((sc) => ({
          condition: sc.task?.externalDescription || 'No description available',
          statusDescription:
            SPECIAL_CONDITION_STATUS[sc.specialConditionStatus] || fromSnakeCase(sc.specialConditionStatus),
          dueDate: sc.task?.dueDate,
        })) || []
      );
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/styles/global.scss';

.content {
  margin-top: 2rem;
}

.padding-bottom-1 {
  padding-bottom: 1rem;
}

.sec-wrapper {
  border-bottom: 1px solid $light-text-color;
}

.title {
  font-weight: bold;
  color: $black-color;
  font-size: 2.1rem;
}

.desc {
  font-size: 0.875rem;
  margin-bottom: 1.5rem;
  line-height: 2;
}

.text {
  margin-bottom: 0.3rem;
}

.field-not {
  width: 100%;
}

.sec-title {
  font-size: 1.25rem;
  margin: 0;
  font-family: Gordita-medium !important;
}

.second-row {
  margin-top: 1rem;
}

.heading,
.text {
  font-size: 0.875rem !important;
  font-family: Gordita-medium !important;
}

.inputs {
  gap: 1rem;
  align-items: flex-end;
}

.sec-content {
  padding: 0 1rem;
  width: 100%;
}

:deep(.p-datatable .p-datatable-thead > tr > th) {
  color: $light-text-color;
  background-color: #fff;
  font-size: 1rem;
}

:deep(.p-datatable .p-datatable-tbody > tr > td) {
  text-align: left;
  border-bottom: 1px solid $light-text-color;
  color: $primary-text-color;
  font-size: 0.875rem;
}

.supporting-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

.section-3 {
  margin-top: 1.875rem;
  width: 100%;
}

.icon-wrapper {
  gap: 1rem;
  padding: 0 1rem;

  .icon {
    font-size: 2rem;
  }
}

.heading {
  margin: 0;
  font-size: 1.25rem;
}
.color-highlight {
  color: $primary-color !important;
  cursor: pointer;
}
.back {
  color: rgba(2, 0, 56, 0.4);
}
.status-text {
  text-align: left;
  color: $primary-text-color;
  font-size: 0.875rem;
  text-decoration: none;
}
</style>
