export const TECHLEND_CALCS_URL = stripTrailingSlash(process.env.VUE_APP_TECHLEND_CALCS_URL);
export const TECHLEND_BROKER_URL = stripTrailingSlash(process.env.VUE_APP_TECHLEND_BROKER_URL);
export const TECHLEND_BE_URL = stripTrailingSlash(process.env.VUE_APP_TECHLEND_BE_URL);
export const TECHLEND_FE_URL = stripTrailingSlash(process.env.VUE_APP_TECHLEND_FE_URL);
export const LOAN_APPLICATION_URL = stripTrailingSlash(process.env.VUE_APP_LOAN_APPLICATION_URL);

export const API = {
  APPLICATION: {
    RETRIEVE_APPLICATION: '/users/applications/application',
    LIST_APPLICATIONS: '/users/applications/list-applications',
    CREATE_DISCHARGE_REQUEST: '/users/discharge-request/create-discharge-request',
  },
  AUTHENTICATE: {
    REQUEST_OTP: '/users/authenticate/request-otp',
    VERIFY_OTP: '/users/authenticate/verify-otp',
  },
  CONTACT: {
    SEND_EMAIL: '/users/contact/send-email',
  },
  REGISTER: {
    REQUEST_OTP: '/users/register/request-otp',
    VERIFY_OTP: '/users/register/verify-otp',
  },
  TASKS: {
    ACCEPT_CONDITIONAL_OFFER: '/users/tasks/accept-conditional-offer',
    ACCEPT_UNCONDITIONAL_OFFER: '/users/tasks/accept-unconditional-offer',
    DELETE_SUPPORTING_DOCUMENT: '/users/tasks/delete-supporting-document',
    LIST_TASKS: '/users/tasks/list-tasks',
    UPDATE_TASK: '/users/tasks/update-task',
    UPLOAD_SUPPORTING_DOCUMENTS: '/users/tasks/upload-supporting-documents',
  },
  USER: {
    CURRENT_USER: '/users/user/current-user',
    UPDATE_NOTIFICATION_SETTINGS: '/users/user/update-notification-settings',
    UPDATE_PERSONAL_INFORMATION: '/users/user/update-personal-information',
    UPDATE_PAYMENT_INFORMATION: '/users/user/update-payment-information',
  },
  // NOTE: these endpoints are referenced in parts of the UI that were never used/tested
  AUTH: {
    APPLICATION_SUBMIT: '/users/applications/submit-application',
    BROKER_APPLICATION_SUBMIT: '/brokers/applications/submit-application',
  },
  CALCS: {
    LOAN_AMOUNT: '/legacy/loan-amount',
  },
  PUBLIC: {
    GET_LOAN_NO_TOKEN: '/public/loan-scenario/',
  },
  STATEMENTS: {
    LIST_STATEMENTS: '/users/statements/list-statements',
  },
};

function stripTrailingSlash(value) {
  if (!value || !value.endsWith('/')) {
    return value;
  }
  return value.substring(0, value.length - 1);
}
