<template>
  <div class="timeline-container">
    <h3 class="title pm-txt-color">
      {{ profileDetails.firstName }} {{ profileDetails.middleName }}
      {{ profileDetails.lastName }}
      <span v-if="profileDetails.firstName">-</span> Current application progress
    </h3>
    <div class="progress-details">
      <div class="current-application-container">
        <div class="col">
          <TimeLine />
        </div>
        <div class="col"><LoanDetails :supportingDocumentId="supportingDocumentId" /></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import LoanDetails from './LoanDetails.vue';
import TimeLine from './TimeLine.vue';

export default {
  components: { TimeLine, LoanDetails },
  props: ['supportingDocumentId'],
  computed: {
    ...mapGetters({
      profileDetails: 'settings/profileDetails',
    }),
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';

.timeline-container {
  margin-top: 2rem;

  .title {
    font-size: bold;
    color: $primary-text-color;
    font-size: 1.25rem;
  }

  .progress-details {
    border-bottom: 1px solid $light-text-color;
    padding-bottom: 2rem;
    .current-application-container {
      display: flex !important;
      @media (max-width: 992px) {
        flex-wrap: wrap;
      }
    }
  }
}
</style>
