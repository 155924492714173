<template>
  <div>
    <Spinner v-if="isLoading" class="flex" />
    <div v-else class="div">
      <div class="progress-summary-container box-shadow">
        <div class="header-wrapper flex-wrap flex justify-content-between w-100">
          <div class="header p-d-flex p-ai-center">
            <h3
              @click="changingTab('Progress')"
              class="title gr-bold"
              :class="this.component === 'Progress' ? 'active' : 'lt-txt-color'"
            >
              Progress
            </h3>
            <h3
              @click="changingTab('Summary')"
              class="title gr-bold"
              :class="this.component === 'Summary' ? 'active' : 'lt-txt-color'"
            >
              Summary
            </h3>
          </div>
          <div class="footer top-footer" v-if="this.component === 'Summary' && displayFooter">
            <div v-if="progressStage === 'conditional_offer'" class="flex p-ai-center">
              <div class="back header-back mr-5 cursor-pointer" @click="toggleCmp">Back</div>
              <Button
                :label="`Accept Conditional offer`"
                type="primary"
                iconPos="right"
                icon="pi pi-chevron-right"
                class="header-footer"
                @click="openCondition"
              />
            </div>
            <div v-else-if="progressStage === 'unconditional_offer'" class="view-loan flex p-ai-center">
              <div class="back header-back mr-5 cursor-pointer" @click="toggleCmp">Back</div>
              <Button
                :label="`Accept UnConditional offer`"
                type="primary"
                iconPos="right"
                class="header-footer"
                icon="pi pi-chevron-right"
                @click="openUnCondition"
              />
            </div>
            <Button
              v-else
              class="view-loan mt-3"
              label="View loan progress"
              type="primary"
              iconPos="right"
              icon="pi pi-chevron-right"
              @click="toggleCmp"
            />
          </div>
        </div>
        <!-- <Progress /> -->
        <!-- <Summary /> -->
        <component
          :is="component"
          @toggleCmp="toggleCmpStatus"
          :cmp="component"
          @setCurrentProgress="setCurrentProgress"
          :progressStage="progressStage"
        ></component>
        <div class="footer flex justify-content-end mt-3" v-if="this.component === 'Summary' && displayFooter">
          <div v-if="progressStage === 'conditional_offer'" class="flex p-ai-center">
            <div class="back mr-5 cursor-pointer" @click="toggleCmp">Back</div>
            <Button
              :label="`Accept Conditional offer`"
              type="primary"
              iconPos="right"
              icon="pi pi-chevron-right"
              @click="openCondition"
            />
          </div>
          <div v-else-if="progressStage === 'unconditional_offer'" class="view-loan flex p-ai-center">
            <div class="back mr-5 cursor-pointer" @click="toggleCmp">Back</div>
            <Button
              :label="`Accept UnConditional offer`"
              type="primary"
              iconPos="right"
              icon="pi pi-chevron-right"
              @click="openUnCondition"
            />
          </div>
          <Button
            v-else
            class="view-loan mt-3"
            label="View loan progress"
            type="primary"
            iconPos="right"
            icon="pi pi-chevron-right"
            @click="toggleCmp"
          />
        </div>
      </div>
      <!-- <Discharge :display="display" /> -->
      <IlionModal :display="false" />
      <ConditionalOfferDialog :display="displayConditonalDialog" @closeCondition="closeCondition" :key="conditionKey" />
      <UnconditionalOfferDialog
        :display="displayUnConditonalDialog"
        @completed="closeUnConditionalDialog"
        :key="uncondKey"
        :closeDialog="closeUnConditionalDialog"
      />
    </div>
  </div>
</template>

<script>
import Progress from './Progress/index.vue';
import Summary from './Summary/index.vue';
// import Discharge from "./Progress/LoanSettlement/DischargeRequest.vue";
import { mapActions, mapGetters } from 'vuex';
import ConditionalOfferDialog from './Progress/ConditionalOfferDialog.vue';
import IlionModal from './Progress/IlionModal.vue';
import UnconditionalOfferDialog from './Progress/UnconditionalOfferDialog.vue';

export default {
  components: {
    Progress,
    Summary,
    // Discharge,
    IlionModal,
    ConditionalOfferDialog,
    UnconditionalOfferDialog,
  },
  computed: {
    ...mapGetters({
      selectedLoanProgress: 'loans/selectedLoanProgress',
    }),
  },
  data() {
    return {
      component: 'Progress',
      display: false,
      progressStage: '',
      conditionKey: 0,
      displayConditonalDialog: false,
      conditionOfferDiabled: false,
      displayFooter: false,
      uncondKey: 10,
      selectedTaskId: '',
      displayUnConditonalDialog: false,
      isLoading: true,
    };
  },
  methods: {
    ...mapActions({
      fetchLoanProgress: 'loans/fetchLoanProgress',
      setNewLoanId: 'loans/setNewLoanId',
    }),
    async changingTab(cmp) {
      this.component = cmp;
      await this.acceptConditionalOffer();
    },
    toggleCmpStatus() {
      return (this.component = 'Progress');
    },
    toggleCmp() {
      this.component = 'Progress';
    },
    setCurrentProgress(value) {
      this.progressStage = value;
    },
    async acceptConditionalOffer() {
      this.displayFooter = false;
      await this.fetchLoanProgress();
      const result = this.selectedLoanProgress;
      if (result.tasks) {
        const list = result.tasks;
        const task = list.find((obj) =>
          (obj.modal_field == this.progressStage) == 'condional_offer' ? 'conditional' : 'unconditional',
        );
        if (task) {
          this.displayFooter = true;
          this.selectedTaskId = task.Id;
          this.uncondKey += 1;
        } else {
          this.displayFooter = false;
        }
      } else {
        this.displayFooter = false;
      }
    },
    acceptUnConditionalOffer() {
      console.log('uncondtional');
    },
    openCondition() {
      this.conditionKey += 1;
      this.displayConditonalDialog = true;
    },
    openUnCondition() {
      this.displayUnConditonalDialog = true;
    },
    async closeCondition() {
      this.displayConditonalDialog = false;
      this.component = 'Progress';
      await this.acceptConditionalOffer();
    },
    async closeUnConditionalDialog() {
      this.displayUnConditonalDialog = false;
      this.component = 'Progress';
      await this.acceptConditionalOffer();
    },
  },
  async created() {
    await this.setNewLoanId(this.$route.params.id);
    this.isLoading = true;
    await this.acceptConditionalOffer();
    this.isLoading = false;
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';

.back {
  color: rgba(2, 0, 56, 0.4);
}
@media (max-width: 992px) {
  .view-loan {
    display: none;
  }
}

@media (max-width: 785px) {
  .top-footer {
    display: none;
  }
  .header-back {
    display: none;
  }

  .header-footer {
    margin-bottom: 1rem;
  }
}
.progress-summary-container {
  background-color: $white-color;
  // display: inline-block;
  margin: 1rem 0;
  padding: 1.875rem;

  .header-wrapper {
    gap: 1rem;
    margin: 0;
    padding: 0;
    border-bottom: 1px solid #02003826;

    .header {
      gap: 2rem;

      .title {
        color: #adadad;
        cursor: pointer;
        font-size: 1.4rem;
        margin: 0;
        padding: 1rem 0 1.5rem 0;
      }
      .active {
        color: $primary-color;
        border-bottom: 3px solid $primary-color;
        font-weight: bold;
      }
    }
  }
}
</style>
