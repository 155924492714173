import { API, TECHLEND_BE_URL } from '@/constants.api';
import { TOKEN } from '@/constants';
import { _post } from '@/services/crudService';

import { useCookies } from 'vue3-cookies';
const { cookies } = useCookies();

export default {
  namespaced: true,

  state: {
    verifyFormData: {
      mobilePhoneNumber: '',
      where_did_you_find_about_techlend: null,
      verification_code: '',
      token: '',
    },
    verifyData: {},
  },
  getters: {
    verifyFormData: ({ verifyFormData }) => verifyFormData,
    verifyData: ({ verifyData }) => verifyData,
  },
  mutations: {
    setVerifyState(storeState, { fieldName, value }) {
      storeState.verifyFormData[fieldName] = value;
    },
    setFinalData(storeState) {
      const finalObj = {
        mobile_no: storeState.verifyFormData.mobile_number,
        how_you_know_techlend: storeState.verifyFormData.where_did_you_find_about_techlend,
        verification_code: storeState.verifyFormData.verification_code,
      };
      storeState.verifyData = finalObj;
    },
  },
  actions: {
    setVerifyState({ commit }, payload) {
      commit('setVerifyState', payload);
      commit('setFinalData');
    },
    async fetchOtp(state, payload) {
      await _post(TECHLEND_BE_URL + API.AUTHENTICATE.REQUEST_OTP, payload);
    },
    async verifyOtp({ commit }, payload) {
      const { data } = await _post(TECHLEND_BE_URL + API.AUTHENTICATE.VERIFY_OTP, payload);
      commit('setVerifyState', { fieldName: TOKEN, value: data.token });
      cookies.set(TOKEN, data.token);
    },
    async submitIncomeAndExpenseDetails({ rootState }) {
      const { secondapplicationform } = rootState;
      const reqObj = {
        ...secondapplicationform.income.incomeData,
        ...secondapplicationform.expense.expenseData,
        ...secondapplicationform.assetsLiability.assetLiabilityData,
        ...secondapplicationform.verify.verifyData,
        application_id: secondapplicationform.applicationId,
      };
      await _post(
        `${TECHLEND_BE_URL}save-income-expense?application_id=${secondapplicationform.applicationId}&applicantType=${secondapplicationform.applicantType}`,
        reqObj,
      );
    },
  },
};
